import { formatDateDDMMYYMonthInLettersVersion2 } from '../../utils/functions'

export const formatResponsesByEntityList = (responsesByEntityList, setResponsesByEntityList, page, limit) => {
  const listFormatted = responsesByEntityList.map((response) => {
    let emailFormatted = response.sender.split('<')[1]?.replace(/>/g, '') || response.sender
    let emailName = emailFormatted.split('@')[0]
    let emailDomain = emailFormatted.split('@')[1]

    if (emailName.length > 20) {
      emailName = emailName.slice(0, 20) + '...'
    }

    if (emailDomain.length > 20) {
      const lastDotIndex = emailDomain.indexOf('.')
      const domainName = emailDomain.slice(0, lastDotIndex)
      const domainExtension = emailDomain.slice(lastDotIndex)
      emailDomain = domainName.slice(0, 10) + '...' + domainExtension
    }

    emailFormatted = emailName + '@' + emailDomain

    return {
      id: response.id,
      arrivalDate: formatDateDDMMYYMonthInLettersVersion2(response.date),
      email: emailFormatted,
      respondingEntity: response.subject,
      subject: response.subject
    }
  })

  setResponsesByEntityList(listFormatted)
}
