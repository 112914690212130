import React, { useState, useContext, useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import iconPersonCircleAdmin from '../../../../assets/icons/icon_nuevo usuario-naranja.svg'
import iconPersonCircleLawyer from '../../../../assets/icons/icon_nuevo usuario-morado.svg'
import iconPersonCircleOper from '../../../../assets/icons/icon_nuevo usuario-violeta.svg'
import generatePasswordIcon from '../../../../assets/icons/icon_generate_password.svg'
import backIcon from '../../../../assets/icons/icon_volver.svg'
import { DataContext } from '../../../../context/DataContext'
import { Modal } from '../../../../components/Modal/Modal'
import { useModal } from '../../../../hooks/useModal'
import { useFetchFormData } from '../../hooks/useFetchFormData'
import { useIncorrectInputFormat } from '../../hooks/useIncorrectInputFormat'
import { maxSize } from '../../../../utils/validateFunctions'
import { roles } from '../../../../utils/objects'
import {
  calculateBirthdayDatesRange,
  onBirthdayDateChange,
  onCityChange,
  onCountryChange,
  onDocumentNumberChange,
  onDocumentTypeIdChange,
  onEmailChange,
  onExpeditionCityChange,
  onGenderChange,
  onIsValidBirthdayDateBlur,
  onIsValidCityBlur,
  onIsValidCountryBlur,
  onIsValidDocumentNumberBlurEmployee,
  onIsValidEmailBlur,
  onIsValidExpeditionCityBlur,
  onIsValidLastNameBlur,
  onIsValidNameBlur,
  onPasswordKeyDown,
  onPasswordClick,
  onIsValidPhoneBlur,
  onLastNameChange,
  onNameChange,
  onPhoneChange
} from '../../utils/createAndEditUserMethods'
import {
  closeModal,
  createNewUserSendForm,
  setClassAndIcon
} from './CreateNewUserFunctions'

import './CreateNewUser.css'
import { generateAleatoryPassword } from '../../../../utils/functions'
import { ScriptContext } from '../../../../context/ScriptsContext'

export const CreateNewUser = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const dataContext = useContext(DataContext)
  const { dataState, dataDispatch } = dataContext
  const { idToken } = dataState.administrative
  const { userProfile } = dataState.profile
  const { roleIdToBeCreated } = dataState.administrative
  const navigate = useNavigate()

  const {
    countriesFetched,
    citiesFetched,
    docTypesFetched,
    gendersFetched
  } = useFetchFormData()

  const { minBirthdayDate, maxBirthdayDate } = useMemo(() => calculateBirthdayDatesRange(), [])

  const [showModal, handleShow, handleClose] = useModal()
  const [showModal2, handleShow2, handleClose2] = useModal()

  const [showModal3, handleShow3, handleClose3] = useModal()

  const [modal3Message, setModal3Message] = useState('')

  const [passwordState] = useState({
    password: {
      eyeIcon: generatePasswordIcon,
      isPasswordVisible: false,
      passwordInputType: 'password'
    },
    passwordConfirmation: {
      eyeIcon: generatePasswordIcon,
      isPasswordVisible: false,
      passwordInputType: 'password'
    }
  })

  const [isRequesting, setIsRequesting] = useState(false)

  const formInitialValues = {
    birthdayDate: '',
    city: '',
    cityId: '',
    country: '',
    email: '',
    gender: '',
    documentExpeditionCity: '',
    documentExpeditionCityId: '',
    documentNumber: '',
    documentTypeId: '',
    lastName: '',
    name: '',
    password: '',
    passwordConfirmation: '',
    phone: '',
    personId: null
  }

  const [formValues, setFormValues] = useState(formInitialValues)

  const { incorrectInputFormat, setIncorrectInputFormat } = useIncorrectInputFormat(formValues.personId)

  const { colorClass, iconSrc } = setClassAndIcon(
    roles,
    userProfile,
    iconPersonCircleAdmin,
    iconPersonCircleLawyer,
    iconPersonCircleOper
  )
  useEffect(() => {
    setFormValues({
      ...formValues,
      documentExpeditionCityId: citiesFetched.find(city => city.cityName.includes(formValues.documentExpeditionCity))?.cityId
    })
  }, [formValues.documentExpeditionCity])

  return (
    <>
      <div className='createNewUserHeaderContainer'>
        <p className={'createNewUserHeaderContainerParagraph'}>
          {scriptsSave && scriptsSave.createNewUserString ? scriptsSave.createNewUserString.newUser : ''}
        </p>
      </div>
      <hr className='hrCreateNewUser' />
      <div className='createNewUserFormContainer'>
        <div className='createNewUserMainContent'>
          <div className={`createNewUserFormHeaderContainer ${colorClass}`}>
            <img
              alt={scriptsSave && scriptsSave.createNewUserString ? scriptsSave.createNewUserString.altImageTxt1 : ''}
              className='createNewUserFormHeaderContainerImg'
              src={iconSrc}
            />
            <p className='createNewUserFormHeaderContainerParagraph'>
              {`${scriptsSave && scriptsSave.createNewUserString ? scriptsSave.createNewUserString.role : ''}${userProfile}`}
            </p>
          </div>
          <div id='createNewUserFormContainer'>
            <div className='createNewUserFormSubContainerL'>
              <div className='formSubContainerLNombreApellidoContainer'>
                <div className='createNewUserInputBox'>
                  <span className='createNewUserInputTitle'>{`*${scriptsSave && scriptsSave.createNewUserString ? scriptsSave.createNewUserString.userName : ''}`}</span>
                  <input
                    autoComplete='off'
                    className={`${incorrectInputFormat.nameFormat === 'employeeRightFormat' ? 'createNewUserInput' : incorrectInputFormat.nameFormat === 'employeeIncorrectUserName' ? 'createNewUserNameInputIncorrectFormat' : 'createNewUserInputIncorrectFormat'}
                    `}
                    name='createNewUserInput'
                    onBlur={() => onIsValidNameBlur(formValues, incorrectInputFormat, setIncorrectInputFormat)}
                    onChange={(e) => onNameChange(e, formValues, setFormValues)}
                    onInput={(e) => maxSize(e, 'flName')}
                    placeholder={scriptsSave && scriptsSave.createNewUserString ? scriptsSave.createNewUserString.placeholder1 : ''}
                    type='text'
                    value={formValues.name}
                    tabIndex='1'
                    disabled={formValues.personId}
                  />
                  <span className={incorrectInputFormat.nameFormat}>{incorrectInputFormat.nameMessage}</span>
                </div>
                <div className='createNewUserInputBox'>
                  <span className='createNewUserInputTitle'>{`*${scriptsSave && scriptsSave.createNewUserString ? scriptsSave.createNewUserString.userLastName : ''}`}</span>
                  <input
                    autoComplete='off'
                    className={`${incorrectInputFormat.lastNameFormat === 'employeeRightFormat' ? 'createNewUserInput' : incorrectInputFormat.lastNameFormat === 'employeeIncorrectUserName' ? 'createNewUserNameInputIncorrectFormat' : 'createNewUserInputIncorrectFormat'}
                    `}
                    name='createNewUserInput'
                    onBlur={() => onIsValidLastNameBlur(formValues, incorrectInputFormat, setIncorrectInputFormat)}
                    onChange={(e) => onLastNameChange(e, formValues, setFormValues)}
                    onInput={(e) => maxSize(e, 'flName')}
                    placeholder={scriptsSave && scriptsSave.createNewUserString ? scriptsSave.createNewUserString.placeholder2 : ''}
                    type='text'
                    value={formValues.lastName}
                    tabIndex='2'
                    disabled={formValues.personId}
                  />
                  <span className={incorrectInputFormat.lastNameFormat}>{incorrectInputFormat.lastNameMessage}</span>
                </div>
              </div>
              <div className='createNewUserInputBox'>
                <span className='createNewUserInputTitle'>{`*${scriptsSave && scriptsSave.createNewUserString ? scriptsSave.createNewUserString.eMail : ''}`}</span>
                <input
                  autoComplete='off'
                  className={`${incorrectInputFormat.emailFormat === 'employeeRightFormat' ? 'createNewUserInput' : 'createNewUserInputIncorrectFormat'}`}
                  name='createNewUserInput'
                  onBlur={() => onIsValidEmailBlur(dataContext, formValues, setFormValues, incorrectInputFormat, setIncorrectInputFormat, idToken, formInitialValues)}
                  onChange={(e) => onEmailChange(e, formValues, setFormValues)}
                  onInput={(e) => maxSize(e, 'email')}
                  placeholder={scriptsSave && scriptsSave.createNewUserString ? scriptsSave.createNewUserString.placeholder3 : ''}
                  type='text'
                  value={formValues.email}
                  tabIndex='5'
                />
                <span className={incorrectInputFormat.emailFormat}>{incorrectInputFormat.emailMessage}</span>
              </div>
              <div className='formSubContainerLPaisCiudadContainer'>
                <div className='createNewUserInputBox'>
                  <span className='createNewUserInputTitle'>{`*${scriptsSave && scriptsSave.createNewUserString ? scriptsSave.createNewUserString.country : ''}`}</span>
                  <input
                    autoComplete='off'
                    className={`${incorrectInputFormat.countryFormat === 'employeeRightFormat' ? 'createNewUserInput' : 'createNewUserInputIncorrectFormat'}`}
                    name='createNewUserInput'
                    onBlur={() => onIsValidCountryBlur(
                      formValues,
                      setFormValues,
                      incorrectInputFormat,
                      setIncorrectInputFormat,
                      countriesFetched
                    )}
                    onChange={(e) => onCountryChange(e, formValues, setFormValues)}
                    onInput={(e) => maxSize(e, 'flName')}
                    placeholder={scriptsSave && scriptsSave.createNewUserString ? scriptsSave.createNewUserString.placeholder4 : ''}
                    type='text'
                    value={formValues.country}
                    tabIndex='8'
                    disabled={formValues.personId}
                  />
                  <span className={incorrectInputFormat.countryFormat}>{incorrectInputFormat.countryMessage}</span>
                </div>
                <div className='createNewUserInputBox'>
                  <span className='createNewUserInputTitle'>{`*${scriptsSave && scriptsSave.createNewUserString ? scriptsSave.createNewUserString.city : ''}`}</span>
                  <input
                    autoComplete='off'
                    className={`${incorrectInputFormat.cityFormat === 'employeeRightFormat' ? 'createNewUserInput' : 'createNewUserInputIncorrectFormat'}`}
                    name='createNewUserInput'
                    onBlur={() => onIsValidCityBlur(
                      formValues,
                      setFormValues,
                      incorrectInputFormat,
                      setIncorrectInputFormat,
                      citiesFetched
                    )}
                    onChange={(e) => onCityChange(e, formValues, setFormValues)}
                    placeholder={scriptsSave && scriptsSave.createNewUserString ? scriptsSave.createNewUserString.placeholder5 : ''}
                    type='text'
                    value={formValues.city}
                    tabIndex='9'
                    disabled={formValues.personId}
                  />
                  <span className={incorrectInputFormat.cityFormat}>{incorrectInputFormat.cityMessage}</span>
                </div>
              </div>
              <div className='createNewUserInputBox'>
                <span className='createUserPasswordTitle'>{`${scriptsSave && scriptsSave.createNewUserString ? scriptsSave.createNewUserString.userPassword : ''}`}</span>
                <span className='createUserPasswordSubtitle'>{`*${scriptsSave && scriptsSave.createNewUserString ? scriptsSave.createNewUserString.password : ''}`}</span>
                <div className='textboxIconPasswordContainer'>
                  <div
                    className={`${incorrectInputFormat.passwordFormat === 'employeeRightFormat' ? 'createNewUserInput createPasswordInput' : 'createNewUserInputIncorrectFormat createPasswordInputIncorrectFormat'}`}
                  >
                    <span>{formValues.password.replace(/./g, '\u25CF')}</span>
                    <img
                      alt={scriptsSave && scriptsSave.createNewUserString ? scriptsSave.createNewUserString.altEyeIcon : ''}
                      className='passwordGenerateImg'
                      onKeyDown={(e) => onPasswordKeyDown(
                        e,
                        generateAleatoryPassword,
                        formValues,
                        setFormValues,
                        setIncorrectInputFormat
                      )}
                      onClick={() => onPasswordClick(
                        generateAleatoryPassword,
                        formValues,
                        setFormValues,
                        setIncorrectInputFormat
                      )}
                      src={passwordState.password.eyeIcon}
                      tabIndex='12'
                    />
                  </div>
                </div>
                <span className={incorrectInputFormat.passwordFormat}>{incorrectInputFormat.passwordMessage}</span>
              </div>
              <span className='redAdvice'>{`*${scriptsSave && scriptsSave.createNewUserString ? scriptsSave.createNewUserString.mandatoryFileds : ''}`}</span>
            </div>
            <div className='createNewUserFormSubContainerR'>
              <div className='formSubContainerRGeneralContainerA'>
                <div className='createNewUserInputBox IdSelect'>
                  <span className='createNewUserInputTitle'>{`*${scriptsSave && scriptsSave.createNewUserString ? scriptsSave.createNewUserString.documentTypeId : ''}`}</span>
                  <select
                    autoComplete='off'
                    className={`${incorrectInputFormat.documentTypeIdFormat === 'employeeRightFormat' ? 'createNewUserSelect' : 'createNewUserSelectIncorrectFormat'}`}
                    onChange={(e) => onDocumentTypeIdChange(
                      e,
                      formValues,
                      setFormValues,
                      incorrectInputFormat,
                      setIncorrectInputFormat
                    )}
                    tabIndex='3'
                    disabled={formValues.personId}
                  >
                    <option key='0ident' id='documentTypeId' value=''>
                      {
                        formValues.documentTypeId
                          ? docTypesFetched.find((doc) => doc.identDocId === formValues.documentTypeId)?.identDocName
                          : scriptsSave && scriptsSave.createNewUserString ? scriptsSave.createNewUserString.defaultDocumentTypeId : ''
                      }
                    </option>
                    {
                      docTypesFetched.map(({ identDocId, identDocName }) => (
                        <option
                          key={identDocId}
                          value={identDocId}
                        >
                          {identDocName}
                        </option>
                      ))
                    }
                  </select>
                  <span className={incorrectInputFormat.documentTypeIdFormat}>{incorrectInputFormat.documentTypeIdMessage}</span>
                </div>
                <div className='createNewUserInputBox'>
                  <span className='createNewUserInputTitle'>{`*${scriptsSave && scriptsSave.createNewUserString ? scriptsSave.createNewUserString.documentNumber : ''}`}</span>
                  <input
                    autoComplete='off'
                    className={`${incorrectInputFormat.documentNumberFormat === 'employeeRightFormat' ? 'createNewUserInput' : 'createNewUserInputIncorrectFormat'}`}
                    name='createNewUserInput'
                    onBlur={() => onIsValidDocumentNumberBlurEmployee(dataContext, formValues, formInitialValues, setFormValues, incorrectInputFormat, setIncorrectInputFormat, idToken)}
                    onChange={(e) => onDocumentNumberChange(e, formValues, setFormValues)}
                    placeholder={scriptsSave && scriptsSave.createNewUserString ? scriptsSave.createNewUserString.placeholder7 : ''}
                    type='text'
                    value={formValues.documentNumber}
                    tabIndex='4'
                  />
                  <span className={incorrectInputFormat.documentNumberFormat}>{incorrectInputFormat.documentNumberMessage}</span>
                </div>
              </div>
              <div className='formSubContainerRGeneralContainerB'>
                <div className='createNewUserInputBox BirthdateSelect'>
                  <span className='createNewUserInputTitle'>{`*${scriptsSave && scriptsSave.createNewUserString ? scriptsSave.createNewUserString.birthdayDate : ''}`}</span>
                  <input
                    autoComplete='off'
                    className={`${incorrectInputFormat.birthdayDateFormat === 'employeeRightFormat' ? 'createNewUserInputDate' : 'createNewUserInputDateIncorrectFormat'}`}
                    max={maxBirthdayDate}
                    min={minBirthdayDate}
                    name='createNewUserInput'
                    onBlur={() => onIsValidBirthdayDateBlur(
                      formValues,
                      incorrectInputFormat,
                      setIncorrectInputFormat,
                      maxBirthdayDate,
                      minBirthdayDate
                    )}
                    onChange={(e) => onBirthdayDateChange(e, formValues, setFormValues)}
                    placeholder={scriptsSave && scriptsSave.createNewUserString ? scriptsSave.createNewUserString.placeholder8 : ''}
                    type='date'
                    value={formValues.birthdayDate}
                    tabIndex='6'
                    disabled={formValues.personId}
                  />
                  <span className={incorrectInputFormat.birthdayDateFormat}>{incorrectInputFormat.birthdayDateMessage}</span>
                </div>
                <div className='createNewUserInputBox'>
                  <span className='createNewUserInputTitle'>{`*${scriptsSave && scriptsSave.createNewUserString ? scriptsSave.createNewUserString.idIssuance : ''}`}</span>
                  <input
                    autoComplete='off'
                    className={`${incorrectInputFormat.documentExpeditionCityFormat === 'employeeRightFormat' ? 'createNewUserInput' : 'createNewUserInputIncorrectFormat'}`}
                    name='createNewUserInput'
                    onBlur={() => onIsValidExpeditionCityBlur(
                      formValues,
                      setFormValues,
                      incorrectInputFormat,
                      setIncorrectInputFormat,
                      citiesFetched
                    )}
                    onChange={(e) => onExpeditionCityChange(e, formValues, setFormValues)}
                    placeholder={scriptsSave && scriptsSave.createNewUserString ? scriptsSave.createNewUserString.placeholder9 : ''}
                    type='text'
                    value={formValues.documentExpeditionCity}
                    tabIndex='7'
                    disabled={formValues.personId}
                  />
                  <span className={incorrectInputFormat.documentExpeditionCityFormat}>{incorrectInputFormat.documentExpeditionCityMessage}</span>
                </div>
              </div>
              <div className='formSubContainerRGeneralContainerC'>
                <div className='createNewUserInputBox GenderInput'>
                  <span className='createNewUserInputTitle'>{`${scriptsSave && scriptsSave.createNewUserString ? scriptsSave.createNewUserString.gender : ''}`}</span>
                  <select
                    autoComplete='off'
                    className={`${incorrectInputFormat.genderFormat === 'employeeRightFormat' ? 'createNewUserSelect' : 'createNewUserSelectIncorrectFormat'}`}
                    onChange={(e) => onGenderChange(
                      e,
                      formValues,
                      setFormValues,
                      incorrectInputFormat,
                      setIncorrectInputFormat
                    )}
                    tabIndex='10'
                    disabled={formValues.personId}
                  >
                    <option key='0gender' id='gender' value=''>
                      {
                        formValues.gender
                          ? gendersFetched.find((gender) => gender.genderId === formValues.gender)?.genderType
                          : scriptsSave && scriptsSave.createNewUserString ? scriptsSave.createNewUserString.defaultGender : ''
                      }
                    </option>
                    {
                      gendersFetched.map(({ genderId, genderType }) => (
                        <option
                          key={genderId}
                          value={genderId}
                        >
                          {genderType}
                        </option>
                      ))
                    }
                  </select>
                  <span className={incorrectInputFormat.genderFormat}>{incorrectInputFormat.genderMessage}</span>
                </div>
                <div className='createNewUserInputBox'>
                  <span className='createNewUserInputTitle'>{`*${scriptsSave && scriptsSave.createNewUserString ? scriptsSave.createNewUserString.phone : ''}`}</span>
                  <input
                    autoComplete='off'
                    className={`${incorrectInputFormat.phoneFormat === 'employeeRightFormat' ? 'createNewUserInput' : 'createNewUserInputIncorrectFormat'}`}
                    onBlur={() => onIsValidPhoneBlur(dataContext, formValues, setFormValues, incorrectInputFormat, setIncorrectInputFormat, idToken)}
                    onChange={(e) => onPhoneChange(e, formValues, setFormValues)}
                    name='createNewUserInput'
                    placeholder={scriptsSave && scriptsSave.createNewUserString ? scriptsSave.createNewUserString.placeholder10 : ''}
                    type='text'
                    value={formValues.phone}
                    tabIndex='11'
                    disabled={formValues.personId}
                  />
                  <span className={incorrectInputFormat.phoneFormat}>{incorrectInputFormat.phoneMessage}</span>
                </div>
              </div>
              <div className='createNewUserInputBox'>
              </div>
            </div>
          </div>
        </div>
        <div className='createNewUserFooter'>
          <button onClick={handleShow2} className='createNewUserFooterBackLink'>
            <img
              src={backIcon}
              alt={scriptsSave && scriptsSave.createNewUserString ? scriptsSave.createNewUserString.altImageTxt2 : ''}
            />
            <span>{`${scriptsSave && scriptsSave.createNewUserString ? scriptsSave.createNewUserString.goBack : ''}`}</span>
          </button>
          <button
            onClick={() => createNewUserSendForm(
              handleShow,
              formInitialValues,
              formValues,
              setFormValues,
              incorrectInputFormat,
              setIncorrectInputFormat,
              countriesFetched,
              citiesFetched,
              maxBirthdayDate,
              minBirthdayDate,
              roleIdToBeCreated,
              idToken,
              dataContext,
              dataDispatch,
              isRequesting,
              setIsRequesting,
              handleShow3,
              setModal3Message
            )}
            className={`${isRequesting ? 'createNewUserFooterButtonDisabled' : 'createNewUserFooterButton'}`}
            tabIndex='13'
          >
            {`${scriptsSave && scriptsSave.createNewUserString ? scriptsSave.createNewUserString.create : ''}`}
          </button>
        </div>
      </div>
      <Modal isOpen={showModal}>
        <Modal.Content>
          <p>{scriptsSave && scriptsSave.createNewUserString ? scriptsSave.createNewUserString.successfulyUserCreation : ''}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button
            className='Modal-orangeBtn'
            onClick={() => closeModal(navigate, handleClose)}>
            {scriptsSave && scriptsSave.createNewUserString ? scriptsSave.createNewUserString.continue : ''}
          </button>
        </Modal.Buttons>
      </Modal>
      <Modal isOpen={showModal2}>
        <Modal.Content>
          <p>{scriptsSave && scriptsSave.createNewUserString ? scriptsSave.createNewUserString.cancelUserCreation : ''}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button
            className='Modal-normalBtn'
            onClick={handleClose2}>
            {scriptsSave && scriptsSave.createNewUserString ? scriptsSave.createNewUserString.no : ''}
          </button>
          <button
            className='Modal-orangeBtn'
            onClick={() => closeModal(navigate, handleClose)}>
            {scriptsSave && scriptsSave.createNewUserString ? scriptsSave.createNewUserString.yes : ''}
          </button>
        </Modal.Buttons>
      </Modal>
      <Modal isOpen={showModal3}>
        <Modal.Content>
          <p>{modal3Message}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button
            className='Modal-greenBtn'
            onClick={handleClose3}>
            {scriptsSave && scriptsSave.createNewUserString ? scriptsSave.createNewUserString.ok : ''}
          </button>
        </Modal.Buttons>
      </Modal>
    </>
  )
}
