import { autorizeOrRejectPurchase, updateControlPaymentDemand } from '../../../../utils/userQueries'
import parse from 'html-react-parser'

export const onSendObservationClick = async (
  companyId,
  userId,
  response,
  idToken,
  handleShow,
  dataContext
) => {
  if (response.length < 20) return

  const bodyQuery = {
    userId,
    companyId,
    response
  }

  const updateControlPaymentDemandResponse = await updateControlPaymentDemand(dataContext, idToken, bodyQuery)

  if (updateControlPaymentDemandResponse.response) {
    handleShow()
  }
}

export const onContinueModalBtnClick = async (autorize, paymentId, dataContext) => {
  const { navigate, dataState } = dataContext
  const { idToken } = dataState.operative
  await autorizeOrRejectPurchase(autorize, paymentId, dataContext, idToken)
  navigate('../PagosTuDataControl')
}

export const onAcceptClick = (setModalMessage, handleShow, movementsControlString) => {
  setModalMessage(parse(movementsControlString.acceptModalMsg))
  handleShow()
}

export const onRejectClick = (setModalMessage, handleShow, movementsControlString) => {
  setModalMessage(parse(movementsControlString.rejectModalMsg))
  handleShow()
}
