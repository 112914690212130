import { textWithoutEspecials } from '../../../../../../utils/functions'
import { sendNewProcessWithoutContactChannel } from '../../../../../../utils/userQueries'

export const sendNewProcessSubmit = async (dataContext, file, demandId, handleShow, lawyerEmployeeId) => {
  const { dataState } = dataContext
  const { idToken } = dataState.lawyer

  const requestBody = {
    files: {
      value: 'demand',
      fileNames: [
        {
          fileName: textWithoutEspecials(file.name),
          contentType: file.type
        }
      ]
    },
    employeeId: lawyerEmployeeId
  }

  const responseReq = await sendNewProcessWithoutContactChannel(dataContext, idToken, requestBody, demandId)
  if (responseReq.response) {
    const keyName = Object.keys(responseReq.body)[0]
    const urlToSendFile = responseReq.body[textWithoutEspecials(keyName)].url
    await fetch(urlToSendFile, {
      method: 'PUT',
      body: file
    })
    handleShow()
  }
}
