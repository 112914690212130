import React, { useContext } from 'react'
import './CharCounter.css'
import { ScriptContext } from '../../context/ScriptsContext'

export const CharCounter = ({ description }) => {
  const { scriptsSave } = useContext(ScriptContext)
  return (
    <p className='charCounter'>
      {
        description?.length < 10
          ? `${scriptsSave && scriptsSave.charCounterAdminStrings ? scriptsSave.charCounterAdminStrings.descriptionTxt1 : ''} ${description?.length}`
          : `${!description ? 0 : description?.length}${scriptsSave && scriptsSave.charCounterAdminStrings ? scriptsSave.charCounterAdminStrings.descriptionTxt2 : ''}`
      }
    </p>
  )
}
