import React, { useContext, useEffect, useRef, useState } from 'react'
import editIcon from '../../../../assets/icons/icon_editar.svg'
import { useNavigate } from 'react-router-dom'
import { DataContext } from '../../../../context/DataContext'
import { usePaginateQuery } from '../../../../hooks/usePaginateQuery'
import { Pagination } from '../../../../components/Pagination/Pagination'
import { dataTypes } from '../../../../context/reducers/dataReducer/dataTypes'
import { useFilterBySearch } from '../../../../hooks/useFilterBySearch'
import { formatEntityWithoutContactChannelList } from './EntitiesWithoutChannelFunctions'
import { getEntityWithoutContactChannelList, searchFilterEntitiesWithoutContact } from '../../../../utils/userQueries'
import { ScriptContext } from '../../../../context/ScriptsContext'
import './EntitiesWithoutChannel.css'
import { Dropdown } from '../../../../components/Dropdown/Dropdown'
import { EmptyData } from '../../../../components/EmptyData/EmptyData'

export const EntitiesWithoutChannel = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const navigate = useNavigate()
  const dataContext = useContext(DataContext)
  const { dataState } = dataContext
  const { idToken, caseTracking } = dataState.lawyer
  const { entityWithoutChannelContactList } = caseTracking
  const responseEntityWithoutChannelContactList = entityWithoutChannelContactList.list

  const originalResponseEWCList = useRef(entityWithoutChannelContactList.list)
  const [originalIsChanged, setOriginalIsChanged] = useState(0)
  useEffect(() => {
    if (originalIsChanged <= 1) {
      originalResponseEWCList.current = responseEntityWithoutChannelContactList
      setOriginalIsChanged(originalIsChanged + 1)
    }
  }, [responseEntityWithoutChannelContactList])

  const [queryPersonNameBody, setQueryPersonNameBody] = useState(null)
  const [queryPersonIDBody, setQueryPersonIDBody] = useState(null)
  const [newPersonID, setNewPersonID] = useState('')
  const [newPersonName, setNewPersonName] = useState('')

  const newPersonNameBody = {
    personId: {
      personName: newPersonName
    }
  }

  const newPersonIDBody = {
    personId: {
      personIdentNum: newPersonID
    }
  }

  const [paginationValues, setPaginationValues, isNextActive, setIsNextActive, isPrevActive, setIsPrevActive] = usePaginateQuery(
    entityWithoutChannelContactList,
    'entityWithoutChannelContactList',
    queryPersonNameBody || queryPersonIDBody ? searchFilterEntitiesWithoutContact : getEntityWithoutContactChannelList,
    dataTypes.getEntityWithoutChannelContactList,
    idToken,
    dataContext,
    queryPersonNameBody || queryPersonIDBody || null
  )

  const { page, limit, totalPages } = paginationValues

  const { listFormatted } = useFilterBySearch(
    responseEntityWithoutChannelContactList,
    formatEntityWithoutContactChannelList,
    page,
    limit
  )
  return (
    <section id='EntitiesWithoutChannel'>
      <table className='EntitiesWithoutChannelTable'>
        <thead className='EntitiesWithoutChannelTableHead'>
          <tr>
            <th>{scriptsSave && scriptsSave.EntitiesWithoutChannelString ? scriptsSave.EntitiesWithoutChannelString.column1Name : ''}</th>
            <th>
              <Dropdown
                title={scriptsSave && scriptsSave.EntitiesWithoutChannelString ? scriptsSave.EntitiesWithoutChannelString.column2Name : ''}
                type={'search'}
                newQueryBody={newPersonNameBody}
                setQueryBody={setQueryPersonNameBody}
                setNewValue={setNewPersonName}
              />
            </th>
            <th>
              <Dropdown
                title={scriptsSave && scriptsSave.EntitiesWithoutChannelString ? scriptsSave.EntitiesWithoutChannelString.column3Name : ''}
                type={'search'}
                newQueryBody={newPersonIDBody}
                setQueryBody={setQueryPersonIDBody}
                setNewValue={setNewPersonID}
              />
            </th>
            <th>{scriptsSave && scriptsSave.EntitiesWithoutChannelString ? scriptsSave.EntitiesWithoutChannelString.column4Name : ''}</th>
            <th>{scriptsSave && scriptsSave.EntitiesWithoutChannelString ? scriptsSave.EntitiesWithoutChannelString.column5Name : ''}</th>
          </tr>
        </thead>
        <tbody className='EntitiesWithoutChannelTableBody'>
          {
            listFormatted.map((response) => (
              <tr key={response.id}>
                <td>{response.id}</td>
                <td>{response.name}</td>
                <td>{response.userIdentNum}</td>
                <td><p>{response.companyName}</p></td>
                <td>
                  <button
                    className='requestsGridActionsBtn'
                    onClick={() => navigate('EntidadesSinCanalDetalle', { state: { requestId: response.companyId, view: 'noContact' } })}
                  >
                    <img
                      src={editIcon}
                      alt={scriptsSave && scriptsSave.EntitiesWithoutChannelString ? scriptsSave.EntitiesWithoutChannelString.altImageTxt1 : ''}
                    />
                  </button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
      {(originalResponseEWCList.current.length !== 0 && listFormatted.length === 0) && <EmptyData/>}
      {/* <RequestDetail
        requestTitle={'Derecho de petición'}
        requestDate={'24 Jul 2023'}
        requestId={'0383931'}
        requestType={'Derecho de petición'}
        requestUsername={'Pepito Perez'}
        requestDescription={`${newRequestsDetailString.blockEntity} xxxxxxxxx`}
      /> */}
      <Pagination
        currentPage={entityWithoutChannelContactList.currentPage}
        paginationValues={paginationValues}
        scrollMeasurement={entityWithoutChannelContactList.scrollMeasurement}
        setPaginationValues={setPaginationValues}
        totalPages={totalPages}
        isNextActive={isNextActive}
        setIsNextActive={setIsNextActive}
        isPrevActive={isPrevActive}
        setIsPrevActive={setIsPrevActive}
      />
    </section>
  )
}
