import React, { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import parse from 'html-react-parser'

import { DataContext } from '../../../../context/DataContext'
import { Pagination } from '../../../../components/Pagination/Pagination'
import watchIcon from '../../../../assets/icons/icon_ver-operativo.svg'
import { Modal } from '../../../../components/Modal/Modal'
import { useModal } from '../../../../hooks/useModal'
import { addElipsisAtEnd } from '../../../../utils/functions'
import { usePaginateQuery } from '../../../../hooks/usePaginateQuery'
import { getAllEntitiesNotActive } from '../../../../utils/userQueries'
import { dataTypes } from '../../../../context/reducers/dataReducer/dataTypes'
import { Dropdown } from '../../../../components/Dropdown/Dropdown'
import { formatNewEntitiesList, onEditClick } from './NewEntitiesFunctions'
import { ScriptContext } from '../../../../context/ScriptsContext'

export const NewEntities = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const dataContext = useContext(DataContext)
  const { dataState, dataDispatch } = dataContext
  const { idToken, newEntries } = dataState.operative
  const { entities } = newEntries
  const newEntityEntriesList = newEntries.entities.list

  const [paginationValues, setPaginationValues, isNextActive, setIsNextActive] = usePaginateQuery(
    entities,
    'entities',
    getAllEntitiesNotActive,
    dataTypes.updateNewEntitiesEntries,
    idToken,
    dataContext
  )

  const { page, limit, totalPages } = paginationValues

  const [listFormatted, setListFormatted] = useState([])

  useEffect(() => {
    formatNewEntitiesList(newEntityEntriesList, setListFormatted, page, limit)
  }, [newEntityEntriesList])

  const navigate = useNavigate()

  const [showModal, handleClose] = useModal()

  return (
    <section className='newEntitiesListSection'>
      <table className='newEntitiesListContainer'>
        <tbody id='entriesTable'>
          <tr id='newEntitiesTableHead'>
            <th>{scriptsSave && scriptsSave.newEntitiesString ? scriptsSave.newEntitiesString.thead1 : ''}</th>
            <th className='newEntitiesListHeadCell'>
              <Dropdown
                filterBy={'companyComertialName'}
                list={listFormatted}
                sortType={'string'}
                title={scriptsSave && scriptsSave.newEntitiesString ? scriptsSave.newEntitiesString.thead3 : ''}
                type={'orderSelect'}
                updateList={setListFormatted}
              />
            </th>
            <th>{scriptsSave && scriptsSave.newEntitiesString ? scriptsSave.newEntitiesString.thead4 : ''}</th>
            <th>{scriptsSave && scriptsSave.newEntitiesString ? scriptsSave.newEntitiesString.thead5 : ''}</th>
            <th>{scriptsSave && scriptsSave.newEntitiesString ? scriptsSave.newEntitiesString.thead7 : ''}</th>
          </tr>
          {
            listFormatted.map((entity) => (
              <tr id='newEntitiesTableBody' key={entity.id}>
                <td>{entity.id}</td>
                <td>{addElipsisAtEnd(entity.companyComertialName, 20)}</td>
                <td>{entity.companyNit}</td>
                <td>{addElipsisAtEnd(entity.companyEmail, 30)}</td>
                <td>
                  <button
                    className='newEntitiesEditButton'
                    onClick={() => onEditClick(navigate, entity, dataDispatch)}
                  >
                    <img
                      alt={scriptsSave && scriptsSave.newEntitiesString ? scriptsSave.newEntitiesString.altImageTxt2 : ''}
                      className='editIcon'
                      src={watchIcon}
                    />
                  </button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
      <Pagination
        currentPage={entities.currentPage}
        paginationValues={paginationValues}
        scrollMeasurement={entities.scrollMeasurement}
        setPaginationValues={setPaginationValues}
        totalPages={totalPages}
        isNextActive={isNextActive}
        setIsNextActive={setIsNextActive}
      />

      <Modal isOpen={showModal}>
        <Modal.Content>
          <p>{scriptsSave && scriptsSave.newEntitiesString ? parse(scriptsSave.newEntitiesString.modalDescription) : ''}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button className='Modal-normalBtn' onClick={handleClose}>
            {scriptsSave && scriptsSave.entriesListString ? scriptsSave.entriesListString.cancel : ''}
          </button>
          <button className='Modal-orangeBtn'>
            {scriptsSave && scriptsSave.entriesListString ? scriptsSave.entriesListString.continue : ''}
          </button>
        </Modal.Buttons>
      </Modal>
    </section>
  )
}
