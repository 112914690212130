import { textWithoutEspecials } from '../../../../utils/functions'
import { createNewCompany } from '../../../../utils/userQueries'
import { fileExtension } from '../../../../utils/validateFunctions'

export const cancelEdit = (e, isDisabled, handleCancelModalShow, navigate, state, navigationPath) => {
  e.preventDefault()

  if (!isDisabled) handleCancelModalShow()
  else navigate(navigationPath, { state })
}

export const formatCreateValues = (createValues) => {
  const valuesToFormat = { ...createValues }
  const { companyNit, phone } = valuesToFormat
  if (!companyNit) {
    valuesToFormat.companyNit = valuesToFormat.bussinessName
  }
  if (!phone) {
    valuesToFormat.phone = generateTenDigitNumber()
  }

  function generateTenDigitNumber () {
    const randomDigits = Math.floor(Math.random() * 1_000_000_000).toString().padStart(9, '0')
    return `3${randomDigits}`
  }

  const keysToFormat = {
    city: 'cityId',
    email: 'companyEmail',
    phone: 'companyPhone',
    bussinessName: 'companyBussinesName',
    comertialName: 'companyComertialName'
  }

  const createValuesFormatted = Object.entries(createValues)

  const formattedValuesToRequest = createValuesFormatted.map(([key, value]) => {
    const newKey = keysToFormat[key] || key
    return [newKey, value]
  })

  const valuesFormatted = Object.fromEntries(formattedValuesToRequest)

  return valuesFormatted
}

export const formatBodyToRequest = (createValuesFormatted) => {
  delete createValuesFormatted.state
  const { file, ...restOfValues } = createValuesFormatted
  const bodyToRequest = {
    createCompanyDto: {
      ...restOfValues,
      categoryCompany: {
        categoryCompanyId: createValuesFormatted.categoryCompany.bank ? 1 : createValuesFormatted.categoryCompany.publicServices ? 2 : createValuesFormatted.categoryCompany.noChannel ? 4 : 3
      },
      directRemitent: true
    },
    files: {
      value: 'logos',
      fileNames: [
        {
          fileName: textWithoutEspecials(`${createValuesFormatted.companyComertialName}.${file.type.split('/')[1]}`),
          contentType: file.type
        }
      ]
    }
  }
  return bodyToRequest
}

export const handleSubmit = async (
  e,
  idToken,
  createValues,
  dataContext,
  handleSaveModalShow,
  setisDisabled,
  setIsComertialName,
  setIncorrectInputFormat,
  incorrectInputFormat,
  setIsBussinessName,
  setIsEmail
) => {
  e.preventDefault()
  setisDisabled(true)
  const btnName = e.nativeEvent.submitter.name
  if (btnName === 'cancel') return null

  const createValuesFormatted = formatCreateValues(createValues)
  const bodyToRequest = formatBodyToRequest(createValuesFormatted)

  const comertialName = bodyToRequest.createCompanyDto.companyComertialName
  if (comertialName.length < 10) {
    setIsComertialName(true)
    setIncorrectInputFormat({ ...incorrectInputFormat, comertialNameformat: 'employeeIncorrectFormat' })
    return false
  }
  setIsComertialName(false)
  if (comertialName.startsWith(' ')) {
    setIsComertialName(true)
    setIncorrectInputFormat({
      ...incorrectInputFormat,
      comertialNameformat: 'employeeIncorrectFormat',
      comertialNameMessage: 'El nombre comercial no debe empezar con espacios'
    })
    return false
  }
  setIsComertialName(false)
  const companyBussinesName = bodyToRequest.createCompanyDto.companyBussinesName
  if (companyBussinesName.length < 10) {
    setIsBussinessName(true)
    setIncorrectInputFormat({ ...incorrectInputFormat, bussinessNameFormat: 'employeeIncorrectFormat' })
    return false
  }
  setIsBussinessName(false)
  if (companyBussinesName.startsWith(' ')) {
    setIsBussinessName(true)
    setIncorrectInputFormat({
      ...incorrectInputFormat,
      bussinessNameFormat: 'employeeIncorrectFormat',
      bussinessNameMessage: 'El nombre empresarial no debe empezar con espacios'
    })
    return false
  }
  setIsBussinessName(false)
  const companyEmail = bodyToRequest.createCompanyDto.companyEmail
  if (companyEmail.startsWith(' ')) {
    setIsEmail(true)
    setIncorrectInputFormat({ ...incorrectInputFormat, companyEmailFormat: 'employeeIncorrectFormat' })
    return false
  }
  setIsEmail(false)
  const responseCompanyCreation = await createNewCompany(idToken, bodyToRequest, createValuesFormatted, dataContext)
  if (responseCompanyCreation.response) {
    setisDisabled(false)
    handleSaveModalShow()
  }
}

export const onUploadLogoClick = (e, setCreateValues, createValues, message, setModalMessage, handleShow) => {
  const isAValidExtension = fileExtension(
    e,
    message,
    setModalMessage,
    handleShow
  )

  if (!isAValidExtension) return

  setCreateValues({ ...createValues, file: e.target.files[0] })
}

export const onFirstCheckboxChange = (e, createValues, setCreateValues) => {
  const isChecked = e.target.checked
  setCreateValues({ ...createValues, categoryCompany: { bank: isChecked, publicServices: false, noChannel: false } })
}
export const onSecondCheckboxChange = (e, createValues, setCreateValues) => {
  const isChecked = e.target.checked
  setCreateValues({ ...createValues, categoryCompany: { bank: false, publicServices: isChecked, noChannel: false } })
}
export const onThirdCheckboxChange = (e, createValues, setCreateValues) => {
  const isChecked = e.target.checked
  setCreateValues({ ...createValues, categoryCompany: { bank: false, publicServices: false, noChannel: isChecked } })
}
