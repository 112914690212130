export const roles = {
  ADMINISTRATIVO: 'Administrativo',
  OPERATIVO: 'Operativo',
  ABOGADO: 'Abogado'
}

export const emptySession = {
  logged: false,

  profile: {
    role: '',
    userProfile: '',
    userName: ''
  },
  // administrative contiene toda la información relacionada solo con el rol administrativo
  administrative: {
    dataBases: {
      dataBases: {
        currentPage: 1,
        list: [],
        scrollMeasurement: 0
      },
      databaseToBeReviewed: {
        shoppingHistoryId: '',
        shoppingDate: '',
        expeditionDate: '',
        usersAmount: 0,
        databasePath: '',
        shoppingCode: '',
        createDt: '',
        updateDt: '',
        shoppingDbActive: false,
        databaseTypeId: {},
        buyerId: {},
        id: ''
      }
    },
    employeeCredentialEmail: '',
    employeeId: '',
    employeeCredentialId: '',
    employeesList: [],
    idToken: '',
    roleIdToBeCreated: null,
    roleName: '',
    rolId: null,
    employeeToBeEdited: {},
    citiesDocTypesGenders: {
      countriesFetched: [],
      citiesFetched: [],
      docTypesFetched: [],
      gendersFetched: []
    }
  },

  // operative contiene toda la información relacionada solo con el rol operativo
  operative: {
    citiesFetched: [],
    docTypesFetched: [],
    statesFetched: [],
    gendersFetched: [],
    employeeCredentialEmail: '',
    employeeCredentialId: '',
    employeeId: '',
    idToken: '',
    newEntries: {
      users: {
        currentPage: 1,
        list: [],
        scrollMeasurement: 0
      },
      userEntryToBeReviewed: {
        userId: ''
      },
      userEntryToBeModified: {
        credentialId: '',
        personId: '',
        name: '',
        cell: '',
        email: '',
        documentIdType: '',
        documentIdNum: '',
        gender: '',
        documentPath: ''
      },
      entities: {
        currentPage: 1,
        list: [],
        scrollMeasurement: 0
      },
      entityEntryToBeReviewed: {
        buyerCredentialId: ''
      },
      entityEntryToBeModified: {
        buyerCredentialId: '',
        buyerId: '',
        companyComertialName: '',
        companyBussinesName: '',
        companyEmail: '',
        companyNit: '',
        buyerLegalRepresentative: '',
        buyerContactName: '',
        companyPhone: '',
        companyLogoPath: '',
        buyerRUTPath: ''
      },
      pqrsOfUnregisteredUsers: {
        currentPage: 1,
        list: [],
        scrollMeasurement: 0
      },
      pqrsOfUnregisteredUserToBeReviewed: {
        id: '',
        name: '',
        tel: '',
        email: '',
        asunto: '',
        requestId: ''
      },
      paginationOfUnreadPqrs: {
        paginationValues: {
          page: 0,
          limit: 0,
          scrollMeasurement: 0,
          totalItems: 0,
          totalPages: 0
        },
        setPaginationValues: null
      }
    },
    users: {
      usersManagement: {
        currentPage: 1,
        list: [],
        scrollMeasurement: 0
      },
      userManagementToBeReviewed: {
        specificInfo: {
          planName: '',
          personActive: false,
          name: '',
          idType: '',
          phone: '',
          idNum: '',
          email: '',
          personId: '',
          credentialId: ''
        },
        generalInfo: {
          creationDate: '',
          actualBalance: ''
        },
        blockedCompanies: [],
        authorizedCompanies: []
      },
      deleteRequestsManagement: {
        currentPage: 1,
        list: [],
        scrollMeasurement: 0
      },
      deleteRequestsToBeReviewed: {
        id: '',
        buyerId: '',
        userId: '',
        buyerCredentialId: '',
        credentialId: '',
        nameInList: '',
        name: '',
        identNumInList: '',
        identNum: '',
        phone: '',
        identType: '',
        email: '',
        userType: '',
        pqrsName: '',
        requestId: '',
        requestContent: '',
        createDate: '',
        balance: '',
        planName: ''
      },
      adminChangePlan: {
        currentPage: 1,
        list: [],
        scrollMeasurement: 0
      },
      adminChangePlanToBeReviewed: {
        specificInfo: {
          planName: '',
          personActive: false,
          name: '',
          idType: '',
          phone: '',
          idNum: '',
          email: '',
          personId: '',
          credentialId: ''
        },
        generalInfo: {
          creationDate: '',
          actualBalance: ''
        },
        planToChangeInfo: {
          planInfo: 0,
          planName: ''
        },
        blockedCompanies: [],
        authorizedCompanies: []
      },
      companyManagementToBeReviewed: {
        id: '',
        companyId: '',
        companyBussinesName: '',
        companyNit: '',
        companyComertialName: '',
        companyPhone: '',
        companyEmail: '',
        companyLogoPath: '',
        cityId: {}
      },
      companiesManagement: {
        currentPage: 1,
        list: [],
        scrollMeasurement: 0
      },
      massiveUploads: {
        csvData: null,
        company: ''
      },
      paymentHistoryPerContactNotAllowed: {
        currentPage: 1,
        list: [],
        scrollMeasurement: 0
      },
      historyPerCPDetailToBeReviewed: {}
    },
    movements: {
      userPocket: {
        currentPage: 1,
        list: [],
        scrollMeasurement: 0
      },
      userPocketToBeReviewed: {},
      paymentOfEntities: {
        currentPage: 1,
        list: [],
        scrollMeasurement: 0
      },
      paymentOfEntityToBeReviewed: {
        entityName: '',
        email: '',
        logo: '',
        movementHistory: []
      },
      tuDataControlPayments: {
        currentPage: 1,
        list: [],
        scrollMeasurement: 0
      },
      tuDataControlPaymentToBeReviewed: {
        demandId: ''
      }
    },
    pqrs: {
      pqrsList: {
        currentPage: 1,
        list: [],
        scrollMeasurement: 0
      },
      pqrsDetail: {}
    },
    roleName: '',
    rolId: null
  },

  // lawyer contiene toda la información relacionada solo con el rol abogado
  lawyer: {
    employeeCredentialEmail: '',
    employeeCredentialId: '',
    employeeId: '',
    idToken: '',
    roleName: '',
    rolId: null,
    requirementList: [],
    caseTracking: {
      caseTrackingList: {
        currentPage: 1,
        list: [],
        scrollMeasurement: 0
      },
      caseTrackingUserDetail: {},
      caseTrackingEntityDetail: {},
      entityWithoutChannelContactList: {
        currentPage: 1,
        list: [],
        scrollMeasurement: 0
      },
      entityWithoutChannelContactDetail: {}
    },
    requests: {
      responsesByEntity: {
        currentPage: 1,
        list: [],
        scrollMeasurement: 0
      },
      responseByEntityDetail: {},
      userAndEntityToBeReviewed: {
        userData: { userId: '', name: '', id: '', email: '' },
        idCompany: { id: '', name: '', email: '', nit: '' }
      }
    },
    notAllowedContact: {
      notAllowedContactList: {
        currentPage: 1,
        list: [],
        scrollMeasurement: 0
      },
      notAllowedContactDetail: {}
    }
  }
}

export const shoppingInfoObject = {
  shoppingHistory: {
    shoppingDate: '1970-01-01T00:00:00.000Z',
    expeditionDate: '1970-01-01T00:00:00.000Z',
    usersAmount: 0,
    databasePath: '',
    shoppingDbActive: false
  },
  download: ''
}
