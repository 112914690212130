import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { DataContext } from '../../context/DataContext'
import backIcon from '../../assets/icons/icon_volver.svg'
import { onCloseRequestAnsweredClick, onInputStatusClick, onSubmitClick, validateTextarea } from './UnregisteredUsersFunctions'
import { useModal } from '../../hooks/useModal'
import { Modal } from '../../components/Modal/Modal'
import { CharCounter } from '../../components/CharCounter/CharCounter'
import { ScriptContext } from '../../context/ScriptsContext'
import './UnregisteredUsers.css'

export const UnregisteredUsers = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const dataContext = useContext(DataContext)
  const { dataState } = dataContext
  const { idToken, newEntries, employeeId } = dataState.operative
  const { pqrsOfUnregisteredUserToBeReviewed } = newEntries
  const { asunto, email, name, tel, requestId, pqrsId } = pqrsOfUnregisteredUserToBeReviewed
  const [user, domain] = email.split('@')

  const [responseFields, setResponseFields] = useState({
    statusId: 0,
    response: '',
    employeeId
  })

  const [showModal, handleShow, handleClose] = useModal()
  const navigate = useNavigate()

  const [isRequesting, setIsRequesting] = useState(false)

  return (
    <>
      <div className='unregisteredUsersGeneralContainer'>
        <div className='unregisteredUsersGeneralSubcontainer'>
          <div className='unregisteredUsersSubcontainer1'>
            <h2 className='unregisteredUsersTitle'>{scriptsSave && scriptsSave.userString ? scriptsSave.userString.data : ''}</h2>
            <div>
              <h3 className='unregisteredUserH3'>{scriptsSave && scriptsSave.userString ? scriptsSave.userString.userName : ''}</h3>
              <p className='unregisteredUserP'>{name}</p>
            </div>
            <div>
              <h3 className='unregisteredUserH3'>{scriptsSave && scriptsSave.userString ? scriptsSave.userString.mail : ''}</h3>
              <p className='unregisteredUserP'>{user}<br />{domain}</p>
            </div>
            <div>
              <h3 className='unregisteredUserH3'>{scriptsSave && scriptsSave.userString ? scriptsSave.userString.phone : ''}</h3>
              <p className='unregisteredUserP'>{tel}</p>
            </div>
          </div>
          <div className='unregisteredUsersSubcontainer2'>
            <h3 className='unregisteredUsersTitle'>{scriptsSave && scriptsSave.compString ? scriptsSave.compString.subject : ''}</h3>
            <p className='unregisteredUsersTextArea1'>{asunto}</p>
          </div>
        </div>
        <div className='unregisteredUsersSubcontainer3'>
          <h3 className='unregisteredUsersBottomTitle'>
            {scriptsSave && scriptsSave.compString ? scriptsSave.compString.answer : ''}<span className='unregisteredUserH3Note'>{scriptsSave && scriptsSave.compString ? scriptsSave.compString.answerNote : ''}</span>
          </h3>
          <textarea
            className='unregisteredUsersTextArea2'
            onChange={(e) => validateTextarea(e, setResponseFields)}
            value={responseFields.response}
          />
          <CharCounter description={responseFields.response} />
        </div>
        <div className='unregisteredUsersFooter'>
          <Link to={'../UsuariosNoRegistrados'} className='unregisteredUsersFooterBackLink'>
            <img
              src={backIcon}
              alt={scriptsSave && scriptsSave.unregisteredUsersString ? scriptsSave.unregisteredUsersString.altImageTxt1 : ''}
            />
            <span>{scriptsSave && scriptsSave.compString ? scriptsSave.compString.return : ''}</span>
          </Link>
          <div className='unregisteredUsersInputsContainer'>
            <div className='inputRadioContainer'>
              <input
                className='unregisteredUsersInputRadio'
                type='radio'
                id='aceptar'
                name='opcion'
                value={2}
                checked={responseFields.statusId === 2}
                onChange={(e) => onInputStatusClick(e, setResponseFields)}
              />
              <label
                className='unregisteredUsersInputRadioLabel'
                htmlFor='aceptar'
              >
                {scriptsSave && scriptsSave.unregisteredUsersString ? scriptsSave.unregisteredUsersString.inputRadio1Text : ''}
              </label>
            </div>
            <div className='inputRadioContainer'>
              <input
                className='unregisteredUsersInputRadio'
                type='radio'
                id='rechazar'
                name='opcion'
                value={3}
                checked={responseFields.statusId === 3}
                onChange={(e) => onInputStatusClick(e, setResponseFields)}
              />
              <label
                className='unregisteredUsersInputRadioLabel'
                htmlFor='rechazar'
              >
                {scriptsSave && scriptsSave.unregisteredUsersString ? scriptsSave.unregisteredUsersString.inputRadio2Text : ''}
              </label>
            </div>
            <button
              onClick={() => onSubmitClick(
                pqrsId,
                requestId,
                responseFields,
                handleShow,
                idToken,
                dataContext,
                isRequesting,
                setIsRequesting
              )}
              className='unregisteredUserButton'
              disabled={responseFields.response.length < 10 || !responseFields.statusId || isRequesting}
            >
              {scriptsSave && scriptsSave.compString ? scriptsSave.compString.sendResponse : ''}
            </button>
          </div>
        </div>
      </div>
      <Modal isOpen={showModal}>
        <Modal.Content>
          <p>{scriptsSave && scriptsSave.unregisteredUsersString ? scriptsSave.unregisteredUsersString.modalTextContent1 : ''}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button
            className='Modal-orangeBtn'
            onClick={() => onCloseRequestAnsweredClick(handleClose, navigate)}
          >
            {scriptsSave && scriptsSave.unregisteredUsersString ? scriptsSave.unregisteredUsersString.modalBtn1Text1 : ''}
          </button>
        </Modal.Buttons>
      </Modal>
    </>
  )
}
