import Papa from 'papaparse'
import CryptoJS from 'crypto-js'
import { v4 as uuidv4 } from 'uuid'
import JSZip from 'jszip'

export const getAge = (birthday) => {
  const birthdayArr = birthday.split('-')
  const birthdayDate = new Date(
    birthdayArr[0],
    birthdayArr[1] - 1,
    birthdayArr[2]
  )
  const ageDifMs = Date.now() - birthdayDate.getTime()
  const ageDate = new Date(ageDifMs)
  const result = Math.abs(ageDate.getUTCFullYear()) - 1970
  return result
}

export const getTyping = (dataRegister) => {
  let typing = {}
  if (dataRegister.includes('users')) {
    typing = {
      useractive: true,
      userterms: true,
      userpolicies: true,
      credentialsenabled: true,
      identdocid: 'number',
      genderid: 'number',
      usercity: 'number',
      planid: 'number'
    }
  } else {
    if (dataRegister.includes('companies')) {
      typing = {
        cityId: 'number'
      }
    }
  }
  return typing
}

export const csvToJson = (file, dataRegister) => {
  const typing = getTyping(dataRegister)
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      dynamicTyping: typing,
      complete: (results) => {
        resolve(results.data)
      },
      error: (error) => {
        reject(error)
      }
    })
  })
}

export const removeIncompletes = async (data, dataRegister) => {
  let filteredData = {}
  if (dataRegister.includes('users')) {
    filteredData = data.filter((item) => {
      const hasUsername =
        hasOwnPropertySafe(item, 'username') && item.username.trim() !== ''
      const hasUserlastname =
        hasOwnPropertySafe(item, 'userlastname') &&
        item.userlastname.trim() !== ''
      const hasIdentdocid =
        hasOwnPropertySafe(item, 'identdocid') &&
        !isNaN(item.identdocid) &&
        item.identdocid.trim() !== ''
      const hasUseridentnum =
        hasOwnPropertySafe(item, 'useridentnum') && item.useridentnum !== ''
      const hasUserbirthdate =
        hasOwnPropertySafe(item, 'userbirthdate') &&
        item.userbirthdate.trim() !== ''
      const hasGenderid =
        hasOwnPropertySafe(item, 'genderid') &&
        !isNaN(item.genderid) &&
        item.genderid.trim() !== ''
      const hasUserphone =
        hasOwnPropertySafe(item, 'userphone') && item.userphone !== ''
      const hasUsercity =
        hasOwnPropertySafe(item, 'usercity') &&
        !isNaN(item.usercity) &&
        item.usercity.trim() !== ''
      const hasUseractive =
        hasOwnPropertySafe(item, 'useractive') &&
        typeof item.useractive === 'boolean'
      const hasUsericon =
        hasOwnPropertySafe(item, 'usericon') && item.usericon.trim() !== ''
      const hasPlanid =
        hasOwnPropertySafe(item, 'planid') &&
        !isNaN(item.planid) &&
        item.planid.trim() !== ''
      const hasUserterms =
        hasOwnPropertySafe(item, 'userterms') &&
        typeof item.userterms === 'boolean'
      const hasUserpolicies =
        hasOwnPropertySafe(item, 'userpolicies') &&
        typeof item.userpolicies === 'boolean'
      const hasUserdocumentpath =
        hasOwnPropertySafe(item, 'userdocumentpath') &&
        item.userdocumentpath.trim() !== ''
      const hasCredentialemail =
        hasOwnPropertySafe(item, 'credentialemail') &&
        item.credentialemail.trim() !== ''
      const hasCredentialpass =
        hasOwnPropertySafe(item, 'credentialpass') &&
        item.credentialpass.trim() !== ''
      const hasCredentialsenabled =
        hasOwnPropertySafe(item, 'credentialsenabled') &&
        typeof item.credentialsenabled === 'boolean'
      return (
        hasUsername &&
        hasUserlastname &&
        hasIdentdocid &&
        hasUseridentnum &&
        hasUserbirthdate &&
        hasGenderid &&
        hasUserphone &&
        hasUsercity &&
        hasUseractive &&
        hasUsericon &&
        hasPlanid &&
        hasUserterms &&
        hasUserpolicies &&
        hasUserdocumentpath &&
        hasCredentialemail &&
        hasCredentialpass &&
        hasCredentialsenabled
      )
    })
  } else {
    if (dataRegister.includes('companies')) {
      filteredData = data.filter((item) => {
        const hascompanybusinesName =
          hasOwnPropertySafe(item, 'companybusinesName') &&
          item.companybusinesName.trim() !== ''
        const hascompanycomercialName =
          hasOwnPropertySafe(item, 'companycomercialName') &&
          item.companycomercialName.trim() !== ''
        const hascompanynit =
          hasOwnPropertySafe(item, 'companynit') && item.companynit.trim() !== ''
        const hascityid =
          hasOwnPropertySafe(item, 'cityid') &&
          !isNaN(item.cityid) &&
          item.cityid.trim() !== ''
        const hascompanyphone =
          hasOwnPropertySafe(item, 'companyphone') &&
          item.companyphone.trim() !== ''
        const hascompanyemail =
          hasOwnPropertySafe(item, 'companyemail') &&
          item.companyemail.trim() !== ''
        const hascompanyLogo =
          hasOwnPropertySafe(item, 'companyLogo') &&
          item.companyLogo.trim() !== ''
        return (
          hascompanybusinesName &&
          hascompanycomercialName &&
          hascompanynit &&
          hascityid &&
          hascompanyphone &&
          hascompanyemail &&
          hascompanyLogo
        )
      })
    }
  }

  return filteredData
}

export const addIdstoObject = (object, idsGenerados) => {
  let nuevoID
  do {
    nuevoID =
      Math.floor(Math.random() * (1099999999 - 10000000 + 1)) + 10000000
  } while (idsGenerados[nuevoID])
  return { useridentnum: nuevoID, ...object }
}

export const updatefromPersons = (personsIds, users) => {
  return users.map((user) => {
    const persons = personsIds.find(
      (person) => person.useridentnum === user.useridentnum
    )
    const { useridentnum, ...userWithoutIdentnum } = user
    return {
      ...userWithoutIdentnum,
      personid: persons.personid
    }
  })
}

export const updatefromUsers = (usersIds, credentials) => {
  return credentials.map((credential) => {
    const users = usersIds.find(
      (user) => user.personid === credential.personid
    )
    const { personid, ...credentialWithoutIdentnum } = credential
    return {
      ...credentialWithoutIdentnum,
      userid: users.userid
    }
  })
}

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export const slashedFormatDateDDMMYYYY = (date) => {
  const formattedDate = new Date(date)
  const day = formattedDate.getDate().toString().padStart(2, '0')
  const month = (formattedDate.getMonth() + 1).toString().padStart(2, '0')
  const year = formattedDate.getFullYear().toString()
  return `${day}/${month}/${year}`
}

export const dashedFormatDateDDMMYYYY = (date) => {
  const formattedDate = new Date(date)
  const day = formattedDate.getDate().toString().padStart(2, '0')
  const month = (formattedDate.getMonth() + 1).toString().padStart(2, '0')
  const year = formattedDate.getFullYear().toString()
  return `${day}-${month}-${year}`
}

export const dashedFormatDateYYYYMMDD = (date) => {
  const formattedDate = new Date(date)
  const day = formattedDate.toLocaleDateString('es-ES', { day: '2-digit', timeZone: 'UTC' }).padStart(2, '0')
  const month = formattedDate.toLocaleDateString('es-ES', { month: '2-digit', timeZone: 'UTC' }).padStart(2, '0')
  const year = formattedDate.toLocaleDateString('es-ES', { year: 'numeric', timeZone: 'UTC' })
  return `${year}-${month}-${day}`
}

export const formatDateDDMMYYMonthInLetters = (date) => {
  const dateRaw = new Date(date)

  const year = dateRaw.getUTCFullYear()
  const month = String(dateRaw.getUTCMonth()).padStart(2, '0')
  const monthName = capitalizeFirstLetter(new Date(2000, month).toLocaleString('es-ES', { month: 'short' }))
  const day = String(dateRaw.getUTCDate()).padStart(2, '0')

  const dateFormatted = `${day} ${monthName} ${year}`

  return dateFormatted
}
export const formatDateDDMMYYMonthInLettersVersion2 = (date) => {
  const dateRaw = new Date(date.replace(/^"|"$/g, ''))

  const options = { day: '2-digit', month: 'short', year: 'numeric' }
  let dateFormatted = dateRaw.toLocaleDateString('es-ES', options)

  dateFormatted = dateFormatted.replace(/(\b[a-z](?!\s))/g, letter => letter.toUpperCase())
  return dateFormatted
}

export const formatDateToTimeHHMM = (fecha) => {
  const fechaObjeto = new Date(fecha)

  // Obtener las horas y los minutos
  const horas = fechaObjeto.getHours()
  const minutos = fechaObjeto.getMinutes()

  // Determinar si es AM o PM y ajustar las horas en consecuencia
  const ampm = horas >= 12 ? 'pm' : 'am'
  const horas12 = horas % 12 || 12 // Convertir las horas en formato de 12 horas

  // Formatear los minutos con ceros a la izquierda si es necesario
  const minutosFormateados = minutos < 10 ? '0' + minutos : minutos

  const horaFormateada = `${horas12}:${minutosFormateados} ${ampm}`

  return horaFormateada
}

export const formatDateFromDDMMYYYYSlashedToYYYYMMDDDashed = (date) => {
  // Dividir la fecha en sus componentes: día, mes y año
  const [day, month, year] = date.split('/')

  // Formatear la fecha al estilo 'YYYY-MM-DD'
  const dateFormatted = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
  return dateFormatted
}

export const passwordEncrypt = (password) => {
  const hash = CryptoJS.AES.encrypt(
    password,
    process.env.REACT_APP_SK
  ).toString()
  return hash
}

export const calculateNumberOfDigits = (array) => {
  const arrayLength = array?.length

  if (arrayLength < 1000) {
    return 3
  } else if (arrayLength < 10000) {
    return 4
  } else if (arrayLength < 100000) {
    return 5
  } else if (arrayLength < 1000000) {
    return 6
  } else if (arrayLength < 10000000) {
    return 7
  } else if (arrayLength < 100000000) {
    return 8
  } else if (arrayLength < 1000000000) {
    return 9
  } else if (arrayLength < 10000000000) {
    return 10
  } else {
    return 11
  }
}

export const handleViewDocument = (name, path, setState, handleShow) => {
  const extension = name.split('.')[1]
  const mediaExtensions = ['jpg', 'jpeg', 'png', 'gif']
  if (mediaExtensions.includes(extension)) {
    setState(path)
    handleShow()
  } else {
    const newWindow = window.open(path, '_blank')
    if (newWindow) {
      newWindow.opener = null
    }
  }
}

export const createAleatoryPassword = () => {
  let lettersArray = ''
  let numbersArray = ''
  let newPassword = ''

  while (lettersArray.length < 2 || numbersArray.length < 4) {
    newPassword = ''
    const genUuid = uuidv4().replace(/-/g, '')
    const accepted = '!#$%&*,.:?@[]^_{|}~'

    const lastDigits = genUuid.substring(genUuid.length - 12)
    const len2 = lastDigits.length

    lettersArray = lastDigits.split('').filter(d => isNaN(parseInt(d)))
    numbersArray = lastDigits.split('').filter(d => !isNaN(parseInt(d)))

    const middleLetterIndex = Math.floor(lettersArray.length / 2)
    const middleNumberIndex = Math.floor(numbersArray.length / 2)

    let lettersCount = 0
    let numbersCount = 0
    for (let i = 0; i < len2; i++) {
      if (isNaN(parseInt(lastDigits[i]))) {
        lettersCount++
        if (lettersCount === middleLetterIndex + 1) {
          newPassword += lastDigits[i].toUpperCase()
        } else {
          newPassword += lastDigits[i]
        }
      } else {
        numbersCount++
        if (numbersCount === middleNumberIndex + 1) {
          newPassword += accepted[Math.floor(Math.random() * accepted.length)]
        } else {
          newPassword += lastDigits[i]
        }
      }
    }
  }
  return newPassword
}

export const handleDownload = async (filesList) => {
  const zip = new JSZip()

  await Promise.all(
    filesList.map(async (url) => {
      const response = await fetch(url)
      const fileData = await response.blob()
      const fileName = url
        .substring(url.lastIndexOf('/') + 1)
        .split('?X-Amz')[0]
      zip.file(fileName, fileData)
    })
  )

  await zip.generateAsync({ type: 'blob' }).then((blob) => {
    const zipFileName = 'archivos.zip'
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = zipFileName
    link.click()
  })
}

export const generateAleatoryPassword = (formValues, setFormValues) => {
  const password = createAleatoryPassword()
  setFormValues({ ...formValues, password, passwordConfirmation: password })
}

export const hasOwnPropertySafe = (obj, prop) => {
  return Object.prototype.hasOwnProperty.call(obj, prop)
}

export const setDocumentUrl = (documentPath, setUserDocumentPathObject, setUrl, setIsImage) => {
  if (documentPath.length > 0) {
    const tempUrl = JSON.parse(documentPath)
    setUserDocumentPathObject(tempUrl)
    if (tempUrl && tempUrl.length > 0 && (tempUrl[0].userDocumentPath || tempUrl[0].buyerRUTPath)) {
      setUrl(tempUrl[0].userDocumentPath || tempUrl[0].buyerRUTPath)
    } else {
      setUrl(null)
    }
    tempUrl.forEach((document) => {
      if (document.type === 'pdf') {
        setIsImage(false)
      }
    })
  }
}

export const isExpired = (dateGotten) => {
  const expeditionDate = new Date(dateGotten)
  const currentDate = new Date()

  return !(expeditionDate > currentDate)
}

export const downloadingFilesModal = (setModalMessage, handleShow, handleClose) => {
  let text = 'Descargando archivos'
  setModalMessage(text)
  handleShow()
  let dots = 0
  let addingDots = true

  const updateText = () => {
    if (addingDots) {
      text += ' . '
      setModalMessage(text)
      dots++
      if (dots >= 3) {
        addingDots = false
      }
    } else {
      text = text.slice(0, -9)
      setModalMessage(text)
      dots = 0
      if (dots <= 0) {
        addingDots = true
      }
    }
  }
  const intervalId = setInterval(updateText, 1000)

  const clearIntervalFn = () => {
    clearInterval(intervalId)
    handleClose()
  }
  return { clearIntervalFn }
}

export const downloadFilesWithJSZip = async (urls) => {
  const zip = new JSZip()

  console.time('Descargar archivos')
  for (let i = 0; i < urls.length; i++) {
    const url = urls[i]
    const response = await fetch(url)
    const fileData = await response.blob()
    const fileName = url
      .substring(url.lastIndexOf('/') + 1)
      .split('?X-Amz')[0]
    zip.file(fileName, fileData)
  }
  console.timeEnd('Descargar archivos')

  await zip.generateAsync({ type: 'blob' })
    .then((blob) => {
      const zipFileName = 'anexos.zip'
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = zipFileName
      link.click()
    })
}

export const addElipsisAtEnd = (text, charsToShow) => {
  return text.length >= charsToShow ? text.substring(0, charsToShow) + '...' : text
}

export const saveUserData = (email, pass) => {
  const encryptedPass = CryptoJS.AES.encrypt(pass, 'tudata-secret-key').toString()
  localStorage.setItem('adminEmail', email)
  localStorage.setItem('adminPass', encryptedPass)
}

export const getUserData = () => {
  const adminEmail = localStorage.getItem('adminEmail') ?? ''
  const encryptedPass = localStorage.getItem('adminPass') ?? ''
  const decryptedPass = CryptoJS.AES.decrypt(encryptedPass, 'tudata-secret-key').toString(CryptoJS.enc.Utf8)
  return { adminEmail, adminPass: decryptedPass }
}

export const disableForwardAndBackwardNavigation = () => {
  window.history.pushState(null, null, window.location.href)
  window.onpopstate = function () {
    window.history.pushState(null, null, window.location.href)
  }
}

export const textWithoutEspecials = (text) => {
  const accentuated = {
    á: 'a',
    é: 'e',
    í: 'i',
    ó: 'o',
    ú: 'u',
    Á: 'A',
    É: 'E',
    Í: 'I',
    Ó: 'O',
    Ú: 'U',
    ñ: 'n',
    Ñ: 'N',
    ü: 'u',
    Ü: 'U'
  }

  text = text.replace(/[áéíóúÁÉÍÓÚñÑüÜ]/g, match => accentuated[match])
  text = text.replace(/\s+/g, '_')
  text = text.replace(/[^a-zA-Z0-9_.]/g, '')

  return text
}
