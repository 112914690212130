import { useEffect, useState } from 'react'
import { employeeString } from '../../../utils/strings'

export const useIncorrectInputFormat = (personId) => {
  const defaultValues = {
    birthdayDateFormat: 'employeeRightFormat',
    birthdayDateMessage: employeeString.mandatoryField,
    cityFormat: 'employeeRightFormat',
    cityMessage: employeeString.mandatoryField,
    countryFormat: 'employeeRightFormat',
    countryMessage: employeeString.mandatoryField,
    documentExpeditionCityFormat: 'employeeRightFormat',
    documentExpeditionCityMessage: employeeString.mandatoryField,
    documentNumberFormat: 'employeeRightFormat',
    documentNumberMessage: employeeString.mandatoryField,
    documentTypeIdFormat: 'employeeRightFormat',
    documentTypeIdMessage: employeeString.mandatoryField,
    emailFormat: 'employeeRightFormat',
    emailMessage: employeeString.mandatoryField,
    genderFormat: 'employeeRightFormat',
    genderMessage: employeeString.mandatoryField,
    lastNameFormat: 'employeeRightFormat',
    lastNameMessage: employeeString.mandatoryField,
    nameFormat: 'employeeRightFormat',
    nameMessage: employeeString.mandatoryField,
    passwordConfirmationFormat: 'employeeRightFormat',
    passwordConfirmationMessage: employeeString.mandatoryField,
    passwordFormat: 'employeeRightFormat',
    passwordMessage: employeeString.mandatoryField,
    phoneFormat: 'employeeRightFormat',
    phoneMessage: employeeString.mandatoryField
  }

  const [incorrectInputFormat, setIncorrectInputFormat] = useState(defaultValues)

  useEffect(() => {
    if (personId) setIncorrectInputFormat(defaultValues)
  }, [personId])

  return { incorrectInputFormat, setIncorrectInputFormat }
}
