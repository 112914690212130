import React, { useContext } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { NavLink, Outlet, useNavigate } from 'react-router-dom'
import parse from 'html-react-parser'

import logoApp from '../../assets/icons/logo.svg'
import logoPersonaPlus from '../../assets/icons/icon_userplus.svg'
import logoRequests from '../../assets/icons/icon_solicitudes.svg'
import logoPersonaLlave from '../../assets/icons/icon_bloq_empresas.svg'
import logoGrupoPersonas from '../../assets/icons/icon_users.svg'
import logoPregunta from '../../assets/icons/icon_consultas.svg'
import logoMovimientos from '../../assets/icons/icon_movimientos.svg'
import logoContacto from '../../assets/icons/icon_contacto.svg'
import logoUpdateInfo from '../../assets/icons/icon_updateInfo.svg'
import dataBaseLogo from '../../assets/icons/icon_bd2.svg'
import iconCerrar from '../../assets/icons/icon_cerrar.svg'
import { useModal } from '../../hooks/useModal'
import { Modal } from '../../components/Modal/Modal'
import { DataContext } from '../../context/DataContext'
import { removeData } from './BaseAdministrativoFunctions'
import { roles } from '../../utils/objects'
import iconEmpresas from '../../assets/icons/iconEmpresa.png'
import './BaseAdministrativo.css'
import { ScriptContext } from '../../context/ScriptsContext'

export const BaseAdministrativo = () => {
  const { dataState, dataDispatch } = useContext(DataContext)
  const { scriptsSave } = useContext(ScriptContext)
  const { role } = dataState.profile
  const { administrative, operative, lawyer } = dataState
  const employeeId =
    administrative.employeeId || operative.employeeId || lawyer.employeeId
  const [showModal, handleShow, handleClose] = useModal()
  const navigate = useNavigate()

  return (
    <div className="mainContainerBaseAdministrativo">
      <Container>
        <Row>
          <Col className="colMenuAdministrativo">
            <div className="subContainerMenuAdministrativo1">
              <div className="mainLogoContainer">
                <img
                  className="logoApp"
                  src={logoApp}
                  alt={scriptsSave && scriptsSave.indexString ? scriptsSave.indexString.altImgTxt1 : ''}
                />
                <p className="logoParagraph">
                  {
                    role === roles.ADMINISTRATIVO
                      ? `${scriptsSave && scriptsSave.indexString ? scriptsSave.indexString.administrative : ''}`
                      : role === roles.OPERATIVO
                        ? `${scriptsSave && scriptsSave.indexString ? scriptsSave.indexString.operative : ''}`
                        : `${scriptsSave && scriptsSave.indexString ? scriptsSave.indexString.lawyer : ''}`
                  }
                </p>
              </div>
              <div className='baseAdministrativoMenu'>
                {
                  role === roles.OPERATIVO && (
                    <NavLink
                      to={`${employeeId}/Entradas`}
                      className={({ isActive }) =>
                        `navbar-item ${isActive ? 'item-selected' : ''}`
                      }
                    >
                      <img
                        className="iconoPersonaBaseA"
                        src={logoPersonaPlus}
                        alt={scriptsSave && scriptsSave.indexString ? scriptsSave.indexString.altImgTxt2 : ''}
                      />
                      <p className="paragraphSolicitudes">
                        {scriptsSave && scriptsSave.indexString ? scriptsSave.indexString.entries : ''}
                      </p>
                    </NavLink>
                  )
                }
                {
                  role === roles.ABOGADO && (
                    <NavLink
                      to={`${employeeId}/GestionUsuarios`}
                      className={({ isActive }) =>
                        `navbar-item ${isActive ? 'item-selected' : ''}`
                      }
                    >
                      <img
                        className="iconoPersonaBaseA"
                        src={logoRequests}
                        alt={scriptsSave && scriptsSave.indexString ? scriptsSave.indexString.altImgTxt3 : ''}
                      />
                      <p className="paragraphSolicitudes">
                        {scriptsSave && scriptsSave.indexString ? parse(scriptsSave.indexString.userManagement) : ''}
                      </p>
                    </NavLink>
                  )
                }
                {
                  role === 'Oculto' && (
                    <NavLink
                      to={`${employeeId}/Entidades`}
                      className={({ isActive }) =>
                        `navbar-item ${isActive ? 'item-selected' : ''}`
                      }
                    >
                      <img
                        className="iconoPersonaBaseA"
                        src={logoPersonaLlave}
                        alt={scriptsSave && scriptsSave.indexString ? scriptsSave.indexString.altImgTxt4 : ''}
                      />
                      <p className="paragraphValidaciones">
                        {scriptsSave && scriptsSave.indexString ? scriptsSave.indexString.entities : ''}
                      </p>
                    </NavLink>
                  )
                }
                {
                  role === roles.OPERATIVO && (
                    <NavLink
                      to={`${employeeId}/Usuarios`}
                      className={({ isActive }) =>
                        `navbar-item ${isActive ? 'item-selected' : ''}`
                      }
                    >
                      <img
                        className="iconoPersonaBaseA"
                        src={logoGrupoPersonas}
                        alt={scriptsSave && scriptsSave.indexString ? scriptsSave.indexString.altImgTxt5 : ''}
                      />
                      <p className="paragraphUsuarios">
                        {scriptsSave && scriptsSave.indexString ? scriptsSave.indexString.users : ''}
                      </p>
                    </NavLink>
                  )
                }
                {
                  role === roles.OPERATIVO && (
                    <NavLink
                      to={`${employeeId}/Empresas`}
                      className={({ isActive }) =>
                        `navbar-item ${isActive ? 'item-selected' : ''}`
                      }
                    >
                      <img
                        className="iconoPersonaBaseA"
                        src={iconEmpresas}
                        alt={scriptsSave && scriptsSave.indexString ? scriptsSave.indexString.altImgTxt6 : ''}
                      />
                      <p className="paragraphPQRS">
                        {scriptsSave && scriptsSave.indexString ? scriptsSave.indexString.companies : ''}
                      </p>
                    </NavLink>
                  )
                }
                {
                  role === roles.ADMINISTRATIVO && (
                    <NavLink
                      to={`${employeeId}/UsuariosAdmin`}
                      className={({ isActive }) =>
                        `navbar-item ${isActive ? 'item-selected' : ''}`
                      }
                    >
                      <img
                        className="iconoPersonaBaseA"
                        src={logoGrupoPersonas}
                        alt={scriptsSave && scriptsSave.indexString ? scriptsSave.indexString.altImgTxt7 : ''}
                      />
                      <p className="paragraphUsuarios">
                        {scriptsSave && scriptsSave.indexString ? scriptsSave.indexString.users : ''}
                      </p>
                    </NavLink>
                  )
                }
                {
                  role === roles.ADMINISTRATIVO && (
                    <NavLink
                      to={`${employeeId}/DB`}
                      className={({ isActive }) =>
                        `navbar-item ${isActive ? 'item-selected' : ''}`
                      }
                    >
                      <img
                        className="iconoPersonaBaseA"
                        src={dataBaseLogo}
                        alt={scriptsSave && scriptsSave.indexString ? scriptsSave.indexString.altImgTxt8 : ''}
                      />
                      <p className="paragraphUsuarios">
                        {scriptsSave && scriptsSave.indexString ? parse(scriptsSave.indexString.dataBases) : ''}
                      </p>
                    </NavLink>
                  )
                }
                {
                  role === roles.ADMINISTRATIVO && (
                    <NavLink
                      to={`${employeeId}/ActualizacionInfo`}
                      className={({ isActive }) =>
                        `navbar-item ${isActive ? 'item-selected' : ''}`
                      }
                    >
                      <img
                        className="iconoPersonaBaseA--small"
                        src={logoUpdateInfo}
                        alt={scriptsSave && scriptsSave.indexString ? scriptsSave.indexString.altImgTxt11 : ''}
                      />
                      <p className="paragraphPQRS">
                        {scriptsSave && scriptsSave.indexString ? scriptsSave.indexString.infoUpdate : ''}
                      </p>
                    </NavLink>
                  )
                }
                {
                  role === roles.OPERATIVO && (
                    <NavLink
                      to={`${employeeId}/PQRS`}
                      className={({ isActive }) =>
                        `navbar-item ${isActive ? 'item-selected' : ''}`
                      }
                    >
                      <img
                        className="iconoPersonaBaseA"
                        src={logoPregunta}
                        alt={scriptsSave && scriptsSave.indexString ? scriptsSave.indexString.altImgTxt9 : ''}
                      />
                      <p className="paragraphPQRS">
                        {scriptsSave && scriptsSave.indexString ? scriptsSave.indexString.pqrs : ''}
                      </p>
                    </NavLink>
                  )
                }
                {
                  role === roles.OPERATIVO && (
                    <NavLink
                      to={`${employeeId}/Movimientos`}
                      className={({ isActive }) =>
                        `navbar-item ${isActive ? 'item-selected' : ''}`
                      }
                    >
                      <img
                        className="iconoPersonaBaseA"
                        src={logoMovimientos}
                        alt={scriptsSave && scriptsSave.indexString ? scriptsSave.indexString.altImgTxt10 : ''}
                      />
                      <p className="paragraphPQRS">
                        {scriptsSave && scriptsSave.indexString ? scriptsSave.indexString.movements : ''}
                      </p>
                    </NavLink>
                  )
                }
                {
                  role === roles.ABOGADO && (
                    <NavLink
                      to={`${employeeId}/Contacto`}
                      className={({ isActive }) =>
                        `navbar-item ${isActive ? 'item-selected' : ''}`
                      }
                    >
                      <img
                        className="iconoPersonaBaseA"
                        src={logoContacto}
                        alt={scriptsSave && scriptsSave.indexString ? scriptsSave.indexString.altImgTxt12 : ''}
                      />
                      <p className="paragraphPQRS">
                        {scriptsSave && scriptsSave.indexString ? scriptsSave.indexString.contact : ''}
                      </p>
                    </NavLink>
                  )
                }
              </div>
            </div>

            <div className="subContainerMenu2">
              <div className="logoutContainer" onClick={handleShow}>
                <img
                  src={iconCerrar}
                  alt={scriptsSave && scriptsSave.indexString ? scriptsSave.indexString.altImgTxt13 : ''}
                />
                <p>{scriptsSave && scriptsSave.userString ? scriptsSave.userString.quit : ''}</p>
              </div>
            </div>
            <Modal isOpen={showModal}>
              <Modal.Content>
                <p>{scriptsSave && scriptsSave.userString ? scriptsSave.userString.quitAsk : ''}</p>
              </Modal.Content>
              <Modal.Buttons>
                <button onClick={handleClose} className="Modal-normalBtn">
                  {scriptsSave && scriptsSave.requestsString ? scriptsSave.requestsString.cancel : ''}
                </button>
                <button
                  onClick={() => removeData({ navigate, dataDispatch })}
                  className="Modal-orangeBtn"
                >
                  {scriptsSave && scriptsSave.requestsString ? scriptsSave.requestsString.continue : ''}
                </button>
              </Modal.Buttons>
            </Modal>
          </Col>

          <Col className="colDatos" md="auto">
            <div className="subContainer">
              <Outlet />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
