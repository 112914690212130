import React, { useContext, useEffect, useState } from 'react'
import userCompany from '../../../../assets/images/userCompany.png'
import './EditPQRSBuyer.css'
import { CustomSelect } from '../../../../components/CustomSelect/CustomSelect'
import backIcon from '../../../../assets/icons/icon_volver.svg'
import { useNavigate } from 'react-router-dom'
import {
  cancelEdit,
  fetchData,
  getLocationValues,
  handleSubmit
} from './EditPQRSBuyerFunctions'
import { DataContext } from '../../../../context/DataContext'
import {
  onAddressChange,
  onDocumentRepNumberChange,
  onEmailChange,
  onInputChange,
  onIsValidEmailBlur,
  onIsValidExpCityBlur,
  onIsValidInputBlur,
  onIsValidPhoneBlur,
  onIsValidRepDocumentNumberBlur,
  onPhoneChange
} from '../../../UsersAdmin/utils/createAndEditUserMethods'
import { maxSize } from '../../../../utils/validateFunctions'
import { useIncorrectInputFormat } from '../../../UsersAdmin/hooks/useIncorrectInputFormat'
import { compareValues } from '../EditPQRSUser/EditPQRSUserFunctions'
import { Modal } from '../../../../components/Modal/Modal'
import { useModal } from '../../../../hooks/useModal'
import { ScriptContext } from '../../../../context/ScriptsContext'

export const EditPQRSBuyer = ({ state }) => {
  const { scriptsSave } = useContext(ScriptContext)
  const { id: buyerId } = state
  const navigate = useNavigate()
  const dataContext = useContext(DataContext)
  const { dataState, dataDispatch } = dataContext
  const { idToken, citiesFetched, statesFetched } = dataState.operative
  const [initialValues, setInitialValues] = useState({})
  const [newValues, setNewValues] = useState({})
  const [isDisabled, setIsDisabled] = useState(false)

  const [showSaveModal, handleSaveModalShow] = useModal()
  const [showCancelModal, handleCancelModalShow, handleCancelModalClose] =
    useModal()

  const { incorrectInputFormat, setIncorrectInputFormat } = useIncorrectInputFormat()

  useEffect(() => {
    fetchData({
      idToken,
      dataContext,
      buyerId,
      setInitialValues,
      setNewValues
    })
    getLocationValues({ citiesFetched, statesFetched, dataDispatch })
  }, [])

  useEffect(() => {
    const valuesAreDifferent = compareValues(initialValues, newValues)
    valuesAreDifferent.length ? setIsDisabled(false) : setIsDisabled(true)
  }, [newValues])

  const handleIDType = (optionChoosed) =>
    setNewValues({ ...newValues, typeId: optionChoosed })
  return (
    <section id="EditPQRSBuyer">
      <img
        src={userCompany}
        className="EditPQRSBuyerImg"
        alt={scriptsSave && scriptsSave.buyerPQRSEditString ? scriptsSave.buyerPQRSEditString.altImageTxt1 : ''}
      />
      <form className="EditPQRSBuyerForm">
        <div className="EditPQRSBuyerFormRow">
          <div className="EditPQRSBuyerFormField soloField">
            <p className="EditPQRSBuyerFormKey">
              {scriptsSave && scriptsSave.buyerPQRSEditString ? scriptsSave.buyerPQRSEditString.legalRepName : ''}
            </p>
            <input
              type="text"
              name="legalRepName"
              value={newValues?.legalRepName}
              onChange={(e) => onInputChange(e, newValues, setNewValues)}
              onInput={(e) => maxSize(e, 'compName')}
              onBlur={(e) =>
                onIsValidInputBlur(
                  e,
                  newValues,
                  incorrectInputFormat,
                  setIncorrectInputFormat
                )
              }
              className={`${
                incorrectInputFormat.nameFormat === 'employeeRightFormat'
                  ? 'EditPQRSBuyerFormInput'
                  : 'editUserInputIncorrectFormat'
              }`}
            />
            <span className={incorrectInputFormat.nameFormat}>
              {incorrectInputFormat.nameMessage}
            </span>
          </div>
        </div>
        <div className="EditPQRSBuyerFormRow">
          <div className="EditPQRSBuyerFormField doubleField">
            <div className="doubleFieldLeft">
              <p className="EditPQRSBuyerFormKey">
                {scriptsSave && scriptsSave.buyerPQRSEditString ? scriptsSave.buyerPQRSEditString.repTypeId : ''}
              </p>
              <CustomSelect
                defaultValue={newValues?.typeId}
                selector={handleIDType}
              >
                <option
                  className="CustomSelectOptionsItem"
                  value={'Cédula de ciudadanía'}
                >
                  {scriptsSave && scriptsSave.userPQRSEditString ? scriptsSave.userPQRSEditString.typeId.CC : ''}
                </option>
              </CustomSelect>
            </div>
            <div className="doubleFieldRight">
              <p className="EditPQRSBuyerFormKey">
                {scriptsSave && scriptsSave.buyerPQRSEditString ? scriptsSave.buyerPQRSEditString.repId : ''}
              </p>
              <input
                type="text"
                name="repId"
                value={newValues?.repId}
                onChange={(e) =>
                  onDocumentRepNumberChange(e, newValues, setNewValues)
                }
                onBlur={() =>
                  onIsValidRepDocumentNumberBlur(
                    dataContext,
                    newValues,
                    setNewValues,
                    incorrectInputFormat,
                    setIncorrectInputFormat,
                    idToken,
                    initialValues
                  )
                }
                className={`${
                  incorrectInputFormat.documentNumberFormat ===
                  'employeeRightFormat'
                    ? 'EditPQRSBuyerFormInput'
                    : 'editUserInputIncorrectFormat'
                }`}
              />
              <span className={incorrectInputFormat.documentNumberFormat}>
                {' '}
                {incorrectInputFormat.documentNumberMessage}
              </span>
            </div>
          </div>
          <div className="EditPQRSBuyerFormField">
            <p className="EditPQRSBuyerFormKey">
              {scriptsSave && scriptsSave.buyerPQRSEditString ? scriptsSave.buyerPQRSEditString.expCity : ''}
            </p>
            <input
              type="text"
              name="expCity"
              value={newValues?.expCity}
              onChange={(e) => onInputChange(e, newValues, setNewValues)}
              onInput={(e) => maxSize(e, 'compName')}
              onBlur={() =>
                onIsValidExpCityBlur(
                  newValues,
                  setNewValues,
                  incorrectInputFormat,
                  setIncorrectInputFormat,
                  citiesFetched
                )
              }
              className={`${
                incorrectInputFormat.nameFormat === 'employeeRightFormat'
                  ? 'EditPQRSBuyerFormInput'
                  : 'editUserInputIncorrectFormat'
              }`}
            />
            <span className={incorrectInputFormat.nameFormat}>
              {incorrectInputFormat.nameMessage}
            </span>
          </div>
        </div>
        <div className="EditPQRSBuyerFormRow">
          <div className="EditPQRSBuyerFormField">
            <p className="EditPQRSBuyerFormKey">
              {scriptsSave && scriptsSave.buyerPQRSEditString ? scriptsSave.buyerPQRSEditString.contactName : ''}
            </p>
            <input
              type="text"
              name="contactName"
              value={newValues?.contactName}
              onChange={(e) => onInputChange(e, newValues, setNewValues)}
              onInput={(e) => maxSize(e, 'compName')}
              onBlur={(e) =>
                onIsValidInputBlur(
                  e,
                  newValues,
                  incorrectInputFormat,
                  setIncorrectInputFormat
                )
              }
              className={`${
                incorrectInputFormat.nameFormat === 'employeeRightFormat'
                  ? 'EditPQRSBuyerFormInput'
                  : 'editUserInputIncorrectFormat'
              }`}
            />
            <span className={incorrectInputFormat.nameFormat}>
              {incorrectInputFormat.nameMessage}
            </span>
          </div>
          <div className="EditPQRSBuyerFormField">
            <p className="EditPQRSBuyerFormKey">
              {scriptsSave && scriptsSave.buyerPQRSEditString ? scriptsSave.buyerPQRSEditString.address : ''}
            </p>
            <input
              type="text"
              name="address"
              value={newValues?.address}
              onChange={(e) => onAddressChange(e, newValues, setNewValues)}
              onInput={(e) => maxSize(e, 'compName')}
              onBlur={(e) =>
                onIsValidInputBlur(
                  e,
                  newValues,
                  incorrectInputFormat,
                  setIncorrectInputFormat
                )
              }
              className={`${
                incorrectInputFormat.nameFormat === 'employeeRightFormat'
                  ? 'EditPQRSBuyerFormInput'
                  : 'editUserInputIncorrectFormat'
              }`}
            />
            <span className={incorrectInputFormat.nameFormat}>
              {incorrectInputFormat.nameMessage}
            </span>
          </div>
        </div>
        <div className="EditPQRSBuyerFormRow">
          <div className="EditPQRSBuyerFormField">
            <p className="EditPQRSBuyerFormKey">{scriptsSave && scriptsSave.buyerPQRSEditString ? scriptsSave.buyerPQRSEditString.email : ''}</p>
            <input
              type="text"
              name="email"
              value={newValues?.email}
              onChange={(e) => onEmailChange(e, newValues, setNewValues)}
              onBlur={() =>
                onIsValidEmailBlur(
                  dataContext,
                  newValues,
                  setNewValues,
                  incorrectInputFormat,
                  setIncorrectInputFormat,
                  idToken,
                  initialValues
                )
              }
              className={`${
                incorrectInputFormat.emailFormat === 'employeeRightFormat'
                  ? 'EditPQRSBuyerFormInput'
                  : 'editUserInputIncorrectFormat'
              }`}
            />
            <span className={incorrectInputFormat.emailFormat}>
              {incorrectInputFormat.emailMessage}
            </span>
          </div>
          <div className="EditPQRSBuyerFormField">
            <p className="EditPQRSBuyerFormKey">{scriptsSave && scriptsSave.buyerPQRSEditString ? scriptsSave.buyerPQRSEditString.phone : ''}</p>
            <input
              type="text"
              name="phone"
              value={newValues?.phone}
              onChange={(e) => onPhoneChange(e, newValues, setNewValues)}
              onBlur={() =>
                onIsValidPhoneBlur(
                  dataContext,
                  newValues,
                  setNewValues,
                  incorrectInputFormat,
                  setIncorrectInputFormat,
                  idToken,
                  initialValues
                )
              }
              className={`${
                incorrectInputFormat.phoneFormat === 'employeeRightFormat'
                  ? 'EditPQRSBuyerFormInput'
                  : 'editUserInputIncorrectFormat'
              }`}
            />
            <span className={incorrectInputFormat.phoneFormat}>
              {incorrectInputFormat.phoneMessage}
            </span>
          </div>
        </div>
        <div className="navFooter">
          <button
            className="navFooterBackButton"
            onClick={(e) =>
              cancelEdit(e, isDisabled, handleCancelModalShow, navigate, state)
            }
          >
            <img
              className="navFooterBackLinkImage"
              src={backIcon}
              alt={scriptsSave && scriptsSave.buyerPQRSEditString ? scriptsSave.buyerPQRSEditString.altImageTxt2 : ''}
            />
            <span className="navFooterBackLinkText">
              {scriptsSave && scriptsSave.navFooterStrings ? scriptsSave.navFooterStrings.goBack : ''}
            </span>
          </button>
          <div className="navFooterButtonsContainer">
            <button
              className="EditPQRSUserBtn"
              onClick={(e) =>
                cancelEdit(e, isDisabled, handleCancelModalShow, navigate, state)
              }
            >
              {scriptsSave && scriptsSave.buyerPQRSEditString ? scriptsSave.buyerPQRSEditString.cancel : ''}
            </button>
            <button
              className="EditPQRSUserBtn"
              disabled={isDisabled}
              onClick={(e) =>
                handleSubmit(
                  e,
                  idToken,
                  newValues,
                  initialValues,
                  handleCancelModalClose,
                  handleSaveModalShow,
                  dataContext,
                  setIsDisabled
                )
              }
            >
              {scriptsSave && scriptsSave.buyerPQRSEditString ? scriptsSave.buyerPQRSEditString.save : ''}
            </button>
          </div>
        </div>
      </form>
      <Modal isOpen={showSaveModal}>
        <Modal.Content>
          <p>{scriptsSave && scriptsSave.userPQRSEditString ? scriptsSave.userPQRSEditString.saveModalMessage : ''}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button className="Modal-orangeBtn" onClick={() => navigate('../SolicitudDelUsuario', { state })}>
            {scriptsSave && scriptsSave.userPQRSEditString ? scriptsSave.userPQRSEditString.continue : ''}
          </button>
        </Modal.Buttons>
      </Modal>
      <Modal isOpen={showCancelModal}>
        <Modal.Content>
          <p>{scriptsSave && scriptsSave.userPQRSEditString ? scriptsSave.userPQRSEditString.cancelModalMessage : ''}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button className="Modal-normalBtn" onClick={() => navigate('../SolicitudDelUsuario', { state })}>
            {scriptsSave && scriptsSave.userPQRSEditString ? scriptsSave.userPQRSEditString.cancel : ''}
          </button>
          <button
            className="Modal-orangeBtn"
            onClick={(e) =>
              handleSubmit(
                e,
                idToken,
                newValues,
                initialValues,
                handleCancelModalClose,
                handleSaveModalShow,
                dataContext
              )
            }
          >
            {scriptsSave && scriptsSave.userPQRSEditString ? scriptsSave.userPQRSEditString.saveChanges : ''}
          </button>
        </Modal.Buttons>
      </Modal>
    </section>
  )
}
