import React, { useContext, useEffect, useState } from 'react'
// import parse from 'html-react-parser'
import { CustomSelect } from '../../../../components/CustomSelect/CustomSelect'
import { getLocationValues } from '../EditPQRSUser/EditPQRSUserFunctions'
import { DataContext } from '../../../../context/DataContext'
import { maxSize, validateCellphoneLength, validateCompanyName, validateNitLength } from '../../../../utils/validateFunctions'
import { onEditEmailBlur, onEditEmailChange, onFormInputChange, onNitChange, onPhoneChange, validateInputOnBlur } from '../../../UsersAdmin/utils/createAndEditUserMethods'
import { cancelEdit } from '../CreateNewCompany/CreateNewCompanyFunctions'
import { useNavigate } from 'react-router-dom'
import { Modal } from '../../../../components/Modal/Modal'
import { useModal } from '../../../../hooks/useModal'
import backIcon from '../../../../assets/icons/icon_volver.svg'
import userCompanyImg from '../../../../assets/images/userCompany.png'
import './editCompany.css'
import { getCompanyCredentialsExistsValidation, getCompanyPhoneExistsValidation } from '../../../../utils/userQueries'
import { handleSubmit, onFirstCheckboxChange, onSecondCheckboxChange, onThirdCheckboxChange, onUploadLogoClick, wasDataUpdated } from './EditCompanyFunctions'
import habilitarIcon from '../../../../assets/icons/icon_bien.svg'
import { useIncorrectInputFormat } from '../../hooks/useIncorrectInputFormat'
import { ScriptContext } from '../../../../context/ScriptsContext'

export const EditCompany = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const navigate = useNavigate()
  const dataContext = useContext(DataContext)
  const { dataState, dataDispatch } = dataContext
  const { idToken, citiesFetched, statesFetched, users } = dataState.operative
  const companyInfo = users?.companyManagementToBeReviewed
  const [showModal, handleShow, handleClose] = useModal()
  const [modalMessage, setModalMessage] = useState('')

  const inputValues = {
    companyId: null,
    state: null,
    city: null,
    companyNit: null,
    email: null,
    phone: null,
    bussinessName: null,
    comertialName: null,
    file: null,
    directRemitent: false,
    categoryCompany: {
      bank: false,
      publicServices: false,
      noChannel: false
    }
  }

  const [createValues, setCreateValues] = useState(inputValues)
  const [validateValues, setValidateValues] = useState(inputValues)
  const [citiesFiltered, setCitiesFiltered] = useState([])
  const [isDisabled, setisDisabled] = useState(true)

  const [showSaveModal, handleSaveModalShow] = useModal()
  const [showCancelModal, handleCancelModalShow, handleCancelModalClose] = useModal()
  const [incorrectInputFormat, setIncorrectInputFormat] = useIncorrectInputFormat()
  const [isComertialName, setIsComertialName] = useState(false)
  const [isBussinessName, setIsBussinessName] = useState(false)

  useEffect(() => {
    getLocationValues({ citiesFetched, statesFetched, dataDispatch })
  }, [])

  useEffect(() => {
    const values = Object.values(createValues)
    const areSomeNull = values.map((value) => value === null || value === '').includes(true)
    if (!areSomeNull) {
      const valuestToValidate = Object.values(validateValues)
      const areWrongFields = valuestToValidate.map((value) => value === true).includes(true)
      setisDisabled(areWrongFields)
      if (createValues.bussinessName.length < 3) setisDisabled(true)
    } else {
      setisDisabled(true)
    }
  }, [createValues, validateValues])

  useEffect(() => {
    const stateChoosedId = createValues?.state || 1
    const citiesFiltered = citiesFetched.filter(
      (city) =>
        city.stateId.stateId === stateChoosedId
    )
    setCitiesFiltered(citiesFiltered)
  }, [citiesFetched, createValues?.state])

  useEffect(() => {
    if (companyInfo) {
      setCreateValues({
        companyId: companyInfo.companyId,
        state: companyInfo.cityId.stateId.stateId,
        city: companyInfo.cityId.cityId,
        companyNit: companyInfo.companyNit,
        email: companyInfo.companyEmail,
        phone: companyInfo.companyPhone,
        bussinessName: companyInfo.companyBussinesName,
        comertialName: companyInfo.companyComertialName,
        file: companyInfo.companyLogoPath,
        directRemitent: false,
        categoryCompany: { categoryCompanyId: companyInfo.categoryCompany.categoryCompanyId }
      })
    }
  }, [companyInfo])

  const handleSelectState = (option) => setCreateValues({ ...createValues, state: option })
  const handleSelectCity = (option) => setCreateValues({ ...createValues, city: option })

  const initialData = {
    comertialName: companyInfo.companyComertialName,
    bussinessName: companyInfo.companyBussinesName,
    companyNit: companyInfo.companyNit,
    email: companyInfo.companyEmail,
    phone: companyInfo.companyPhone,
    state: companyInfo.cityId.stateId.stateId,
    directRemitent: false,
    city: companyInfo.cityId.cityId,
    file: companyInfo.companyLogoPath,
    categoryCompany: companyInfo.categoryCompany
  }

  return (
    <section id='editCompany'>
      <img
        src={userCompanyImg}
        className='editCompanyImg'
        alt={scriptsSave && scriptsSave.editCompanyString ? scriptsSave.editCompanyString.altImageTxt1 : ''}
      />
      <form className='editCompanyForm' onSubmit={
        (e) => handleSubmit(
          e,
          idToken,
          createValues,
          dataContext,
          handleSaveModalShow,
          setisDisabled,
          initialData,
          setIsComertialName,
          setIncorrectInputFormat,
          incorrectInputFormat,
          getCompanyCredentialsExistsValidation,
          setIsBussinessName
        )}>
        <div className='editCompanyFormGrid'>
          <div className="editCompanyFormItem">
            <p className="editCompanyFormItemText">
              {scriptsSave && scriptsSave.editCompanyString ? scriptsSave.editCompanyString.comertialName : ''}
            </p>
            <input
              type="text"
              name='comertialName'
              value={createValues.comertialName || ''}
              onInput={(e) => maxSize(e, 'compName')}
              onChange={(e) => onFormInputChange(e, createValues, setCreateValues, validateCompanyName, incorrectInputFormat, setIncorrectInputFormat)}
              className="editCompanyFormItemInput"
              required />
            {isComertialName &&
              <span className={incorrectInputFormat.comertialNameFormat}>{incorrectInputFormat.comertialNameMessage}</span>
            }
          </div>
          <div className="editCompanyFormItem">
            <p className="editCompanyFormItemText">
              {scriptsSave && scriptsSave.editCompanyString ? scriptsSave.editCompanyString.bussinessName : ''}
            </p>
            <input
              type="text"
              name='bussinessName'
              value={createValues.bussinessName || ''}
              onInput={(e) => maxSize(e, 'compName')}
              onChange={(e) => onFormInputChange(
                e,
                createValues,
                setCreateValues,
                validateCompanyName,
                incorrectInputFormat,
                setIncorrectInputFormat
              )}
              className={`editCompanyFormItemInput ${validateValues.bussinessName ? 'inputIsWrong' : ''}`}
              required />
            {validateValues.bussinessName && <p className='labelIsWrong'>{scriptsSave && scriptsSave.editCompanyString ? scriptsSave.editCompanyString.nameAlreadyExists : ''}</p>}
            {isBussinessName &&
              <span className={incorrectInputFormat.bussinessNameFormat}>{incorrectInputFormat.bussinessNameMessage}</span>
            }
          </div>
          <div className="editCompanyFormItem">
            <p className="editCompanyFormItemText">
              {scriptsSave && scriptsSave.editCompanyString ? scriptsSave.editCompanyString.nit : ''}
            </p>
            <input
              type="text"
              name='companyNit'
              value={createValues.companyNit || ''}
              onInput={(e) => maxSize(e, 'nit')}
              onChange={(e) => onNitChange(e, createValues, setCreateValues)}
              onBlur={(e) => validateInputOnBlur(
                e,
                validateValues,
                setValidateValues,
                getCompanyCredentialsExistsValidation,
                { idToken, companyNit: createValues.companyNit, dataContext },
                initialData.companyNit,
                validateNitLength
              )}
              className={`editCompanyFormItemInput ${validateValues.companyNit ? 'inputIsWrong' : ''}`}
              required />
            {validateValues.companyNit && <p className='labelIsWrong'>{scriptsSave && scriptsSave.editCompanyString ? scriptsSave.editCompanyString.nitAlreadyExists : ''}</p>}
          </div>
          <div className="editCompanyFormItem">
            <p className="editCompanyFormItemText">
              {scriptsSave && scriptsSave.editCompanyString ? scriptsSave.editCompanyString.email : ''}
            </p>
            <input
              type="text"
              name='email'
              value={createValues.email || ''}
              onInput={(e) => maxSize(e, 'email')}
              onChange={(e) => onEditEmailChange(e, createValues, setCreateValues)}
              onBlur={(e) => onEditEmailBlur(
                e,
                validateValues,
                setValidateValues,
                getCompanyCredentialsExistsValidation,
                { idToken, companyEmail: createValues.email, dataContext },
                initialData.email
              )}
              className={`editCompanyFormItemInput ${validateValues.email ? 'inputIsWrong' : ''}`}
              required />
            {validateValues.email && <p className='labelIsWrong'>{scriptsSave && scriptsSave.editCompanyString ? scriptsSave.editCompanyString.emailAlreadyExists : ''}</p>}
          </div>
          <div className="editCompanyFormItem">
            <p className="editCompanyFormItemText">
              {scriptsSave && scriptsSave.editCompanyString ? scriptsSave.editCompanyString.phone : ''}
            </p>
            <input
              type="text"
              name='phone'
              value={createValues.phone || ''}
              onInput={(e) => maxSize(e, 'phone')}
              onChange={(e) => onPhoneChange(e, createValues, setCreateValues)}
              onBlur={(e) => validateInputOnBlur(
                e,
                validateValues,
                setValidateValues,
                getCompanyPhoneExistsValidation,
                { idToken, createValues, dataContext },
                initialData.phone,
                validateCellphoneLength
              )}
              className={`editCompanyFormItemInput ${validateValues.phone ? 'inputIsWrong' : ''}`}
              required />
            {(validateValues.phone && createValues.companyNit)
              ? <p className='labelIsWrong'>{scriptsSave && scriptsSave.editCompanyString ? scriptsSave.editCompanyString.phoneAlreadyExists : ''}</p>
              : validateValues.phone && <p className='labelIsWrong'>{scriptsSave && scriptsSave.editCompanyString ? scriptsSave.editCompanyString.nitMsg1 : ''}</p>

            }
          </div>
          <div className="editCompanyFormItem">
            <p className="editCompanyFormItemText">
              {scriptsSave && scriptsSave.editCompanyString ? scriptsSave.editCompanyString.state : ''}
            </p>
            <CustomSelect selector={handleSelectState} defaultValue={createValues.state}>
              {statesFetched?.map((state) => (
                <option
                  key={state.stateId}
                  className='CustomSelectOptionsItem'
                  value={state.stateId}
                >
                  {state.stateName}
                </option>
              ))}
            </CustomSelect>
          </div>
          <div className="editCompanyFormItem">
            <p className="editCompanyFormItemText">
              {scriptsSave && scriptsSave.editCompanyString ? scriptsSave.editCompanyString.selectOptions : ''}
            </p>
            <p className='editCompanyFormSubitemText'>
              {scriptsSave && scriptsSave.editCompanyString ? scriptsSave.editCompanyString.optional : ''}
            </p>
            <div className='editCompanyChkBoxMessageContainer'>
              <input
                className='editCompanyChkBox'
                type='checkbox'
                onChange={(e) => onFirstCheckboxChange(e, createValues, setCreateValues)}
                checked={createValues.categoryCompany.categoryCompanyId === 1 && true}
                name='bankEntity'
              />
              <p className="editCompanyFormItemText2">
                {/* {scriptsSave && scriptsSave.editCompanyString ? parse(scriptsSave.editCompanyString.chkBoxMessage) : ''} */}
                {scriptsSave && scriptsSave.editCompanyString ? scriptsSave.editCompanyString.option1 : ''}
              </p>
            </div>
            <div className='editCompanyChkBoxMessageContainer'>
              <input
                className='editCompanyChkBox'
                type='checkbox'
                onChange={(e) => onSecondCheckboxChange(e, createValues, setCreateValues)}
                checked={createValues.categoryCompany.categoryCompanyId === 2 && true}
                name='bankEntity'
              />
              <p className="editCompanyFormItemText2">
                {scriptsSave && scriptsSave.editCompanyString ? scriptsSave.editCompanyString.option2 : ''}
              </p>
            </div>
            <div className='editCompanyChkBoxMessageContainer'>
              <input
                className='editCompanyChkBox'
                type='checkbox'
                onChange={(e) => onThirdCheckboxChange(e, createValues, setCreateValues)}
                checked={createValues.categoryCompany.categoryCompanyId === 4 && true}
                name='bankEntity'
              />
              <p className="editCompanyFormItemText2">
                {scriptsSave && scriptsSave.editCompanyString ? scriptsSave.editCompanyString.option3 : ''}
              </p>
            </div>
            {/* <div className='editCompanyChkBoxMessageContainer'>
              <input
                className='editCompanyChkBox'
                type='checkbox'
                checked={false}
                name='bankEntity'
                />
              <p className="editCompanyFormItemText2">
                {scriptsSave && scriptsSave.editCompanyString ? scriptsSave.editCompanyString.option4 : ''}
              </p>
            </div> */}
            <p>{ }</p>
          </div>
          <div className="editCompanyFormItem">
            <p className="editCompanyFormItemText">
              {scriptsSave && scriptsSave.editCompanyString ? scriptsSave.editCompanyString.city : ''}
            </p>
            <CustomSelect selector={handleSelectCity} defaultValue={createValues.city}>
              {citiesFiltered?.map((city) => (
                <option
                  key={city.cityId}
                  value={city.cityId}
                  className='CustomSelectOptionsItem'
                  required
                >
                  {city.cityName}
                </option>
              ))}
            </CustomSelect>
            <div className='editCompanyUploadLogoContainer'>
              {
                createValues?.file?.name
                  ? (
                    <div className='editCompanyLoadedImageContainer'>
                      <img
                        className='editCompanyLoadedImage'
                        src={habilitarIcon}
                        alt={scriptsSave && scriptsSave.editCompanyString ? scriptsSave.editCompanyString.altImageTxt2 : ''}
                      />
                      <p className='editCompanyLoadedImageText'>{createValues?.file?.name}</p>
                    </div>
                    )
                  : (
                    <></>
                    )
              }
              <input
                style={{ display: 'none' }}
                type="file"
                accept='.jpg, .jpeg, .png'
                onChange={(e) => onUploadLogoClick(
                  e,
                  setCreateValues,
                  createValues,
                  scriptsSave && scriptsSave.editCompanyString ? scriptsSave.editCompanyString.validateExtension : '',
                  setModalMessage,
                  handleShow)}
                id='uploadLogo'
              />
              <label htmlFor="uploadLogo" className='UploadLogoButton'>
                {scriptsSave && scriptsSave.editCompanyString ? scriptsSave.editCompanyString.uploadLogo : ''}
              </label>
            </div>
          </div>
          <div></div>
        </div>
        <div className="navFooter">
          <button
            className="navFooterBackButton"
            onClick={(e) =>
              cancelEdit(e, isDisabled, handleCancelModalShow, navigate)
            }
          >
            <img
              className="navFooterBackLinkImage"
              src={backIcon}
              alt={scriptsSave && scriptsSave.editCompanyString ? scriptsSave.editCompanyString.altImageTxt3 : ''}
            />
            <span className="navFooterBackLinkText">
              {scriptsSave && scriptsSave.navFooterStrings ? scriptsSave.navFooterStrings.goBack : ''}
            </span>
          </button>
          <div className="navFooterButtonsContainer">
            <button
              className="EditPQRSUserBtn"
              name='cancel'
              onClick={(e) =>
                cancelEdit(e, isDisabled, handleCancelModalShow, navigate)
              }
            >
              {scriptsSave && scriptsSave.userPQRSEditString ? scriptsSave.userPQRSEditString.cancel : ''}
            </button>
            <button
              className="EditPQRSUserBtn"
              type='submit'
              name='submit'
              disabled={isDisabled || !wasDataUpdated(initialData, createValues, setIsComertialName, setIncorrectInputFormat, incorrectInputFormat)}
            >
              {scriptsSave && scriptsSave.userPQRSEditString ? scriptsSave.userPQRSEditString.save : ''}
            </button>
          </div>
        </div>
      </form>
      <Modal isOpen={showSaveModal}>
        <Modal.Content>
          <p>{scriptsSave && scriptsSave.editCompanyString ? scriptsSave.editCompanyString.saveModalMessage : ''}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button
            className="Modal-orangeBtn"
            onClick={() => navigate('..')}
          >
            {scriptsSave && scriptsSave.userPQRSEditString ? scriptsSave.userPQRSEditString.continue : ''}
          </button>
        </Modal.Buttons>
      </Modal>
      <Modal isOpen={showCancelModal}>
        <Modal.Content>
          <p>{scriptsSave && scriptsSave.editCompanyString ? scriptsSave.editCompanyString.cancelModalMessage : ''}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button
            className="Modal-normalBtn"
            onClick={handleCancelModalClose}
          >
            {scriptsSave && scriptsSave.userPQRSEditString ? scriptsSave.userPQRSEditString.cancel : ''}
          </button>
          <button
            className="Modal-orangeBtn"
            onClick={() => navigate('..')}
          >
            {scriptsSave && scriptsSave.userPQRSEditString ? scriptsSave.userPQRSEditString.continue : ''}
          </button>
        </Modal.Buttons>
      </Modal>
      <Modal isOpen={showModal}>
        <Modal.Content>
          <p>{modalMessage}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button className="Modal-orangeBtn" onClick={handleClose}>
            {scriptsSave && scriptsSave.editCompanyString ? scriptsSave.editCompanyString.ok : ''}
          </button>
        </Modal.Buttons>
      </Modal>
    </section>
  )
}
