import React from 'react'
import './EmptyData.css'

export const EmptyData = () => {
  return (
    <div className='NoDataInfo'>
        <h2 className='NoDataInfoTitle'>{'Datos no encontrados'}</h2>
        <p className='NoDataInfoSubtitle'>{'No hay registros que coincidan con esta búsqueda en '}<span className='orangeText'>{'tudata'}</span>
        </p>
    </div>
  )
}
