import { autorizeOrRejectPurchase } from '../../../../utils/userQueries'
import parse from 'html-react-parser'

export const onAcceptClick = (setModalMessage, handleShow, entityMovementsString) => {
  setModalMessage(parse(entityMovementsString.acceptModalMsg))
  handleShow()
}

export const onRejectClick = (setModalMessage, handleShow, entityMovementsString) => {
  setModalMessage(parse(entityMovementsString.rejectModalMsg))
  handleShow()
}

export const onContinueModalBtnClick = async (autorize, shoppingCode, dataContext) => {
  const { navigate, dataState } = dataContext
  const { idToken } = dataState.operative
  await autorizeOrRejectPurchase(autorize, shoppingCode, dataContext, idToken)
  navigate('../PagoEntidades')
}
