import React from 'react'
import { useLocation } from 'react-router-dom'
import { EditPQRSUser } from './EditPQRSUser/EditPQRSUser'
import { EditPQRSBuyer } from './EditPQRSBuyer/EditPQRSBuyer'
import './EditPQRS.css'
import { CreateNewCompany } from './CreateNewCompany/CreateNewCompany'

export const EditPQRS = () => {
  const { state } = useLocation()
  const { isUser, pqrsId } = state
  return (
    <section id='EditPQRS'>
        {isUser
          ? pqrsId === 6 ? <CreateNewCompany state={state}/> : <EditPQRSUser state={state}/>
          : <EditPQRSBuyer state={state}/>
        }
    </section>
  )
}
