import JSZip from 'jszip'
import { sendProceedContactNotAllowedResponse, uploadDocumentPath } from '../../../../utils/userQueries'
import { textWithoutEspecials } from '../../../../utils/functions'

export const downloadFiles = async (urls) => {
  const zip = new JSZip()

  await Promise.all(
    urls.map(async (url) => {
      const response = await fetch(url)
      const fileData = await response.blob()
      const fileName = url
        .substring(url.lastIndexOf('/') + 1)
        .split('?X-Amz')[0]
      zip.file(fileName, fileData)
    })
  )

  await zip.generateAsync({ type: 'blob' }).then((blob) => {
    const zipFileName = 'archivos.zip'
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = zipFileName
    link.click()
  })
}

export const handleSubmitProceedRequest = async (
  idToken,
  documentReqBody,
  sendResponseBody,
  dataContext,
  handleShow,
  setRadicateNum
) => {
  const responseSendProceedRequest =
    await sendProceedContactNotAllowedResponse(
      idToken,
      sendResponseBody,
      dataContext
    )
  if (responseSendProceedRequest.response) {
    setRadicateNum(responseSendProceedRequest.body.radicatedNum)
    const { file, ...restDocumentReqBody } = documentReqBody
    const responseDocumentPath = await uploadDocumentPath(
      idToken,
      restDocumentReqBody,
      dataContext
    )
    if (responseDocumentPath.response) {
      const keyName = Object.keys(responseDocumentPath.body.doc)[0]
      const urlToSendFile = responseDocumentPath.body.doc[textWithoutEspecials(keyName)].url
      await fetch(urlToSendFile, {
        method: 'PUT',
        body: file
      })
      handleShow()
    }
  }
}

export const onUploadFileChange = (e, setFile, setUploadedFile) => {
  const file = e.target.files[0]
  const formattedName = file.name.replace(/\s/g, '_')

  setFile(file)
  setUploadedFile(formattedName)
}
