import React, { useContext } from 'react'
import { NavLink, Outlet, useLocation } from 'react-router-dom'
import { Aside } from '../../../components/Aside/Aside'
import { useModal } from '../../../hooks/useModal'
import rejectIcon from '../../../assets/icons/icon_rechazada.svg'
import pendingIcon from '../../../assets/icons/icon_enproceso.svg'
import successIcon from '../../../assets/icons/icon_bien.svg'
import './UsersLayout.css'
import { ScriptContext } from '../../../context/ScriptsContext'

export const UsersLayout = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const { pathname } = useLocation()
  const [showModal, , handleClose] = useModal()

  const usersViewName = pathname.endsWith('EditarUsuario')
    ? (scriptsSave && scriptsSave.adminString ? scriptsSave.adminString.editUser : '')
    : (scriptsSave && scriptsSave.adminString ? scriptsSave.adminString.userPanel : '')
  const isInUserDetail = pathname.includes('DetalleUsuario')

  return (
    <section id='UsersLayout'>
      <div className="UsersLayoutHeader">
        <div>
          <NavLink className={({ isActive }) => isActive || isInUserDetail ? 'activeHeader' : 'usersPanelHeaderContainer'} to='' end>
              <p className={'usersPanelParagraph'}>
                {usersViewName}
              </p>
          </NavLink>
          <NavLink className={({ isActive }) => isActive ? 'activeHeader' : 'usersPanelHeaderContainer'} to='SolicitudesEliminacion'>
              <p className={'usersPanelParagraph'}>
                {scriptsSave && scriptsSave.usersLayoutString ? scriptsSave.usersLayoutString.deleteRequests : ''}
              </p>
          </NavLink>
          <NavLink className={({ isActive }) => isActive ? 'activeHeader' : 'usersPanelHeaderContainer'} to='AdmCambioPlan'>
              <p className={'usersPanelParagraph'}>
                {'Adm. Cambio de plan'}
              </p>
          </NavLink>
        </div>
      </div>
      <hr className="hrUsersPanel" />
      <Aside isOpen={showModal}>
        <Aside.Content>
          <h2>{scriptsSave && scriptsSave.usersLayoutString ? scriptsSave.usersLayoutString.indicators : ''}</h2>
          <p>{scriptsSave && scriptsSave.usersLayoutString ? scriptsSave.usersLayoutString.generalStates : ''} <br /><br />{scriptsSave && scriptsSave.usersLayoutString ? scriptsSave.usersLayoutString.thisAre : ''}</p>
          <div>
            <span>{scriptsSave && scriptsSave.usersLayoutString ? scriptsSave.usersLayoutString.rejected : ''}</span>
            <img
              src={rejectIcon}
              alt={scriptsSave && scriptsSave.usersLayoutString ? scriptsSave.usersLayoutString.altImageTxt1 : ''}
            />
            <span>{scriptsSave && scriptsSave.usersLayoutString ? scriptsSave.usersLayoutString.pending : ''}</span>
            <img
              src={pendingIcon}
              alt={scriptsSave && scriptsSave.usersLayoutString ? scriptsSave.usersLayoutString.altImageTxt2 : ''}
            />
            <span>{scriptsSave && scriptsSave.usersLayoutString ? scriptsSave.usersLayoutString.ended : ''}</span>
            <img
              src={successIcon}
              alt={scriptsSave && scriptsSave.usersLayoutString ? scriptsSave.usersLayoutString.altImageTxt3 : ''}
            />
          </div>
        </Aside.Content>
        <Aside.Buttons>
          <button onClick={handleClose} className='Modal-greenBtn'>
            {scriptsSave && scriptsSave.compString ? scriptsSave.compString.ok : ''}
          </button>
        </Aside.Buttons>
      </Aside>
      <Outlet/>
    </section>
  )
}
