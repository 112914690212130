import React, { useLayoutEffect, useRef, useState, useEffect } from 'react'

import {
  onNextBtnClick,
  onPageClick,
  onPrevBtnClick
} from './PaginationFunctions'

import './Pagination.css'

export const Pagination = ({
  totalPages,
  paginationValues,
  setPaginationValues,
  currentPage,
  scrollMeasurement,
  isNextActive,
  setIsNextActive,
  isPrevActive,
  setIsPrevActive
}) => {
  const pageNumbers = []
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i)
  }
  const [activePage, setActivePage] = useState(currentPage)
  const paginationPagesRef = useRef(null)
  const paginationItemRef = useRef(null)

  useLayoutEffect(() => {
    if (paginationPagesRef.current) {
      const childNodesLenght = paginationPagesRef.current.childNodes.length
      const gridTemplateColumns = `repeat(${childNodesLenght}, 2vw)`
      paginationPagesRef.current.style.gridTemplateColumns =
        gridTemplateColumns
      paginationPagesRef.current.scrollLeft = scrollMeasurement
    }
  }, [pageNumbers])

  useEffect(() => {
    setActivePage(currentPage)
  }, [currentPage])

  return totalPages > 0
    ? (
      <div className="paginationContainer">
        <div
          className={isPrevActive ? 'paginationPrevBtn' : 'paginationActivePrevBtn'}
          onClick={() =>
            onPrevBtnClick(
              activePage,
              setActivePage,
              paginationValues,
              setPaginationValues,
              paginationPagesRef,
              paginationItemRef,
              setIsPrevActive
            )
          }
        ></div>
        <div className="paginationPages" ref={paginationPagesRef}>
          {
            pageNumbers.map((number) => (
              <div
                ref={activePage === number ? paginationItemRef : null}
                id={number}
                className={`paginationItem ${activePage === number ? 'paginationItemActive' : ''
                  }`}
                key={number}
                onClick={() =>
                  onPageClick(
                    setActivePage,
                    paginationValues,
                    setPaginationValues,
                    number
                  )
                }
              ></div>
            ))
          }
        </div>
        <div
          className={isNextActive ? 'paginationNextBtn' : 'paginationActiveNextBtn'}
          onClick={() =>
            onNextBtnClick(
              activePage,
              setActivePage,
              paginationValues,
              setPaginationValues,
              totalPages,
              paginationPagesRef,
              paginationItemRef,
              setIsNextActive
            )
          }
        ></div>
      </div>
      )
    : (
      <div></div>
      )
}
