import { textWithoutEspecials } from '../../../../utils/functions'
import { uploadDocumentUpdated } from '../../../../utils/userQueries'

export const handleUploadFile = (e, handleShowInvalidFileModal, setFile) => {
  const imageFormat = e.target.files[0].type.split('/')[1]
  const typesAccepted = ['jpg', 'jpeg', 'png', 'pdf']

  const isValidFormat = typesAccepted.includes(imageFormat)
  if (!isValidFormat) {
    handleShowInvalidFileModal()
    setFile(null)
  } else setFile(e.target.files[0])
  e.target.value = ''
}

export const handleSubmit = async (idToken, dataContext, documentType, file, showModal, setDocumentType, setFile) => {
  const requestBody = {
    typeDoc: documentType,
    namefiles: textWithoutEspecials(file.name),
    contentType: file.type
  }
  const responseUploadDoc = await uploadDocumentUpdated(idToken, requestBody, dataContext)
  if (responseUploadDoc.response) {
    const urlToSendFile = responseUploadDoc.body.key
    await fetch(urlToSendFile, {
      method: 'PUT',
      body: file
    })
    showModal()
    setDocumentType(null)
    setFile(null)
  }
}
