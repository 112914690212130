import React, { useContext } from 'react'
import pendingIcon from '../../assets/icons/icon_enproceso.svg'
import successIcon from '../../assets/icons/icon_bien.svg'

import './LawyersDetailedDescription.css'
import { dashedFormatDateYYYYMMDD } from '../../utils/functions'
import { ScriptContext } from '../../context/ScriptsContext'

export const LawyersDetailedDescription = ({ children, description, entity }) => {
  const { scriptsSave } = useContext(ScriptContext)
  return (
    <>
      <section className="lawyersDetailedDescription">
        <div className="lawyersDetailedDescriptionSubcontainer">
          <article className="lawyersDetailedDescriptionDescriptionInfoContainer">
            <h2 className='lawyersDetailedDescriptionDescriptionMainTitle'>
              {scriptsSave && scriptsSave.lawyersDetailedDescriptionString ? scriptsSave.lawyersDetailedDescriptionString.description : ''}
            </h2>
            <div>
              <h2 className="lawyersDetailedDescriptionDescriptionTitle">
                {scriptsSave && scriptsSave.lawyersDetailedDescriptionString ? scriptsSave.lawyersDetailedDescriptionString.user : ''}
              </h2>
              <p className="lawyersDetailedDescriptionDescriptionValue">{description.name}</p>
            </div>
            <div>
              <h2 className="lawyersDetailedDescriptionDescriptionTitle">
                {scriptsSave && scriptsSave.lawyersDetailedDescriptionString ? scriptsSave.lawyersDetailedDescriptionString.plan : ''}
              </h2>
              <p className="lawyersDetailedDescriptionDescriptionLightValue">
                {description?.userId?.planId.planName === 'Plus'
                  ? 'Más'
                  : description?.userId?.planId.planName
                }
              </p>
            </div>
            <div>
              <h2 className="lawyersDetailedDescriptionDescriptionTitle">
                {scriptsSave && scriptsSave.lawyersDetailedDescriptionString ? scriptsSave.lawyersDetailedDescriptionString.date : ''}
              </h2>
              <p className="lawyersDetailedDescriptionDescriptionLightValue">{dashedFormatDateYYYYMMDD(description?.content?.fecha)}</p>
            </div>
            <div>
              <h2 className="lawyersDetailedDescriptionDescriptionTitle">
                {scriptsSave && scriptsSave.lawyersDetailedDescriptionString ? scriptsSave.lawyersDetailedDescriptionString.time : ''}
              </h2>
              <p className="lawyersDetailedDescriptionDescriptionLightValue">{`${description?.content?.hora}:00`}</p>
            </div>
            <div>
              <h2 className="lawyersDetailedDescriptionDescriptionTitle">
                {scriptsSave && scriptsSave.lawyersDetailedDescriptionString ? scriptsSave.lawyersDetailedDescriptionString.contact : ''}
              </h2>
              <p className="lawyersDetailedDescriptionDescriptionLightValue">{description?.content?.tipodecomunicación}</p>
            </div>
            <p className="lawyersDetailedDescriptionDescriptionText">
              {description?.content?.descripción}
            </p>
          </article>
          <div className='lawyersDetailedDescriptionState'>
            {scriptsSave && scriptsSave.lawyersDetailedDescriptionString ? scriptsSave.lawyersDetailedDescriptionString.state : ''} {description?.statusId?.statusName}
            {description?.statusId?.statusName === `${scriptsSave && scriptsSave.LawyersDetailedDescription ? scriptsSave.LawyersDetailedDescription.statusTxt1 : ''}`
              ? <img
                  src={pendingIcon}
                  alt={scriptsSave && scriptsSave.lawyersDetailedDescriptionString ? scriptsSave.lawyersDetailedDescriptionString.altImageTxt1 : ''}
                />
              : <img
                  src={successIcon}
                  alt={scriptsSave && scriptsSave.lawyersDetailedDescriptionString ? scriptsSave.lawyersDetailedDescriptionString.altImageTxt2 : ''}
                />
            }
          </div>
        </div>

        <div className="lawyersDetailedDescriptionDetailsContainer">
          <div className="lawyersDetailedDescriptionDetailsHeader">
            <h2 className="lawyersDetailedDescriptionDetailsTitle">
              {scriptsSave && scriptsSave.lawyersDetailedDescriptionString ? scriptsSave.lawyersDetailedDescriptionString.forbiddenContact : ''}
            </h2>
            <span className='entityName'>{entity}</span>
          </div>
          <div className="lawyersDetailedDescriptionDetailsAditionalInfo">
            {children}
          </div>
        </div>
      </section>
    </>
  )
}
