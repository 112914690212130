import React from 'react'

export const indexString = {
  administrative: 'Administrativo',
  operative: 'Operativo',
  lawyer: 'Abogado',
  entries: 'Entradas',
  requests: 'Solicitudes',
  userManagement: (<>Gestión <br /> a usuarios</>),
  infoUpdate: 'Actualización de información',
  newUsersEntries: 'Nuevas entradas de usuarios',
  userValidation: 'Validación usuario',
  newEntitiesEntries: 'Nuevas entradas de entidades',
  entityValidation: 'Validación entidad ',
  unregisteredUsers: 'Solicitudes de usuarios no registrados',
  unregisteredUsers2: 'Usuarios no registrados',
  entities: 'Entidades',
  users: 'Usuarios',
  pqrs: 'PQRS',
  movements: 'Movimientos',
  companies: 'Empresas',
  dataBases: (
    <span>
      Base
      <br /> de datos
    </span>
  ),
  contact: 'Contacto no permitido'
}
export const userString = {
  yourData: 'Tus datos',
  blockedCompanies: 'Empresas bloqueadas',
  blockActive: 'Bloqueo activo',
  blockProcess: 'Bloqueo en proceso',
  blockNotSettled: 'Bloqueo en proceso no radicado',
  ourName: 'TuData',
  quit: 'Cerrar sesión',
  quitAsk: '¿Desea cerrar sesión?',
  gender: 'Género',
  age: 'Edad',
  years: 'años',
  phone: 'Teléfono',
  mobile: 'Celular',
  mail: 'Correo',
  activeQueries: 'Solicitudes activas',
  birthdate: 'Fecha de nacimiento',
  add: 'Agregar otra',
  userName: 'Nombre',
  id: 'Identificación',
  idType: 'Tipo de identificación',
  data: 'Datos',
  deleteUser: 'Eliminar usuario',
  save: 'Guardar',
  confirmUser: 'Confirmar usuario',
  seeId: 'Ver ID',
  edit: 'Editar',
  userConfirmation:
    'El nuevo usuario se ha confirmado correctamente. Se le notificará al usuario en su correo electrónico.',
  close: 'Cerrar',
  continue: 'Continuar'
}

export const userRequestsString = {
  button1Text1: 'Editar',
  button1Text2: 'Crear',
  button2Text1: 'Ver adjuntos',
  textareaLabel: 'Respuesta',
  radioInput1Text: 'Aceptar',
  radioInput2Text: 'Rechazar',
  modalContent: 'Se ha notificado al usuario por correo',
  modalButton1Text1: 'Continuar'
}

export const unregisteredUsersString = {
  inputRadio1Text: 'Aceptar',
  inputRadio2Text: 'Rechazar',
  modalBtn1Text1: 'Continuar',
  modalTextContent1: 'Se ha notificado al usuario por correo'
}

export const entityValidationString = {
  modaltext1: 'Has eliminado a la entidad ',
  modaltext2: ' ¿Deseas continuar?',
  modaltext3: 'Has validado a la entidad ',
  modalButton1Text1: 'Cancelar',
  modalButton2Text1: 'Continuar'
}

export const compString = {
  infoZero:
    'Recuerda que para bloquear tus datos de una entidad debes seguir los siguientes pasos:',
  infoOne:
    '1. Busca en nuestra base de datos a la entidad con su nombre comercial o su número de registro comercial (NIT).',
  infoTwo:
    '2. Dale clic para acceder a las opciones de la entidad y selecciona la opción de "bloquear".',
  infoThree:
    '3. Sigue las instrucciones para llenar el formulario y radicar la solicitud en el sistema. Recuerda que este proceso se demora 15 días hábiles desde el momento de la radicación de los papeles.',
  notFound: '¿No encuentras la entidad que estas buscando?',
  queries: 'Solicitudes',
  blockers: 'Bloqueos',
  askBlock: 'Bloquear',
  nit: 'NIT',
  name: 'Nombre de la entidad',
  status: 'Estado',
  process: 'Proceso',
  search: 'Buscar entidad o NIT',
  searchQuery: 'Buscar solicitud',
  changePassword: 'Cambio de contraseña',
  termsAndPolicies: 'Condiciones y políticas',
  tryAnotherValidationMethod: 'Probar otro método',
  deleteAccount: 'Eliminar cuenta',
  deleteEntity: 'Eliminar entidad',
  deleteUser: '¿Deseas eliminar a este usuario?',
  reasons: 'Describe las razones porque se va a eliminar a ',
  next: ', a continuación.',
  description: 'Descripción:',
  directMessage: 'Este mensaje llegará directamente al correo del solicitante.',
  save: 'Guardar',
  clean: 'Limpiar',
  send: 'Enviar',
  accept: 'Aceptar',
  continue: 'Continuar',
  ok: 'OK',
  sendResponse: 'Enviar respuesta',
  return: 'Volver',
  subject: 'Asunto',
  answer: 'Respuesta',
  answerNote: '*Escribe la respuesta, le llegará al solicitante por correo.',
  returnAnswer: 'Responder',
  petitionRequestComplaint: 'Petición, Queja, Reclamo o Sugerencia.',
  entityComertialName: 'Nombre comercial',
  entityBussinesName: 'Nombre empresarial',
  contact: 'Contacto',
  mail: 'Correo',
  phone: 'Teléfono',
  NIT: 'NIT',
  legalRepresentation: 'Representante legal',
  see: 'Ver',
  rutConsultation: 'Consultar RUT',
  userCharge: 'Carga de usuarios',
  entityCharge: 'Carga de entidades',
  fileCharging: 'Cargando el archivo...',
  fileCharged: 'Archivo cargado',
  percentage: '%',
  viewAttachments: 'Ver adjuntos',
  uploadXlsxFile: 'Subir archivo .csv',
  chargingUsers: 'Carga de usuarios',
  chargingEntities: 'Carga de entidades',
  fileUploadSuccessful: 'El archivo a cargado correctamente.',
  loremIpsum:
    'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eaque ealpa error eum consequuntur? Et sequi architecto asperiores. Necessitatibus aperiam quis laboriosam. Earum debitis obcaecati delectus incidunt tempore in quod similique et? Adipisci blanditiis iusto voluptatem et incidunt corrupti exercitationem maxime ab amet quasi. Consequuntur dicta voluptatibus pariatur ea ipsum consectetur aliquam quasi impedit eos, modi fugiat eum?',
  wantToFinish: 'Se ha generado una respuesta a este proceso.',
  cancel: 'Cancelar',
  finish: 'Finalizar'
}

export const adminString = {
  createEntities: 'Crear empresa',
  validatedCompanies: 'Lista de entidades',
  userPanel: 'Adm. a usuarios',
  userPanelAdministrative: 'Panel de usuarios',
  editUser: 'Editar Usuario',
  searchCompany: 'Buscar entidad o empresa',
  dataBases: 'Seguimiento Bases de Datos',
  dataBaseDetails: 'Detalles Base de Datos',
  newUser: 'Nuevo usuario',
  newLawyerUser: 'Nuevo usuario Abogado',
  details: 'Detalles',
  userValidation: 'Validación usuario',
  unregisteredUsers: 'Usuarios no registrados',
  userRequest: 'Solicitud del usuario',
  entityValidation: 'Validación entidad',
  bulkUploads: 'Cargas masivas'
}

export const newUserString = {
  goBack: 'Volver',
  chooseUserType: 'Escoge el tipo de usuario que vas a crear.',
  info1: 'Escoge el tipo de usuario.',
  info2: 'Elige el rol que vas a crear.'
}

export const requestsString = {
  headerParagraph1: 'Bandeja de entrada',
  headerParagraph2: 'Seguimiento de casos',
  headerParagraph3: 'Entidades sin canal de contacto',
  see: 'Ver',
  addSignature: 'Agregar firma',
  send: 'Enviar',
  cancel: 'Cancelar',
  accept: 'Aceptar',
  applicantSignature: 'Firma solicitante',
  lawyerSignature: 'Firma abogado',
  lawyerPqrs: 'PQRS Abogados',
  complain1: 'Acabas de enviar "Queja ante SIC" a la entidad',
  complain2: 'con el radicado no.',
  continue: 'Continuar'
}

export const newRequestsDetailString = {
  blockEntity: 'Bloqueo a la entidad '
}

export const rejectedRequestsDetailString = {
  requestTitle: 'Realizar Queja ante la SIC',
  blockEntity: 'Bloqueo a la entidad '
}

export const userRequestLawyersString = {
  data: 'Datos',
  complain: 'Queja',
  reply: 'Responder',
  name: 'Nombre',
  identification: 'Identificación',
  cel: 'Celular',
  identificationType: 'Tipo de identificación',
  email: 'Correo'
}

export const userAttachmentsString = {
  data: 'Datos',
  name: 'Nombre',
  identification: 'Identificación',
  cel: 'Celular',
  identificationType: 'Tipo de identificación',
  email: 'Correo',
  changes: 'Adjuntos de cambios',
  document: 'Documento',
  weight: 'Peso',
  date: 'Fecha',
  see: 'Ver',
  back: 'Volver',
  modalButton1Text: 'Cerrar'
}

export const userEditString = {
  name: 'Nombre',
  email: 'Correo',
  identification: 'Identificación',
  cel: 'Celular',
  back: 'Volver',
  cancel: 'Cancelar',
  save: 'Guardar',
  changesDone: 'Los cambios se han realizado correctamente',
  continue: 'Continuar'
}

export const userPQRSEditString = {
  name: 'Nombre',
  lastname: 'Apellido',
  typeId: {
    title: 'Tipo ID',
    CC: 'CC',
    CE: 'CE',
    PG: 'PB',
    TI: 'TI'
  },
  gender: {
    title: 'Género',
    male: 'Masculino',
    female: 'Femenino',
    other: 'Otro'
  },
  expeditionPlace: 'Lugar de expedición',
  idNumber: 'Identificación',
  phone: 'Teléfono',
  birthdate: 'Fecha de nacimiento',
  email: 'Correo',
  state: 'Departamento',
  city: 'Ciudad',
  cancel: 'Cancelar',
  save: 'Guardar',
  saveModalMessage: 'Los cambios se han guardado correctamente',
  continue: 'Continuar',
  cancelModalMessage:
    '¿Estás seguro de salir de la edición de usuarios, sin guardar los cambios?',
  saveChanges: 'Guardar cambios'
}

export const buyerPQRSEditString = {
  legalRepName: 'Nombre representante legal',
  repTypeId: 'Tipo ID',
  repId: 'ID de representante',
  expCity: 'Ciudad de expedición',
  contactName: 'Nombre persona de contacto',
  address: 'Dirección',
  email: 'Correo',
  phone: 'Teléfono',
  cancel: 'Cancelar',
  save: 'Guardar'
}

export const createNewCompanyString = {
  comertialName: 'Nombre comercial',
  bussinessName: 'Nombre empresarial',
  nit: 'NIT',
  email: 'Correo',
  phone: 'Teléfono',
  state: 'Departamento',
  logo: 'Logo',
  uploadLogo: 'Subir logo',
  city: 'Ciudad',
  cancel: 'Cancelar',
  save: 'Guardar',
  cancelModalMessage:
    '¿Estás seguro de salir de la edición de usuarios?',
  saveModalMessage: 'La empresa se ha creado correctamente',
  validateExtension: 'La extensión del archivo debe ser alguna de las siguientes: ',
  ok: 'OK',
  selectOptions: 'Selecciona una de estas opciones',
  optional: '*Opcional',
  option1: 'Entidad bancaria',
  option2: 'Servicios públicos',
  option3: 'Sin canal de contacto',
  option4: 'Acepta notificaciones de TuData'
}

export const editCompanyString = {
  comertialName: 'Nombre comercial',
  bussinessName: 'Nombre empresarial',
  nit: 'NIT',
  email: 'Correo',
  phone: 'Teléfono',
  state: 'Departamento',
  logo: 'Logo',
  uploadLogo: 'Subir logo',
  city: 'Ciudad',
  cancel: 'Cancelar',
  save: 'Guardar',
  chkBoxMessage: (
    <span>
      Aceptar que <span style={{ color: '#ff5d00' }}>TuData</span> te comunique<br /> cualquier actualización
    </span>
  ),
  cancelModalMessage:
    '¿Estás seguro de salir de la edición de usuarios?',
  saveModalMessage: 'La empresa se ha editado correctamente',
  nameAlreadyExists: '*Este nombre ya existe',
  nitAlreadyExists: '*Este NIT ya existe',
  emailAlreadyExists: '*Este correo ya tiene una compañía asociada o su formato no es el de un correo válido',
  phoneAlreadyExists: '*Este teléfono ya tiene una compañía asociada',
  nitMsg1: '*Ingrese un Nit en primer lugar',
  validateExtension: 'La extensión del archivo debe ser alguna de las siguientes: ',
  ok: 'OK',
  selectOptions: 'Selecciona una de estas opciones',
  optional: '*Opcional',
  option1: 'Entidad bancaria',
  option2: 'Servicios públicos',
  option3: 'Sin canal de contacto',
  option4: 'Acepta notificaciones de TuData'
}

export const usersPanelAdministrativeString = {
  newUser: 'Nuevo usuario',
  column1: 'ID',
  column2: 'Nombre',
  column3: 'No. Identificación',
  column4: 'Tipo de usuario',
  column5: 'Correo',
  column6: '',
  altImg1: 'icon Person Plus',
  employeeCredentialFailed: 'GET /employeeCredential failed'
}

export const pqrsString = {
  thead1: 'ID',
  thead2: 'Nombre',
  thead3: 'PQRS',
  thead4: 'Título',
  thead5: 'Usuario',
  thead6: 'Acciones',
  ascending: 'Ascendente',
  descending: 'Descendente',
  petition: 'Petición',
  complaint: 'Queja',
  request: 'Solicitud',
  changeEmail: 'Cambio de correo',
  deleteAccount: 'Eliminación de cuenta',
  changeName: 'Cambio de nombre',
  changeData: 'Cambio de datos',
  userPerson: 'Usuario Persona',
  userEnterprise: 'Usuario Empresa'
}

export const historyPaysString = {
  thead1: 'ID',
  thead2: 'Nombre',
  thead3: 'Fecha de pago',
  thead4: 'Tipo de plan',
  thead5: 'Acciones',
  control: 'Data Control',
  plus: 'Más',
  premium: 'Premium',
  actionBtnText1: 'Detalles'
}

export const historyPaysDetailsString = {
  dataTitle: 'Datos',
  dataLabel1: 'Nombre',
  dataLabel2: 'Celular',
  dataLabel3: 'Correo',
  dataLabel4: 'Tipo de identificación',
  dataLabel5: 'Identificación',
  subsectionTitle: 'Historial de pagos por Contacto no Permitido',
  thead1: 'Fechas de pago recibido',
  thead2: 'Empresa a la que reclamó',
  thead3: 'Hora',
  thead4: 'Dinero',
  currencyUnit: ' Pesos',
  footerTextBtn: 'Volver',
  footerAltTextBtn: 'iconBack'
}

export const bulkUploadsString = {
  dropdownTitle: 'Elige Sponsor',
  dropdownAltImgText1: 'Flecha-gris',
  principalSectionAltImgText1: 'Imagen de base de datos',
  warningNote1: '*Sólo archivos Excel o similares',
  allowedExtensions:
    'La extensión del archivo debe ser alguna de las siguientes: ',
  modalBtnText: 'OK'
}

export const userUploadsString = {
  progressBarTitle: 'Carga de usuarios',
  principalSectionAltImgText1: 'Imagen de base de datos',
  footerBtnAltImgText1: 'Botón volver'
}

export const indexContact = {
  notAllowedContact: 'Contacto no permitido',
  documentsUpdate: 'Actualización de documentos'
}

export const notAllowedContactString = {
  files: 'Archivos',
  details: 'Detalles',
  seeBtn: 'Ver',
  reject: 'Rechazar',
  proceed: 'Proceder',
  firstHeadColumn: 'ID',
  secondHeadColumn: 'Nombre',
  thirdHeadColumn: 'Reclamación a',
  fourthHeadColumn: 'Tipo de proceso',
  fifthHeadColumn: 'Acciones',
  notAttachments: 'No hay adjuntos disponibles...'
}

export const rejectString = {
  replyReject: 'Responder motivo del rechazo:',
  save: 'Guardar',
  send: 'Enviar'
}

export const lawyersDetailedDescriptionString = {
  description: 'Descripción',
  plan: 'Plan:',
  user: 'Usuario: ',
  date: 'Fecha',
  time: 'Hora',
  contact: 'Contacto',
  state: 'Estado: ',
  forbiddenContact: 'Contacto no permitido por parte de la empresa: ',
  responseRejectReason: 'Responder motivo del rechazo',
  rejectModalMessage: 'El usuario ha sido informado del motivo del rechazo',
  charactersLimit: '/2000'
}

export const lawyersTableString = {
  thead1: 'ID',
  thead2: 'Nombre',
  thead3: 'PQRS',
  thead4: 'Título',
  thead5: 'Usuario',
  thead6: 'Acciones',
  ascending: 'Ascendente',
  descending: 'Descendente',
  userPerson: 'Usuario Persona',
  userEnterprise: 'Usuario Empresa'
}

export const entriesListString = {
  thead1: 'ID',
  thead2: 'Identificación',
  thead3: 'Usuario',
  thead4: 'Celular',
  thead5: 'Correo',
  thead6: 'Estado',
  thead7: 'Acciones',
  modalDescription: (
    <span>
      Está eliminando un usuario <br /> ¿Desea Continuar?{' '}
    </span>
  ),
  cancel: 'Cancelar',
  continue: 'Continuar',
  ascendant: 'Ascendente',
  descendant: 'Descendente'
}

export const newEntitiesString = {
  thead1: 'ID',
  thead2: 'Tipo de Solicitud',
  thead3: 'Empresa',
  thead4: 'NIT',
  thead5: 'Solicitante',
  thead6: 'Estado',
  thead7: 'Acciones',
  modalDescription: (
    <span>
      La entidad ha sido eliminada <br /> ¿Desea Continuar?{' '}
    </span>
  ),
  cancel: 'Cancelar',
  continue: 'Continuar',
  ascendant: 'Ascendente',
  descendant: 'Descendente'
}

export const unregisteredUserString = {
  thead1: 'ID',
  thead2: 'Nombre',
  thead3: 'Celular',
  thead4: 'Correo',
  thead5: 'Acciones'
}

export const userPocketString = {
  thead1: 'ID',
  thead2: 'Nombre',
  thead3: 'Identificación',
  thead4: 'Correo Electrónico',
  thead5: 'Ver'
}

// export const entityMovementsString = {
//   data: 'Datos',
//   name: 'Nombre',
//   email: 'Correo',
//   movementsHistory: 'Historial de movimientos',
//   transaction: 'Transacción',
//   thead1: 'Transacciones',
//   thead2: 'Fecha de compra',
//   thead3: 'Fecha de caducidad',
//   thead4: 'Base de Datos',
//   query: 'Solicitud'
// }
export const entityMovementsString = {
  dataUsers: 'Datos - Usuario(a) Data Control',
  name: 'Nombre',
  payDone: 'Pago realizado',
  email: 'Correo',
  blockQuery: 'Solicitud de bloqueo',
  buyDBQuery: 'Solicitud de compra de base de datos',
  thead1: 'Entidad',
  thead2: 'Cantidad de usuarios',
  thead3: 'Fecha de pago',
  thead4: 'ID de pago',
  startLegalProcess: 'Iniciar Proceso Legal',
  commentsTitle: 'Realiza la descripción de la respuesta*',
  back: 'Volver',
  modalDescription1: 'Se ha realizado un bloqueo exitosamente para el usuario ',
  modalDescription2: '¿Desea finalizar proceso?',
  sendComment: 'Responder',
  reject: 'Rechazar',
  accept: 'Aceptar',
  cancel: 'Cancelar',
  continue: 'Continuar',
  dataBaseType: 'Base de datos',
  acceptModalMsg: (<span>Estás a punto de aceptar esta solicitud. <br /> ¿Te gustaría continuar?</span>),
  rejectModalMsg: (<span>Estás a punto de rechazar esta solicitud. <br /> ¿Te gustaría continuar?</span>)
}

export const createEntityString = {
  typeData: 'Ingresa los datos de la nueva entidad.',
  businessName: 'Razón social empresa',
  tradename: 'Nombre comercial',
  nit: 'NIT (obligatorio)',
  email: 'Correo electrónico',
  cel: 'Celular',
  tel: 'Teléfono (opcional)',
  back: 'Volver',
  create: 'crear',
  typeBusinessName: 'Ingresa la razón social',
  typeTradename: 'Ingresa el nombre comercial de la empresa',
  typeNit: 'Ingresa el Nit de la empresa sin el dígito de verificación',
  typeEmail: 'Ingresa el correo electrónico de la empresa',
  typeCel: '+57           Ingresa el número de celular',
  typeTel: 'Ingresa el número de teléfono'
}

export const rejectEntryString = {
  rejectionReason: 'Motivo del rechazo',
  rejectionDescription:
    'Describa los hallazgos realizados que impiden la creación de la empresa o entidad.'
}

export const movementsLayoutString = {
  tuDataControlMovements: 'Movimientos Data Control',
  userMovements: 'Movimientos del usuario',
  entityMovements: 'Movimientos por entidad',
  userPocket: 'Bolsillo usuario',
  entitiesPayment: 'Pago de entidades',
  tuDataControlPayments: 'Pagos Data Control'
}

export const bolsilloUsuarioString = {
  thead1: 'ID',
  thead2: 'Nombre',
  thead3: 'Identificación',
  thead4: 'Correo electrónico',
  thead5: 'Acciones',
  thead6: 'Detalles',
  modalDescription: (
    <span>
      Está eliminando un usuario <br /> ¿Desea Continuar?{' '}
    </span>
  ),
  cancel: 'Cancelar',
  continue: 'Continuar',
  search: 'Buscar'
}

export const movementsControlString = {
  dataUsers: 'Datos - Usuario(a) Data Control',
  name: 'Nombre',
  payDone: 'Pago realizado',
  email: 'Correo',
  blockQuery: 'Solicitud de bloqueo',
  thead1: 'Entidad',
  thead2: 'NIT',
  thead3: 'Fecha de pago',
  thead4: 'ID de pago',
  thead5: 'Producto',
  thead6: 'Fecha de pago',
  thead7: 'ID de pago',
  startLegalProcess: 'Iniciar Proceso Legal',
  commentsTitle: 'Realiza la descripción de la respuesta*',
  back: 'Volver',
  modalDescription1: 'Se ha realizado un bloqueo exitosamente para el usuario ',
  modalDescription2: '¿Desea finalizar proceso?',
  sendComment: 'Responder',
  cancel: 'Cancelar',
  continue: 'Continuar',
  accept: 'Aceptar',
  reject: 'Rechazar',
  acceptModalMsg: (<span>Estás a punto de aceptar esta solicitud. <br /> ¿Te gustaría continuar?</span>),
  rejectModalMsg: (<span>Estás a punto de rechazar esta solicitud. <br /> ¿Te gustaría continuar?</span>),
  infoPackage: {
    CONTROL3: 'Paquete de bloqueos (3 empresas)',
    CONTROL5: 'Paquete de bloqueos (5 empresas)'
  }
}

export const payEntitiesString = {
  thead1: 'ID',
  thead2: 'Entidad',
  thead3: 'NIT',
  thead4: 'Tipo de BD',
  thead5: 'Fecha de caducidad',
  thead6: 'Acciones',
  thead7: 'Estado',
  buttonText: 'Detalles'
}

export const payControlString = {
  thead1: 'ID',
  thead2: 'Nombre',
  thead3: 'Pago',
  thead4: 'Fecha de pago',
  thead5: 'Estado',
  thead6: 'Acciones'
}

export const RequestString = {
  requestActionString: {
    searchAndIdentifyUser: 'Busca e identifica el usuario y la entidad ',
    for: 'para ',
    proceed: '"Proceder" ',
    or: 'o ',
    completeProcess: '"Completar proceso"',
    searchUser: 'Buscar usuario',
    searchEntity: 'Buscar entidad',
    userNotFound: 'Ningún usuario encontrado',
    companyNotFound: 'Ninguna entidad encontrada',
    name: 'Nombre:',
    id: 'ID:',
    email: 'Correo:',
    nit: 'NIT:',
    queryType: 'Tipo de solicitud:',
    continueProcessBtn: 'Continuar proceso',
    finishProcessBtn: 'Finalizar proceso',
    clientWithNoDemands: 'El cliente no tiene demandas.',
    demandFinished: 'El proceso de demanda debe ser finalizado.',
    processWithoutAnnexes: 'El proceso de demanda no tiene anexos',
    downloadingDocs: 'Descargando archivos',
    allowedExtensions: 'La extensión del archivo debe ser alguna de las siguientes: ',
    fileSuccessfullyUploaded: 'Se ha cargado correctamente el siguiente archivo: ',
    mustUploadDocsFirst: 'Para realizar el envío primero debes subir la respuesta de la entidad y el documento creado.'
  },
  requestDetailString: {
    data: 'Datos',
    bodyMessage: 'Cuerpo de mensaje',
    senderResponse: 'Respuesta de remitente:',
    readMore: 'Leer más...',
    downloadBtn: 'Descargar',
    continueBtn: 'Continuar'
  },
  requestFinishString: {
    form: 'Formulario',
    user: 'Usuario',
    name: 'Nombre: ',
    id: 'ID: ',
    email: 'Correo: ',
    entity: 'Entidad',
    nit: 'NIT: ',
    finishUploading: 'Finaliza subiendo el documento recibido por correo.',
    uploadDocument: 'Subir documento',
    finishBtn: 'Finalizar',
    numberFiled1: 'Proceso con radicado no. ',
    numberFiled2: ' ha finalizado',
    userNoticed: 'El usuario ha sido informado.',
    acceptBtn: 'Aceptar'
  },
  requestSendString: {
    form: 'Formulario',
    user: 'Usuario',
    name: 'Nombre: ',
    id: 'ID: ',
    email: 'Correo: ',
    entity: 'Entidad',
    nit: 'NIT: ',
    contentInfo: 'Contenido información',
    downloadAnnexes: 'Descarga los anexos',
    annexesBtn: 'Anexos',
    uploadResponse: 'Subir respuesta entidad',
    uploadDocumentCreated: 'Subir documento creado',
    uploadDocumentBtn: 'Subir documento',
    sendBtn: 'Enviar',
    documentSent: 'Se ha generado y enviado el documento ',
    userNoticed1: 'El usuario ha sido informado y el proceso con radicado no. ',
    userNoticed2: ' ha sido actualizado',
    acceptBtn: 'Aceptar'
  },
  readMsgText: 'Marcar mensaje como leído'
}

export const userMovementsString = {
  data: 'Datos',
  name: 'Nombre',
  email: 'Correo',
  historyMovements: 'Historial de movimientos',
  thead1: 'Transacciones',
  thead2: 'Fecha',
  thead3: 'Monto de dinero',
  back: 'Volver'
}

export const updateDocumentsStrings = {
  title: 'Actualización de documentos de Cámara de Comercio o Representa legal.',
  subtitle: 'Aquí puedes subir los documentos para actualizar información',
  documentType: 'Elige tipo de documento',
  legalRepId: 'Cédula de ciudadanía del representante',
  chamberComerce: 'Cámara de Comercio',
  uploadDocument: 'Subir documento',
  send: 'Enviar',
  modalMessage: '¡Documento enviado exitosamente!',
  invalidFileModalMsg: 'Solo se admiten archivos con formato: .jpg, .jpeg, .png, .pdf',
  ok: 'OK',
  accept: 'Aceptar'
}

export const navFooterStrings = {
  goBack: 'Volver'
}

export const dataBaseStrings = {
  column1: 'ID',
  column2: 'Tipo',
  column3: 'Entidad',
  column4: 'Fecha de expedición',
  column5: ' ',
  column6: ' '
}

export const tablaGeneralStrings = {
  simple: 'Simple',
  specialized: 'Especializada',
  lawyer: 'Abogado',
  operative: 'Operativo',
  coordinator: 'Coordinador',
  actions: 'Acciones',
  details: 'Detalles',
  deleteUser: '¿Deseas eliminar a este usuario?',
  cancel: 'Cancelar',
  delete: 'Eliminar',
  continue: 'Continuar',
  credentialsDeleted: (
    <span>
      Las credenciales de ingreso para este usuario <br /> han sido eliminadas
      con éxito
    </span>
  ),
  altImg1: 'icono editar',
  altImg2: 'icono eliminar'
}

export const generatePaymentString = {
  currentEarn: 'Dinero actual',
  rewardEarn: 'Dinero de recompensa',
  sendMoney: 'Has enviado una recompensa al usuario'
}

export const generatePaymentRowString = {
  pesosSign: '$',
  kilo: 'k',
  pesos: 'pesos'
}

export const createNewUserString = {
  newUser: 'Nuevo usuario',
  userName: 'Nombre',
  userLastName: 'Apellido',
  documentNumber: 'No. de identificación',
  idIssuance: 'Lugar de expedición',
  documentTypeId: 'Tipo de ID',
  birthdayDate: 'Fecha de nacimiento',
  gender: 'Género',
  country: 'País',
  city: 'Ciudad',
  eMail: 'Correo electrónico',
  phone: 'Teléfono',
  userPassword: 'Contraseña del usuario',
  password: 'Contraseña',
  passwordConfirmation: 'Confirmar contraseña',
  mandatoryFileds: 'Campos obligatorios',
  goBack: 'Volver',
  create: 'Crear',
  continue: 'Continuar',
  successfulyUserCreation: '¡Nuevo usuario registrado con éxito!',
  cancelUserCreation: '¿Deseas cancelar el registro del nuevo usuario?',
  yes: 'Sí',
  no: 'No',
  incorrectEmailFormat: 'Email con formato incorrecto',
  incorrectCity: 'Ciudad no válida',
  incorrectCountry: 'País no válido',
  incorrectPasswordFormat:
    'La contraseña debe contener mínimo 8 caracteres, mayúsculas, minúsculas, números y símbolos (! # $ % & * , . : ? @ [ ] ^ _ { | } ~).',
  incorrectPasswordConfirmation: 'Las contraseñas no coinciden',
  incorrectPhone: 'Teléfono no válido',
  incorrectBirthdayDate: 'Ingresa una fecha válida',
  minBirthdayDate: 'Mínimo permitido ',
  maxBirthdayDate: 'Máximo permitido ',
  mandatoryField: 'Este campo es obligatorio',
  biggerNumber: 'El No. de identificación debe ser mayor a 10000',
  minorNumber: 'El No. de identificación debe ser menor a 2000000000',
  altEyeIcon: 'imagen ojo contraseña',
  defaultDocumentTypeId: 'Tipo de ID',
  defaultGender: 'Género',
  placeholder1: 'Ingresa nombre',
  placeholder2: 'Ingresa apellido',
  placeholder3: 'Ingresa el correo electrónico',
  placeholder4: 'Ingresa país',
  placeholder5: 'Ingresa ciudad',
  placeholder6: 'Ingresa una contraseña valida',
  placeholder7: 'Número de documento de identidad',
  placeholder8: '00/00/0000',
  placeholder9: 'Ciudad de expedición de ID',
  placeholder10: '+57  Ingresa el número de celular',
  placeholder11: 'Vuelve a ingresar la contraseña',
  employeeCredentialFailed: 'GET /employeeCredential failed',
  wrongNameLength: 'El nombre debe contener 3 o más caracteres.',
  wrongLastnameLength: 'El apellido debe contener 3 o más caracteres.',
  ok: 'OK'
}

export const employeeString = {
  mandatoryField: 'Este campo es obligatorio',
  incorrectEmailFormat: 'Email con formato incorrecto',
  incorrectCity: 'Ciudad no válida',
  incorrectCountry: 'País no válido',
  incorrectPasswordFormat:
    'La contraseña debe contener mínimo 8 caracteres, mayúsculas, minúsculas, números y símbolos (! # $ % & * , . : ? @ [ ] ^ _ { | } ~).',
  incorrectPasswordConfirmation: 'Las contraseñas no coinciden',
  incorrectPhone: 'Teléfono no válido',
  incorrectBirthdayDate: 'Ingresa una fecha válida',
  minBirthdayDate: 'Mínimo permitido ',
  maxBirthdayDate: 'Máximo permitido ',
  biggerNumber: 'El No. de identificación debe ser mayor a 10000',
  minorNumber: 'El No. de identificación debe ser menor a 2000000000',
  altEyeIcon: 'imagen ojo contraseña',
  defaultDocumentTypeId: 'Tipo ID',
  defaultGender: 'Género',
  emailAlreadyExists: 'El correo digitado ya se encuentra registrado',
  phoneAlreadyExists: 'El teléfono digitado ya se encuentra registrado',
  documentAlreadyExists: 'El documento digitado ya se encuentra registrado'
}

export const editUserString = {
  editUser: 'Editar usuario',
  userName: 'Nombre',
  userLastName: 'Apellido',
  documentNumber: 'No. de identificación',
  idIssuance: 'Lugar de expedición',
  idType: 'Tipo de ID',
  idNumber: 'No. de identificación',
  birthDate: 'Fecha de nacimiento',
  gender: 'Género',
  country: 'País',
  city: 'Ciudad',
  eMail: 'Correo electrónico',
  phone: 'Teléfono',
  password: 'Contraseña (Modificar)',
  userPassword: 'Contraseña del usuario',
  passwordConfirmation: 'Confirmar contraseña (Opcional)',
  mandatoryFileds: 'Campos obligatorios',
  goBack: 'Volver',
  save: 'Guardar',
  conitnue: 'Continuar',
  successfulyUserCreation: '¡Usuario editado con éxito!',
  mandatoryField: 'Este campo es obligatorio',
  placeholder1: 'Ingresa nombre',
  placeholder2: 'Ingresa apellido',
  placeholder3: 'Ingresa el correo electrónico',
  placeholder4: 'Ingresa país',
  placeholder5: 'Ingresa ciudad',
  placeholder6: 'Ingresa una contraseña valida',
  placeholder7: 'Número de documento de identidad',
  placeholder8: '00/00/0000',
  placeholder9: 'Ciudad de expedición de ID',
  placeholder10: '+57  Ingresa el número de celular',
  placeholder11: 'Vuelve a ingresar la contraseña',
  defaultDocumentTypeId: 'Tipo ID',
  defaultGender: 'Género',
  altEyeIcon: 'imagen ojo contraseña',
  wrongNameLength: 'El nombre debe contener 3 o más caracteres.',
  wrongLastnameLength: 'El apellido debe contener 3 o más caracteres.',
  ok: 'OK'
}

export const entitiesListString = {
  searchCompany: 'Buscar entidad o empresa',
  createEntity: 'Crear entidad'
}

export const authorizeEntryString = {
  socialReason: 'Razón social empresa',
  comercialName: 'Nombre comercial',
  nit: 'NIT (obligatorio)',
  eMail: 'Correo electrónico',
  celPhone: 'Celular',
  phone: 'Teléfono (opcional)'
}

export const entriesActionsString = {
  id: 'ID',
  requestType: 'Tipo de solicitud',
  company: 'Empresa',
  nit: 'NIT',
  applicant: 'Solicitante',
  status: 'Estado',
  authorize: 'Autorizar',
  reject: 'Rechazar',
  goBack: 'Volver',
  create: 'Crear',
  registerSuccessfuly: 'La entidad ha sido registrada correctamente',
  continue: 'Continuar'
}

export const logInString = {
  portalRole: 'Administrativo',
  portalType: 'Portal Administrativo',
  title1: 'Inicio de sesión',
  description1: 'Bienvenido',
  forgotPassword: '¿Olvidaste tu contraseña?',
  loginButton: 'Ingresar',
  usernameLabel: 'Usuario',
  passwordLabel: 'Contraseña',
  rememberLabel: 'Recordarme',
  incorrectEmailFormat: 'Email con formato incorrecto',
  incorrectPasswordFormat:
    'La contraseña debe contener mínimo 8 caracteres, mayúsculas, minúsculas, números y símbolos (! # $ % & * , . : ? @ [ ] ^ _ { | } ~).',
  userNotFound: 'Usuario no encontrado.',
  maxAttemptsReached: 'Máximo de intentos alcanzados, intente en 1 minuto.',
  wrongCredentials: 'Usuario o contraseña incorrectos.',
  maxAttempts:
    'Ha excedido el límite de intentos de ingreso con una credencial incorrecta, debe esperar un tiempo para recuperar el acceso',
  unknownError: 'Error desconocido.',
  rolIdUnauthorized: 'rolId unauthorized or not provided',
  ok: 'OK'
}

export const handlePQRSString = {
  assignedTo: 'Asignado a:',
  rejected: 'Rechazada',
  applicationDescription: 'Descripción de la solicitud',
  answer: 'Respuesta:',
  upDate1: 'Actualización',
  requestLetter: 'Carta de Solicitud',
  fileUpload: 'Subir archivo',
  goBack: 'Volver',
  upDate2: 'Actualizar',
  modalContent1: 'La solicitud',
  modalContent2: 'Bloqueo de cuenta',
  modalContent3: 'cambiará a',
  modalContent4: 'Finalizado',
  modalContent5: 'en caso de que no hayas realizado el cambio',
  modalContent6: 'La solicitud',
  modalContent7: 'ha sido actualizada.',
  continue: '¿Deseas continuar?'
}

export const rejectedRequestsString = {
  info: 'Solicitudes rechazadas por entidades'
}

export const usersLayoutString = {
  historyPays: 'Historial pagos por C/P',
  bulkUpload: 'Cargas Masivas',
  indicators: 'Indicadores',
  generalStates:
    'En este apartado te encontrarás con tres estados, cada uno define la situación del caso.',
  thisAre: 'Estos son:',
  rejected: 'Rechazado',
  pending: 'Pendiente',
  ended: 'Finalizado',
  companiesAdmin: 'Adm. a empresas',
  deleteRequests: 'Solicitudes de eliminación'
}

export const userDetailString = {
  title: 'Detalles información usuario',
  imgAlt: 'Tarjeta de plan del usuario',
  subtitle1: 'Información específica',
  subtitle2: 'Información general',
  subtitle3: 'Solicitud del usuario',
  card1Title1: 'Nombre',
  card1Title2: 'Tipo de identificación',
  card1Title3: 'Celular',
  card1Title4: 'Identificación',
  card1Title5: 'Correo',
  card2Title1: 'Registro usuario',
  card2Title2: 'Saldo actual',
  buttonTxt1: 'Eliminar cuenta',
  buttonTxt2: 'Eliminación de datos',
  inactiveUser: '¿Deseas eliminar esta cuenta?',
  deleteUser: '¿Deseas eliminar toda la información de este usuario?',
  backBtnTxt: 'Volver'
}

export const usersPanelString = {
  id: 'ID',
  name: 'Nombre',
  user: 'Usuario',
  state: 'Estado',
  actions: 'Acciones'
}

export const companiesAdminString = {
  id: 'ID',
  name: 'Nombre',
  user: 'Usuario',
  state: 'Estado',
  actions: 'Acciones',
  btnTxt: 'Nueva empresa'
}

export const detalleDBStrings = {
  title: 'Propietario',
  label1: 'Entidad propietaria',
  label2: 'Estado de la base de datos',
  label3: 'Fecha de compra',
  label4: 'Fecha de caducidad',
  dbStatus1: 'Activa',
  dbStatus2: 'Vencida',
  dbType1: 'Simple',
  subtitle1: 'No. de registros',
  subtitle2: 'Base de Datos ',
  buttonText1: 'Ver archivo',
  footerButton1Text1: 'Volver'
}

export const queriesAdminString = {
  inactive: 'Error: INACTIVE_SESSION',
  expired: 'Error: SESSION_EXPIRED'
}

export const dataContextStrings = {
  expiredSessionMessage: 'Su sesión expiró. Por favor vuelva a autenticarse.',
  ok: 'OK'
}

export const validateFunctionsString = {
  maxFileExceededPart1: 'Tu archivo pesa',
  maxFileExceededPart2:
    'y supera el tamaño máximo permitido. El tamaño máximo para archivos multimedia es de',
  maxFileExceededPart3: 'y para archivos estáticos es de',
  measurementUnits: 'MB'
}

export const requestsGridString = {
  column1Name: 'Fecha de llegada',
  column2Name: 'Correo',
  column3Name: 'Asunto del correo',
  column4Name: 'Acciones'
}

export const caseTrackingString = {
  column1Name: 'ID',
  column2Name: 'Nombre',
  column3Name: 'ID',
  column4Name: 'Usuario',
  column5Name: 'Detalles'
}

export const caseTrackingDetailString = {
  userData: 'Datos Usuario',
  name: 'Nombre',
  phone: 'Celular',
  email: 'Correo',
  id: 'ID',
  gender: 'Género',
  registerDate: 'Registro en TuData',
  blockedCompaniesList: 'Listado empresas bloqueadas'
}

export const EntitiesWithoutChannelString = {
  column1Name: 'ID',
  column2Name: 'Nombre',
  column3Name: 'ID',
  column4Name: 'Entidad a bloquear',
  column5Name: 'Acciones'
}

export const CaseTrackingEntityDetailString = {
  entity: 'Entidad',
  legalRepresentative: 'Representante legal: ',
  nit: 'NIT ',
  phone: 'Teléfono: ',
  contact: 'Contacto: ',
  column1Name: 'Proceso',
  column2Name: 'Estado',
  column3Name: 'Detalles',
  createNewProcess: 'Crear nuevo proceso',
  noContactChannel: 'No hay canal de comunicación'
}

export const DocumentationProcessString = {
  column1Name: 'Proceso',
  column2Name: 'Estado',
  column3Name: 'Fecha de inicio',
  documentationTitle: 'Documentación',
  downloadAnnexes: 'Descargar los anexos del caso',
  annexes: 'Anexos',
  addNewAnnex: 'Agregar nuevo anexo al caso',
  addAnnex: 'Agregar anexo',
  sendAnnex: 'Enviar anexo',
  acceptOrReject: 'Acepta o rechaza ',
  asAppropiate: ' según corresponda',
  accept: 'Aceptar',
  reject: 'Rechazar',
  processFinished: 'Proceso con radicado no. * xxxxxx * ha finalizado',
  userNoticed: 'El usuario ha sido informado'
}

export const SelectProcessString = {
  selectProcess: 'Seleccionar Proceso',
  annexesUploaded: 'Anexos subidos',
  noAnnexesUploaded: 'No hay anexos existentes...',
  uploadAnnexes: 'Subir anexos',
  send: 'Enviar',
  processSuccess: '¡El proceso ha sido creado correctamente!',
  accept: 'Aceptar'
}

export const SelectProcessNoContactString = {
  selectProcess: 'Seleccionar proceso',
  uploadAnnexes: 'Subir anexos',
  send: 'Enviar'
}

export const typeProcessKeys = [
  'Recurso de reposición',
  'Tutela',
  'Queja ante la SIC'
]

export const companiesLayoutStrings = {
  headerItem1: 'Empresas'
}

export const operativeUsersAdminStrings = {
  thead1: 'ID',
  thead2: 'Nombre',
  thead3: 'ID',
  thead4: 'Usuario',
  thead5: 'Acciones'
}

export const operativeUsersAdminDetailsStrings = {
  title1: 'Detalles información usuario',
  title2: 'Información específica',
  title3: 'Información general',
  title4: 'Empresas bloqueadas',
  title5: 'Empresas autorizadas',
  subtitle1: 'Nombre',
  subtitle2: 'Tipo de identificación',
  subtitle3: 'Celular',
  subtitle4: 'Identificación',
  subtitle5: 'Correo',
  subtitle6: 'Registro usuario',
  subtitle7: 'Saldo actual',
  subtitle8: 'Empresa',
  subtitle9: 'Estado',
  backBtnTxt: 'Volver',
  btn1Txt: 'Reactivar cuenta',
  btn2Txt: 'Eliminar datos',
  warningTxt1: 'No aplica para este plan.',
  modalMessage1: '¡Cuenta reactivada exitosamente!',
  modalMessage2: '¡Datos eliminados exitosamente!',
  modalBtnTxt1: 'Ok'
}
