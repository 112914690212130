import { dataTypes } from './dataTypes'

export const dataReducer = (state, action = {}) => {
  let indexEmployeeToBeUpdated
  let newEmployeesList
  switch (action.type) {
    case dataTypes.administrativeLogin:
      return {
        ...state,
        logged: action.payload.logged,
        profile: {
          ...state.profile,
          role: action.payload.roleName
        },
        administrative: {
          ...state.administrative,
          employeeCredentialEmail: action.payload.loginDataFetched.findEmployeeBody.employeeCredentialEmail,
          employeeCredentialId: action.payload.loginDataFetched.findEmployeeBody.employeeCredentialId,
          employeeId: action.payload.loginDataFetched.findEmployeeBody.employeeId.employeeId,
          idToken: action.payload.loginDataFetched.idToken,
          roleName: action.payload.loginDataFetched.findEmployeeBody.employeeId.roleId.roleName,
          rolId: action.payload.loginDataFetched.findEmployeeBody.employeeId.roleId.rolId
        }
      }
    case dataTypes.setEmployeesList:
      return {
        ...state,
        administrative: {
          ...state.administrative,
          employeesList: action.payload
        }
      }
    case dataTypes.roleIdToBeCreated:
      return {
        ...state,
        profile: {
          ...state.profile,
          userProfile: action.payload.userProfile
        },
        administrative: {
          ...state.administrative,
          roleIdToBeCreated: action.payload.role
        }
      }
    case dataTypes.addEmployeeToEmployeesList:
      return {
        ...state,
        administrative: {
          ...state.administrative,
          employeesList: [...state.administrative.employeesList, action.payload]
        }
      }
    case dataTypes.updateEmployeeInEmployeesList:
      indexEmployeeToBeUpdated = state.administrative.employeesList.findIndex(employee => (
        employee.employeeCredentialId === action.payload.employeeCredentialId
      ))

      newEmployeesList = [...state.administrative.employeesList]

      if (indexEmployeeToBeUpdated !== -1) {
        const employeeUpdated = {
          ...action.payload,
          employeeId: {
            ...action.payload.employeeId,
            roleId: {
              ...newEmployeesList[indexEmployeeToBeUpdated].employeeId.roleId
            }
          }
        }

        // Actualizar la nueva lista de empleados sacando el empleado obsoleto y colocando el empleado actualizado
        newEmployeesList.splice(indexEmployeeToBeUpdated, 1, employeeUpdated)
      }

      if (action.payload.originalEmployeeCredentialEmail === state.administrative.employeeCredentialEmail) {
        return {
          ...state,
          administrative: {
            ...state.administrative,
            employeeCredentialEmail: action.payload.employeeCredentialEmail,
            employeesList: newEmployeesList
          }
        }
      }

      return {
        ...state,
        administrative: {
          ...state.administrative,
          employeesList: newEmployeesList
        }
      }
    case dataTypes.deleteEmployeeInEmployeesList:
      return {
        ...state,
        administrative: {
          ...state.administrative,
          employeesList: state.administrative.employeesList.filter(employee => employee.employeeCredentialId !== action.payload)
        }
      }
    case dataTypes.employeeToBeEdited:
      return {
        ...state,
        administrative: {
          ...state.administrative,
          employeeToBeEdited: action.payload
        }
      }
    case dataTypes.updateDataBases:
      return {
        ...state,
        administrative: {
          ...state.administrative,
          dataBases: {
            ...state.administrative.dataBases,
            dataBases: action.payload.dataBases
          }
        }
      }
    case dataTypes.setDatabaseToBeReviewed:
      return {
        ...state,
        administrative: {
          ...state.administrative,
          dataBases: {
            ...state.administrative.dataBases,
            databaseToBeReviewed: action.payload
          }
        }
      }
    case dataTypes.citiesDocTypesGenders:
      return {
        ...state,
        administrative: {
          ...state.administrative,
          citiesDocTypesGenders: {
            countriesFetched: action.payload.countriesFetched,
            citiesFetched: action.payload.citiesFetched,
            docTypesFetched: action.payload.docTypesFetched,
            gendersFetched: action.payload.gendersFetched
          }
        }
      }
    case dataTypes.operativeLogin:
      return {
        ...state,
        logged: action.payload.logged,
        profile: {
          ...state.profile,
          role: action.payload.roleName
        },
        operative: {
          ...state.operative,
          employeeCredentialEmail: action.payload.loginDataFetched.findEmployeeBody.employeeCredentialEmail,
          employeeCredentialId: action.payload.loginDataFetched.findEmployeeBody.employeeCredentialId,
          employeeId: action.payload.loginDataFetched.findEmployeeBody.employeeId.employeeId,
          idToken: action.payload.loginDataFetched.idToken,
          roleName: action.payload.loginDataFetched.findEmployeeBody.employeeId.roleId.roleName,
          rolId: action.payload.loginDataFetched.findEmployeeBody.employeeId.roleId.rolId
        }
      }
    case dataTypes.getCities:
      return {
        ...state,
        operative: {
          ...state.operative,
          citiesFetched: action.payload
        }
      }
    case dataTypes.getStates:
      return {
        ...state,
        operative: {
          ...state.operative,
          statesFetched: action.payload
        }
      }
    case dataTypes.getDocTypes:
      return {
        ...state,
        operative: {
          ...state.operative,
          docTypesFetched: action.payload
        }
      }
    case dataTypes.getGenders:
      return {
        ...state,
        operative: {
          ...state.operative,
          gendersFetched: action.payload
        }
      }
    case dataTypes.updateNewUsersEntries:
      return {
        ...state,
        operative: {
          ...state.operative,
          newEntries: {
            ...state.operative.newEntries,
            users: action.payload.users
          }
        }
      }
    case dataTypes.updateNewEntitiesEntries:
      return {
        ...state,
        operative: {
          ...state.operative,
          newEntries: {
            ...state.operative.newEntries,
            entities: action.payload.entities
          }
        }
      }
    case dataTypes.setNewUserEntryToBeReviewd:
      return {
        ...state,
        operative: {
          ...state.operative,
          newEntries: {
            ...state.operative.newEntries,
            userEntryToBeReviewed: action.payload
          }
        }
      }
    case dataTypes.setUserEntryToBeModified:
      return {
        ...state,
        operative: {
          ...state.operative,
          newEntries: {
            ...state.operative.newEntries,
            userEntryToBeModified: action.payload
          }
        }
      }
    case dataTypes.setEntityEntryToBeModified:
      return {
        ...state,
        operative: {
          ...state.operative,
          newEntries: {
            ...state.operative.newEntries,
            entityEntryToBeModified: action.payload
          }
        }
      }
    case dataTypes.setNewEntityEntryToBeReviewd:
      return {
        ...state,
        operative: {
          ...state.operative,
          newEntries: {
            ...state.operative.newEntries,
            entityEntryToBeReviewed: action.payload
          }
        }
      }
    case dataTypes.updateUserPocket:
      return {
        ...state,
        operative: {
          ...state.operative,
          movements: {
            ...state.operative.movements,
            userPocket: action.payload.userPocket
          }
        }
      }
    case dataTypes.setUserPocketToBeReviewed:
      return {
        ...state,
        operative: {
          ...state.operative,
          movements: {
            ...state.operative.movements,
            userPocketToBeReviewed: action.payload
          }
        }
      }
    case dataTypes.setPaymentOfEntityToBeReviewed:
      return {
        ...state,
        operative: {
          ...state.operative,
          movements: {
            ...state.operative.movements,
            paymentOfEntityToBeReviewed: action.payload
          }
        }
      }
    case dataTypes.setTuDataControlPaymentToBeReviewd:
      return {
        ...state,
        operative: {
          ...state.operative,
          movements: {
            ...state.operative.movements,
            tuDataControlPaymentToBeReviewed: action.payload
          }
        }
      }
    case dataTypes.updatePaymentOfEntities:
      return {
        ...state,
        operative: {
          ...state.operative,
          movements: {
            ...state.operative.movements,
            paymentOfEntities: action.payload.paymentOfEntities
          }
        }
      }
    case dataTypes.updatePqrsOfUnregisteredUsers:
      return {
        ...state,
        operative: {
          ...state.operative,
          newEntries: {
            ...state.operative.newEntries,
            pqrsOfUnregisteredUsers: action.payload.pqrsOfUnregisteredUsers
          }
        }
      }
    case dataTypes.setPqrsOfUnregisteredUsers:
      return {
        ...state,
        operative: {
          ...state.operative,
          newEntries: {
            ...state.operative.newEntries,
            pqrsOfUnregisteredUserToBeReviewed: action.payload
          }
        }
      }
    case dataTypes.setPaginationOfUnreadPqrs:
      return {
        ...state,
        operative: {
          ...state.operative,
          newEntries: {
            ...state.operative.newEntries,
            paginationOfUnreadPqrs: action.payload
          }
        }
      }
    case dataTypes.updateTuDataControlPayments:
      return {
        ...state,
        operative: {
          ...state.operative,
          movements: {
            ...state.operative.movements,
            tuDataControlPayments: action.payload.tuDataControlPayments
          }
        }
      }
    case dataTypes.updateUsersManagement:
      return {
        ...state,
        operative: {
          ...state.operative,
          users: {
            ...state.operative.users,
            usersManagement: action.payload.usersManagement
          }
        }
      }
    case dataTypes.updateDeleteRequests:
      return {
        ...state,
        operative: {
          ...state.operative,
          users: {
            ...state.operative.users,
            deleteRequestsManagement: action.payload.deleteRequestsManagement
          }
        }
      }
    case dataTypes.updateChangePlanManagement:
      return {
        ...state,
        operative: {
          ...state.operative,
          users: {
            ...state.operative.users,
            adminChangePlan: action.payload.adminChangePlan
          }
        }
      }
    case dataTypes.updateCompaniesManagement:
      return {
        ...state,
        operative: {
          ...state.operative,
          users: {
            ...state.operative.users,
            companiesManagement: action.payload.companiesManagement
          }
        }
      }
    case dataTypes.updatePaymentHistoryPerContactNotAllowed:
      return {
        ...state,
        operative: {
          ...state.operative,
          users: {
            ...state.operative.users,
            paymentHistoryPerContactNotAllowed: action.payload.paymentHistoryPerContactNotAllowed
          }
        }
      }
    case dataTypes.setPaymentHistoryDetailsPerContactNotAllowed:
      return {
        ...state,
        operative: {
          ...state.operative,
          users: {
            ...state.operative.users,
            historyPerCPDetailToBeReviewed: action.payload
          }
        }
      }
    case dataTypes.setUserManagementToBeReviewed:
      return {
        ...state,
        operative: {
          ...state.operative,
          users: {
            ...state.operative.users,
            userManagementToBeReviewed: action.payload
          }
        }
      }
    case dataTypes.setUpdateDeleteRequests:
      return {
        ...state,
        operative: {
          ...state.operative,
          users: {
            ...state.operative.users,
            deleteRequestsToBeReviewed: action.payload
          }
        }
      }
    case dataTypes.setAdminChangePlanToBeReviewed:
      return {
        ...state,
        operative: {
          ...state.operative,
          users: {
            ...state.operative.users,
            adminChangePlanToBeReviewed: action.payload
          }
        }
      }
    case dataTypes.setCompanyManagementToBeReviewd:
      return {
        ...state,
        operative: {
          ...state.operative,
          users: {
            ...state.operative.users,
            companyManagementToBeReviewed: action.payload
          }
        }
      }
    case dataTypes.setCsvDataOnMassiveUploads:
      return {
        ...state,
        operative: {
          ...state.operative,
          users: {
            ...state.operative.users,
            massiveUploads: {
              ...state.operative.users.massiveUploads,
              csvData: action.payload
            }
          }
        }
      }
    case dataTypes.setCompanyOnMassiveUploads:
      return {
        ...state,
        operative: {
          ...state.operative,
          users: {
            ...state.operative.users,
            massiveUploads: {
              ...state.operative.users.massiveUploads,
              company: action.payload
            }
          }
        }
      }
    case dataTypes.updatePqrsList:
      return {
        ...state,
        operative: {
          ...state.operative,
          pqrs: {
            ...state.operative.pqrs,
            pqrsList: action.payload.pqrsList
          }
        }
      }
    case dataTypes.getPqrsDetail:
      return {
        ...state,
        operative: {
          ...state.operative,
          pqrs: {
            ...state.operative.pqrs,
            pqrsDetail: action.payload.pqrsDetail
          }
        }
      }
    case dataTypes.lawyerLogin:
      return {
        ...state,
        logged: action.payload.logged,
        profile: {
          ...state.profile,
          role: action.payload.roleName
        },
        lawyer: {
          ...state.lawyer,
          employeeCredentialEmail: action.payload.loginDataFetched.findEmployeeBody.employeeCredentialEmail,
          employeeCredentialId: action.payload.loginDataFetched.findEmployeeBody.employeeCredentialId,
          employeeId: action.payload.loginDataFetched.findEmployeeBody.employeeId.employeeId,
          idToken: action.payload.loginDataFetched.idToken,
          roleName: action.payload.loginDataFetched.findEmployeeBody.employeeId.roleId.roleName,
          rolId: action.payload.loginDataFetched.findEmployeeBody.employeeId.roleId.rolId
        }
      }
    case dataTypes.getRequirementList:
      return {
        ...state,
        lawyer: {
          ...state.lawyer,
          requirementList: action.payload.requirementList
        }
      }
    case dataTypes.getNotAllowedContactList:
      return {
        ...state,
        lawyer: {
          ...state.lawyer,
          notAllowedContact: {
            ...state.lawyer.notAllowedContact,
            notAllowedContactList: action.payload.notAllowedContactList
          }
        }
      }
    case dataTypes.getNotAllowedContactDetail:
      return {
        ...state,
        lawyer: {
          ...state.lawyer,
          notAllowedContact: {
            ...state.lawyer.notAllowedContact,
            notAllowedContactDetail: action.payload.notAllowedContactDetail
          }
        }
      }
    case dataTypes.getResponsesByEntity:
      return {
        ...state,
        lawyer: {
          ...state.lawyer,
          requests: {
            ...state.lawyer.requests,
            responsesByEntity: action.payload.responsesByEntity
          }
        }
      }
    case dataTypes.getCaseTrackingList:
      return {
        ...state,
        lawyer: {
          ...state.lawyer,
          caseTracking: {
            ...state.lawyer.caseTracking,
            caseTrackingList: action.payload.caseTrackingList
          }
        }
      }
    case dataTypes.getCaseTrackingUserDetail:
      return {
        ...state,
        lawyer: {
          ...state.lawyer,
          caseTracking: {
            ...state.lawyer.caseTracking,
            caseTrackingUserDetail: action.payload.caseTrackingUserDetail
          }
        }
      }
    case dataTypes.getCaseTrackingEntityDetail:
      return {
        ...state,
        lawyer: {
          ...state.lawyer,
          caseTracking: {
            ...state.lawyer.caseTracking,
            caseTrackingEntityDetail: action.payload.caseTrackingEntityDetail
          }
        }
      }
    case dataTypes.getEntityWithoutChannelContactList:
      return {
        ...state,
        lawyer: {
          ...state.lawyer,
          caseTracking: {
            ...state.lawyer.caseTracking,
            entityWithoutChannelContactList: action.payload.entityWithoutChannelContactList
          }
        }
      }
    case dataTypes.getEntityWithoutChannelContactDetail:
      return {
        ...state,
        lawyer: {
          ...state.lawyer,
          caseTracking: {
            ...state.lawyer.caseTracking,
            entityWithoutChannelContactDetail: action.payload.entityWithoutChannelContactDetail
          }
        }
      }
    case dataTypes.setResponseByEntityDetail:
      return {
        ...state,
        lawyer: {
          ...state.lawyer,
          requests: {
            ...state.lawyer.requests,
            responseByEntityDetail: action.payload
          }
        }
      }
    case dataTypes.setUserAndEntityToBeReviewed:
      return {
        ...state,
        lawyer: {
          ...state.lawyer,
          requests: {
            ...state.lawyer.requests,
            userAndEntityToBeReviewed: action.payload
          }
        }
      }
    case dataTypes.logout:
      return { ...action.payload }
    default:
      return state
  }
}
