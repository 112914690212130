import React, { useContext, useEffect, useRef, useState } from 'react'
import editIcon from '../../../../assets/icons/icon_editar.svg'
import expiredIcon from '../../../../assets/icons/icon_expired.png'
import './CaseTracking.css'
import { useNavigate } from 'react-router-dom'
import { usePaginateQuery } from '../../../../hooks/usePaginateQuery'
import { DataContext } from '../../../../context/DataContext'
import { dataTypes } from '../../../../context/reducers/dataReducer/dataTypes'
import { useFilterBySearch } from '../../../../hooks/useFilterBySearch'
import { formatCaseTrackingList } from './CaseTrackingFunctions'
import { Pagination } from '../../../../components/Pagination/Pagination'
import { Dropdown } from '../../../../components/Dropdown/Dropdown'
import { getCaseTrackingList, searchFilterCaseTracking } from '../../../../utils/userQueries'
import { ScriptContext } from '../../../../context/ScriptsContext'
import { EmptyData } from '../../../../components/EmptyData/EmptyData'

export const CaseTracking = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const navigate = useNavigate()
  const dataContext = useContext(DataContext)
  const { dataState } = dataContext
  const { idToken, caseTracking } = dataState.lawyer
  const { caseTrackingList } = caseTracking
  const responseCaseTrackingList = caseTrackingList.list

  const originalResponseCaseTrackingList = useRef(caseTrackingList.list)
  const [originalIsChanged, setOriginalIsChanged] = useState(0)
  useEffect(() => {
    if (originalIsChanged <= 1) {
      originalResponseCaseTrackingList.current = responseCaseTrackingList
      setOriginalIsChanged(originalIsChanged + 1)
    }
  }, [responseCaseTrackingList])

  const [queryPersonNameBody, setQueryPersonNameBody] = useState(null)
  const [queryPersonIDBody, setQueryPersonIDBody] = useState(null)
  const [newPersonID, setNewPersonID] = useState('')
  const [newPersonName, setNewPersonName] = useState('')

  const newPersonNameBody = {
    personId: {
      personName: newPersonName
    }
  }

  const newPersonIDBody = {
    personId: {
      personIdentNum: newPersonID
    }
  }

  const [paginationValues, setPaginationValues, isNextActive, setIsNextActive, isPrevActive, setIsPrevActive] = usePaginateQuery(
    caseTrackingList,
    'caseTrackingList',
    queryPersonNameBody || queryPersonIDBody ? searchFilterCaseTracking : getCaseTrackingList,
    dataTypes.getCaseTrackingList,
    idToken,
    dataContext,
    queryPersonNameBody || queryPersonIDBody || null
  )

  const { page, limit, totalPages } = paginationValues

  const {
    listFormatted
  } = useFilterBySearch(
    responseCaseTrackingList,
    formatCaseTrackingList,
    page,
    limit
  )

  return (
    <section id='CaseTracking'>
      <table className='CaseTrackingTable'>
        <thead className='CaseTrackingTableHead'>
          <tr>
            <th>{scriptsSave && scriptsSave.caseTrackingString ? scriptsSave.caseTrackingString.column1Name : ''}</th>
            <th>
              <Dropdown
                title={scriptsSave && scriptsSave.caseTrackingString ? scriptsSave.caseTrackingString.column2Name : ''}
                type={'search'}
                newQueryBody={newPersonNameBody}
                setQueryBody={setQueryPersonNameBody}
                setNewValue={setNewPersonName}
              />
            </th>
            <th>
              <Dropdown
                title={scriptsSave && scriptsSave.caseTrackingString ? scriptsSave.caseTrackingString.column3Name : ''}
                type={'search'}
                newQueryBody={newPersonIDBody}
                setQueryBody={setQueryPersonIDBody}
                setNewValue={setNewPersonID}
              />
            </th>
            <th>{scriptsSave && scriptsSave.caseTrackingString ? scriptsSave.caseTrackingString.column4Name : ''}</th>
            <th>{scriptsSave && scriptsSave.caseTrackingString ? scriptsSave.caseTrackingString.column5Name : ''}</th>
          </tr>
        </thead>
        <tbody className='CaseTrackingTableBody'>
          {listFormatted.map((response) => (
            <tr key={response.id}>
              <td>{response.id}</td>
              <td>{response.name} {response.lastName}</td>
              <td>{response.userIdentNum}</td>
              <td className='userPlanColumn'>
                {`Data ${response.plan}`}
                <div className='expiredAlert'>
                  <span className='expiredAlertText'>{response.expired && 'Vencido'}</span>
                  {
                    response.expired &&
                      (
                        <img
                          src={expiredIcon}
                          alt={scriptsSave && scriptsSave.caseTrackingString ? scriptsSave.caseTrackingString.altImageTxt1 : ''}
                        />
                      )
                  }
                </div>
              </td>
              <td>
              <button
                className='requestsGridActionsBtn'
                onClick={() => navigate('SeguimientoCasosDetalle', { state: { userId: response.userId } })}
              >
                <img
                  src={editIcon}
                  alt={scriptsSave && scriptsSave.caseTrackingString ? scriptsSave.caseTrackingString.altImageTxt2 : ''}
                />
              </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {(originalResponseCaseTrackingList.current.length !== 0 && listFormatted.length === 0) && <EmptyData/>}

      <Pagination
        currentPage={caseTrackingList.currentPage}
        paginationValues={paginationValues}
        scrollMeasurement={caseTrackingList.scrollMeasurement}
        setPaginationValues={setPaginationValues}
        totalPages={totalPages}
        isNextActive={isNextActive}
        setIsNextActive={setIsNextActive}
        isPrevActive={isPrevActive}
        setIsPrevActive={setIsPrevActive}
      />
    </section>
  )
}
