import React, { useContext, useEffect, useRef, useState } from 'react'
import { DataContext } from '../../context/DataContext'
import { Dropdown } from '../../components/Dropdown/Dropdown'
import { usePaginateQuery } from '../../hooks/usePaginateQuery'
import { getAdminChangePlanData, searchFilterAdminChangePlan } from '../../utils/userQueries'
import { dataTypes } from '../../context/reducers/dataReducer/dataTypes'
import { Pagination } from '../../components/Pagination/Pagination'
import { useFilterBySearch } from '../../hooks/useFilterBySearch'
import { formatAdminChangePlanList, onDetailClick } from './ChangePlanAdminFunctions'
import { ScriptContext } from '../../context/ScriptsContext'
import { useNavigate } from 'react-router-dom'
import editIcon from '../../assets/icons/icon_editar.svg'
import './ChangePlanAdmin.css'
import { EmptyData } from '../../components/EmptyData/EmptyData'

export const ChangePlanAdmin = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const dataContext = useContext(DataContext)
  const { dataState, dataDispatch } = dataContext
  const { idToken, users } = dataState.operative
  const { adminChangePlan } = users
  const deleteRequestsList = users.adminChangePlan.list

  const originalDeleteRequestList = useRef(users.adminChangePlan.list)
  const [originalIsChanged, setOriginalIsChanged] = useState(0)
  useEffect(() => {
    if (originalIsChanged <= 1) {
      originalDeleteRequestList.current = deleteRequestsList
      setOriginalIsChanged(originalIsChanged + 1)
    }
  }, [deleteRequestsList])

  const [queryPersonNameBody, setQueryPersonNameBody] = useState(null)
  const [newPersonName, setNewPersonName] = useState('')
  const newPersonNameBody = {
    personId: {
      personName: newPersonName
    }
  }

  const [queryPersonIdentNumBody, setQueryPersonIdentNumBody] = useState(null)
  const [newPersonIdentNum, setNewPersonIdentNum] = useState('')
  const newPersonIdentNumBody = {
    personId: {
      personIdentNum: newPersonIdentNum
    }
  }

  const [paginationValues, setPaginationValues, isNextActive, setIsNextActive, isPrevActive, setIsPrevActive] = usePaginateQuery(
    adminChangePlan,
    'adminChangePlan',
    queryPersonNameBody || queryPersonIdentNumBody ? searchFilterAdminChangePlan : getAdminChangePlanData,
    dataTypes.updateChangePlanManagement,
    idToken,
    dataContext,
    queryPersonNameBody || queryPersonIdentNumBody || null
  )

  const { page, limit, totalPages } = paginationValues

  const { listFormatted } = useFilterBySearch(
    deleteRequestsList,
    formatAdminChangePlanList,
    page,
    limit
  )
  const navigate = useNavigate()
  return (
    <section id='ChangePlanAdmin'>
      <table>
        <thead>
          <tr>
            <th>{scriptsSave && scriptsSave.usersPanelString ? scriptsSave.usersPanelString.id : ''}</th>
            <th>
              <Dropdown
                title={scriptsSave && scriptsSave.usersPanelString ? scriptsSave.usersPanelString.name : ''}
                type={'search'}
                newQueryBody={newPersonNameBody}
                setQueryBody={setQueryPersonNameBody}
                setNewValue={setNewPersonName}
              />
            </th>
            <th>
              <Dropdown
                title={scriptsSave && scriptsSave.usersPanelString ? scriptsSave.usersPanelString.id : ''}
                type={'search'}
                newQueryBody={newPersonIdentNumBody}
                setQueryBody={setQueryPersonIdentNumBody}
                setNewValue={setNewPersonIdentNum}
              />
            </th>
            <th>
              <p>Plan actual</p>
            </th>
            <th>{scriptsSave && scriptsSave.usersPanelString ? scriptsSave.usersPanelString.actions : ''}</th>
          </tr>
        </thead>
        <tbody>
          {
            listFormatted.map((user) => (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>{user.userName}</td>
                <td>{user.identNum}</td>
                <td>{`Data ${user.currentPlan}`}</td>
                <td>
                  <button className='UsersPanelDetailBtn'
                    onClick={() => onDetailClick(
                      navigate,
                      user,
                      idToken,
                      dataDispatch,
                      dataContext
                    )}
                  >
                    <img
                      src={editIcon}
                      alt={scriptsSave && scriptsSave.usersPanelString ? scriptsSave.usersPanelString.altImageTxt2 : ''}
                    />
                  </button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
      {(originalDeleteRequestList.current.length !== 0 && listFormatted.length === 0) && <EmptyData/>}
      <div className='UsersPanelPagination'>
        <Pagination
          currentPage={adminChangePlan.currentPage}
          paginationValues={paginationValues}
          scrollMeasurement={adminChangePlan.scrollMeasurement}
          setPaginationValues={setPaginationValues}
          totalPages={totalPages}
          isNextActive={isNextActive}
          setIsNextActive={setIsNextActive}
          isPrevActive={isPrevActive}
          setIsPrevActive={setIsPrevActive}
        />
      </div>
    </section>
  )
}
