import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { DataContext } from '../../../../context/DataContext'
import { Dropdown } from '../../../../components/Dropdown/Dropdown'

import { usePaginateQuery } from '../../../../hooks/usePaginateQuery'
import { getUserPocketMoves } from '../../../../utils/userQueries'
import { dataTypes } from '../../../../context/reducers/dataReducer/dataTypes'
import { Pagination } from '../../../../components/Pagination/Pagination'
import { useFilterBySearch } from '../../../../hooks/useFilterBySearch'
import {
  formatUserPocketList,
  onDetailsClick
} from './BolsilloUsuarioFunctions'
import watchIcon from '../../../../assets/icons/icon_ver-operativo.svg'
import { ScriptContext } from '../../../../context/ScriptsContext'

import './BolsilloUsuario.css'
import { EmptyData } from '../../../../components/EmptyData/EmptyData'

export const BolsilloUsuario = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const dataContext = useContext(DataContext)
  const { dataState, dataDispatch } = dataContext
  const { idToken, movements } = dataState.operative
  const { userPocket } = movements
  const userPocketList = movements.userPocket.list

  const originalUserPocketList = useRef(movements.userPocket.list)
  const [originalIsChanged, setOriginalIsChanged] = useState(0)
  useEffect(() => {
    if (originalIsChanged <= 1) {
      originalUserPocketList.current = userPocketList
      setOriginalIsChanged(originalIsChanged + 1)
    }
  }, [userPocketList])

  const [paginationValues, setPaginationValues] = usePaginateQuery(
    userPocket,
    'userPocket',
    getUserPocketMoves,
    dataTypes.updateUserPocket,
    idToken,
    dataContext
  )

  const { page, limit, totalPages } = paginationValues

  const { listFormatted } = useFilterBySearch(
    userPocketList,
    formatUserPocketList,
    page,
    limit
  )

  const navigate = useNavigate()

  return (
    <section id='BolsilloUsuario'>
      <table id='bolsilloUsuarioTable'>
        <thead id='bolsilloUsuarioTableHead'>
          <tr>
            <th className='bolsilloUsuarioHeadCell'>
              {scriptsSave && scriptsSave.bolsilloUsuarioString ? scriptsSave.bolsilloUsuarioString.thead1 : ''}
            </th>
            <th className='bolsilloUsuarioHeadCell'>
              <Dropdown
                title={scriptsSave && scriptsSave.bolsilloUsuarioString ? scriptsSave.bolsilloUsuarioString.thead2 : ''}
                type={'search'}
                newQueryBody={{}}
                setQueryBody={() => {}}
                setNewValue={() => {}}
              />
            </th>
            <th className='bolsilloUsuarioHeadCell'>
              <Dropdown
                title={scriptsSave && scriptsSave.bolsilloUsuarioString ? scriptsSave.bolsilloUsuarioString.thead3 : ''}
                type={'search'}
                newQueryBody={{}}
                setQueryBody={() => {}}
                setNewValue={() => {}}
              />
            </th>
            <th className='bolsilloUsuarioHeadCell'>
              <Dropdown
                title={scriptsSave && scriptsSave.bolsilloUsuarioString ? scriptsSave.bolsilloUsuarioString.thead4 : ''}
                type={'search'}
                newQueryBody={{}}
                setQueryBody={() => {}}
                setNewValue={() => {}}
              />
            </th>
            <th>{scriptsSave && scriptsSave.bolsilloUsuarioString ? scriptsSave.bolsilloUsuarioString.thead5 : ''}</th>
          </tr>
          </thead>
          <tbody id='bolsilloUsuarioTableBody'>
            {listFormatted.map((userPocket) => (
              <tr key={userPocket.id}>
                <td>{userPocket.id}</td>
                <td>{userPocket.name}</td>
                <td>{userPocket.identNum}</td>
                <td>{userPocket.email}</td>
                <td>
                  <button
                    onClick={() =>
                      onDetailsClick(
                        navigate,
                        userPocket,
                        userPocket.userId,
                        idToken,
                        dataDispatch,
                        dataContext
                      )
                    }
                  >
                    <img
                      src={watchIcon}
                      alt={scriptsSave && scriptsSave.bolsilloUsuarioString ? scriptsSave.bolsilloUsuarioString.altImageTxt1 : ''}
                    />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
      </table>
      {(originalUserPocketList.current.length !== 0 && listFormatted.length === 0) && <EmptyData/>}
      <Pagination
        currentPage={userPocket.currentPage}
        paginationValues={paginationValues}
        scrollMeasurement={userPocket.scrollMeasurement}
        setPaginationValues={setPaginationValues}
        totalPages={totalPages}
      />
    </section>
  )
}
