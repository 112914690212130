import React, { useState, useContext, useMemo } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

import iconPersonCircleAdmin from '../../../../assets/icons/icon_nuevo usuario-naranja.svg'
import iconPersonCircleLawyer from '../../../../assets/icons/icon_nuevo usuario-morado.svg'
import iconPersonCircleOper from '../../../../assets/icons/icon_nuevo usuario-violeta.svg'
import generatePasswordIcon from '../../../../assets/icons/icon_generate_password.svg'
import backIcon from '../../../../assets/icons/icon_volver.svg'
import { DataContext } from '../../../../context/DataContext'
import { Modal } from '../../../../components/Modal/Modal'
import { useModal } from '../../../../hooks/useModal'
import { useFetchFormData } from '../../hooks/useFetchFormData'
import { useIncorrectInputFormat } from '../../hooks/useIncorrectInputFormat'
import { useEditFormValuesInitializer } from '../../hooks/useEditFormValuesInitializer'
import { roles } from '../../../../utils/objects'
import { maxSize } from '../../../../utils/validateFunctions'
import {
  calculateBirthdayDatesRange,
  onBirthdayDateChange,
  onCityChange,
  onCountryChange,
  onDocumentNumberChange,
  onDocumentTypeIdChange,
  onEmailChange,
  onExpeditionCityChange,
  onGenderChange,
  onIsValidBirthdayDateBlur,
  onIsValidCityBlur,
  onIsValidCountryBlur,
  onIsValidDocumentNumberBlur,
  onIsValidEmailBlur,
  onIsValidExpeditionCityBlur,
  onIsValidLastNameBlur,
  onIsValidNameBlur,
  onIsValidPhoneBlur,
  onLastNameChange,
  onNameChange,
  onPasswordClick,
  onPasswordKeyDown,
  onPhoneChange
} from '../../utils/createAndEditUserMethods'
import {
  closeModal,
  editUserSendForm,
  setClassAndIcon
} from './EditUserFunctions'

import './EditUser.css'
import { generateAleatoryPassword } from '../../../../utils/functions'
import { ScriptContext } from '../../../../context/ScriptsContext'

export const EditUser = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const dataContext = useContext(DataContext)
  const { dataState, dataDispatch } = dataContext
  // const { userProfile, userName } = dataState.profile
  const { idToken, employeeToBeEdited } = dataState.administrative
  const { employeeCredentialEmail, employeeId, employeeCredentialId, roleName } = employeeToBeEdited
  const { employeeId: employeeidToBeEdited } = employeeId
  const {
    expeditionCity,
    genderId,
    identDocId,
    personBirthDt,
    personCity,
    personId,
    personIdentNum,
    personLastName,
    personName,
    personPhone
  } = employeeId.personId
  const navigate = useNavigate()

  const [showModal, handleShow, handleClose] = useModal()

  const [showModal3, handleShow3, handleClose3] = useModal()
  const [modal3Message, setModal3Message] = useState('')
  const [passwordState] = useState({
    password: {
      eyeIcon: generatePasswordIcon,
      isPasswordVisible: false,
      passwordInputType: 'password'
    },
    passwordConfirmation: {
      eyeIcon: generatePasswordIcon,
      isPasswordVisible: false,
      passwordInputType: 'password'
    }
  })

  const {
    countriesFetched,
    citiesFetched,
    docTypesFetched,
    gendersFetched
  } = useFetchFormData()

  const { minBirthdayDate, maxBirthdayDate } = useMemo(() => calculateBirthdayDatesRange(), [])

  const [isRequesting, setIsRequesting] = useState(false)

  const [formValues, setFormValues, initialFormValues] = useEditFormValuesInitializer(
    countriesFetched,
    citiesFetched,
    employeeCredentialEmail,
    expeditionCity,
    genderId,
    identDocId,
    personBirthDt,
    personCity,
    personIdentNum,
    personLastName,
    personName,
    personPhone
  )

  const { incorrectInputFormat, setIncorrectInputFormat } = useIncorrectInputFormat()

  const { colorClass, iconSrc } = setClassAndIcon(
    roles,
    roleName,
    iconPersonCircleAdmin,
    iconPersonCircleLawyer,
    iconPersonCircleOper
  )

  return (
    <>
      <div className='editUserHeaderContainer'>
        <p className={'editUserHeaderContainerParagraph'}>
          {scriptsSave && scriptsSave.editUserString ? scriptsSave.editUserString.editUser : ''}
        </p>
      </div>
      <hr className='hreditUser' />
      <div className='editUserFormContainer'>
        <div className='editNewUserMainContent'>
          <div className={`editUserFormHeaderContainer ${colorClass}`}>
            <img
              alt={scriptsSave && scriptsSave.editUserString ? scriptsSave.editUserString.altImageTxt1 : ''}
              className='editUserFormHeaderContainerImg'
              src={iconSrc}
            />
            <p className='editUserFormHeaderContainerParagraph'>
              {`${personName} ${personLastName} | Rol ${roleName}`}
            </p>
          </div>
          <div id='editUserFormContainer'>
            <div className='editUserFormSubContainerL'>
              <div className='formSubContainerLNombreApellidoContainer'>
                <div className='editUserInputBox'>
                  <span className='editNewUserInputTitle'>{`${scriptsSave && scriptsSave.editUserString ? scriptsSave.editUserString.userName : ''}`}</span>
                  <input
                    autoComplete='off'
                    className={`${incorrectInputFormat.nameFormat === 'employeeRightFormat' ? 'editUserInput' : 'editUserInputIncorrectFormat'}`}
                    name='editUserInput'
                    onBlur={() => onIsValidNameBlur(formValues, incorrectInputFormat, setIncorrectInputFormat)}
                    onChange={(e) => onNameChange(e, formValues, setFormValues)}
                    onInput={(e) => maxSize(e, 'flName')}
                    placeholder={scriptsSave && scriptsSave.editUserString ? scriptsSave.editUserString.placeholder1 : ''}
                    type='text'
                    value={formValues.name}
                    tabIndex='1'
                  />
                  <span className={incorrectInputFormat.nameFormat}>{incorrectInputFormat.nameMessage}</span>
                </div>
                <div className='editUserInputBox'>
                  <span className='editNewUserInputTitle'>{`${scriptsSave && scriptsSave.editUserString ? scriptsSave.editUserString.userLastName : ''}`}</span>
                  <input
                    autoComplete='off'
                    className={`${incorrectInputFormat.lastNameFormat === 'employeeRightFormat' ? 'editUserInput' : 'editUserInputIncorrectFormat'}`}
                    name='editUserInput'
                    onBlur={() => onIsValidLastNameBlur(formValues, incorrectInputFormat, setIncorrectInputFormat)}
                    onChange={(e) => onLastNameChange(e, formValues, setFormValues)}
                    onInput={(e) => maxSize(e, 'flName')}
                    placeholder={scriptsSave && scriptsSave.editUserString ? scriptsSave.editUserString.placeholder2 : ''}
                    type='text'
                    value={formValues.lastName}
                    tabIndex='2'
                  />
                  <span className={incorrectInputFormat.lastNameFormat}>{incorrectInputFormat.lastNameMessage}</span>
                </div>
              </div>
              <div className='editUserInputBox'>
                <span className='editNewUserInputTitle'>{`${scriptsSave && scriptsSave.editUserString ? scriptsSave.editUserString.eMail : ''}`}</span>
                <input
                  autoComplete='off'
                  className={`${incorrectInputFormat.emailFormat === 'employeeRightFormat' ? 'editUserInput' : 'editUserInputIncorrectFormat'}`}
                  name='editUserInput'
                  onBlur={() => onIsValidEmailBlur(
                    dataContext,
                    formValues,
                    setFormValues,
                    incorrectInputFormat,
                    setIncorrectInputFormat,
                    idToken,
                    initialFormValues
                  )}
                  onChange={(e) => onEmailChange(e, formValues, setFormValues)}
                  onInput={(e) => maxSize(e, 'email')}
                  placeholder={scriptsSave && scriptsSave.editUserString ? scriptsSave.editUserString.placeholder3 : ''}
                  type='text'
                  value={formValues.email}
                  tabIndex='5'
                />
                <span className={incorrectInputFormat.emailFormat}>{incorrectInputFormat.emailMessage}</span>
              </div>
              <div className='formSubContainerLPaisCiudadContainer'>
                <div className='editUserInputBox'>
                  <span className='editNewUserInputTitle'>{`${scriptsSave && scriptsSave.editUserString ? scriptsSave.editUserString.country : ''}`}</span>
                  <input
                    autoComplete='off'
                    className={`${incorrectInputFormat.countryFormat === 'employeeRightFormat' ? 'editUserInput' : 'editUserInputIncorrectFormat'}`}
                    name='editUserInput'
                    onBlur={() => onIsValidCountryBlur(
                      formValues,
                      setFormValues,
                      incorrectInputFormat,
                      setIncorrectInputFormat,
                      countriesFetched
                    )}
                    onChange={(e) => onCountryChange(e, formValues, setFormValues)}
                    onInput={(e) => maxSize(e, 'flName')}
                    placeholder={formValues.country ? formValues.country : (scriptsSave && scriptsSave.editUserString ? scriptsSave.editUserString.placeholder4 : '')}
                    type='text'
                    value={formValues.country}
                    tabIndex='8'
                  />
                  <span className={incorrectInputFormat.countryFormat}>{incorrectInputFormat.countryMessage}</span>
                </div>
                <div className='editUserInputBox'>
                  <span className='editNewUserInputTitle'>{`${scriptsSave && scriptsSave.editUserString ? scriptsSave.editUserString.city : ''}`}</span>
                  <input
                    autoComplete='off'
                    className={`${incorrectInputFormat.cityFormat === 'employeeRightFormat' ? 'editUserInput' : 'editUserInputIncorrectFormat'}`}
                    name='editUserInput'
                    onBlur={() => onIsValidCityBlur(
                      formValues,
                      setFormValues,
                      incorrectInputFormat,
                      setIncorrectInputFormat,
                      citiesFetched
                    )}
                    onChange={(e) => onCityChange(e, formValues, setFormValues)}
                    placeholder={scriptsSave && scriptsSave.editUserString ? scriptsSave.editUserString.placeholder5 : ''}
                    type='text'
                    value={formValues.city}
                    tabIndex='9'
                  />
                  <span className={incorrectInputFormat.cityFormat}>{incorrectInputFormat.cityMessage}</span>
                </div>
              </div>
              <div className='editUserInputBox'>
                <span className='editUserPasswordTitle'>{`${scriptsSave && scriptsSave.editUserString ? scriptsSave.editUserString.userPassword : ''}`}</span>
                <span className='editUserPasswordSubtitle'>{`${scriptsSave && scriptsSave.editUserString ? scriptsSave.editUserString.password : ''}`}</span>
                <div className='textboxIconPasswordContainer'>
                  <div
                    className={`${incorrectInputFormat.passwordFormat === 'employeeRightFormat' ? 'editUserInput editPasswordInput' : 'editUserInputIncorrectFormat editPasswordInputIncorrectFormat'}`}
                  >
                    <span>{formValues.password.replace(/./g, '\u25CF')}</span>
                    <img
                      alt={scriptsSave && scriptsSave.editUserString ? scriptsSave.editUserString.altEyeIcon : ''}
                      className='passwordGenerateImg'
                      onKeyDown={(e) => onPasswordKeyDown(
                        e,
                        generateAleatoryPassword,
                        formValues,
                        setFormValues,
                        setIncorrectInputFormat
                      )}
                      onClick={() => onPasswordClick(
                        generateAleatoryPassword,
                        formValues,
                        setFormValues,
                        setIncorrectInputFormat
                      )}
                      src={passwordState.password.eyeIcon}
                      tabIndex='12'
                    />
                  </div>
                </div>
                <span className={incorrectInputFormat.passwordFormat}>{incorrectInputFormat.passwordMessage}</span>
              </div>
              {/* <span className='redAdvice'>
                {`*${scriptsSave && scriptsSave.editUserString ? scriptsSave.editUserString.mandatoryFileds : ''}`}
              </span> */}
            </div>
            <div className='editUserFormSubContainerR'>
              <div className='formSubContainerRGeneralContainerA'>
                <div className='editUserInputBox IdSelect'>
                  <span className='editNewUserInputTitle'>{`${scriptsSave && scriptsSave.editUserString ? scriptsSave.editUserString.idType : ''}`}</span>
                  <select
                    autoComplete='off'
                    className={`${incorrectInputFormat.documentTypeIdFormat === 'employeeRightFormat' ? 'editUserSelect' : 'editUserSelectIncorrectFormat'}`}
                    onChange={(e) => onDocumentTypeIdChange(
                      e,
                      formValues,
                      setFormValues,
                      incorrectInputFormat,
                      setIncorrectInputFormat
                    )}
                    defaultValue={formValues.documentTypeId || ''}
                    tabIndex='3'
                  >
                    <option disabled key='0ident' id='documentTypeId' value=''>
                      {scriptsSave && scriptsSave.editUserString ? scriptsSave.editUserString.defaultDocumentTypeId : ''}
                    </option>
                    {
                      docTypesFetched.map(({ identDocId, identDocName }) => (
                        < option
                          selected={identDocId === Number(formValues.documentTypeId)}
                          key={identDocId}
                          value={identDocId}
                        >
                          {identDocName}
                        </option>

                      ))
                    }
                  </select>
                  <span className={incorrectInputFormat.documentTypeIdFormat}>{incorrectInputFormat.documentTypeIdMessage}</span>
                </div>
                <div className='editUserInputBox'>
                  <span className='editNewUserInputTitle'>{`${scriptsSave && scriptsSave.editUserString ? scriptsSave.editUserString.idNumber : ''}`}</span>
                  <input
                    autoComplete='off'
                    className={`${incorrectInputFormat.documentNumberFormat === 'employeeRightFormat' ? 'editUserInput' : 'editUserInputIncorrectFormat'}`}
                    name='editUserInput'
                    onBlur={() => onIsValidDocumentNumberBlur(
                      dataContext,
                      formValues,
                      setFormValues,
                      incorrectInputFormat,
                      setIncorrectInputFormat,
                      idToken,
                      initialFormValues
                    )}
                    onChange={(e) => onDocumentNumberChange(e, formValues, setFormValues)}
                    placeholder={scriptsSave && scriptsSave.editUserString ? scriptsSave.editUserString.placeholder7 : ''}
                    type='text'
                    value={formValues.documentNumber}
                    tabIndex='4'
                  />
                  <span className={incorrectInputFormat.documentNumberFormat}>{incorrectInputFormat.documentNumberMessage}</span>
                </div>
              </div>
              <div className='formSubContainerRGeneralContainerB'>
                <div className='editUserInputBox BirthdateSelect'>
                  <span className='editNewUserInputTitle'>{`${scriptsSave && scriptsSave.editUserString ? scriptsSave.editUserString.birthDate : ''}`}</span>
                  <input
                    autoComplete='off'
                    className={`${incorrectInputFormat.birthdayDateFormat === 'employeeRightFormat' ? 'editUserInputDate' : 'editUserInputDateIncorrectFormat'}`}
                    max={maxBirthdayDate}
                    min={minBirthdayDate}
                    name='editUserInput'
                    onBlur={() => onIsValidBirthdayDateBlur(
                      formValues,
                      incorrectInputFormat,
                      setIncorrectInputFormat,
                      maxBirthdayDate,
                      minBirthdayDate
                    )}
                    onChange={(e) => onBirthdayDateChange(e, formValues, setFormValues)}
                    placeholder={scriptsSave && scriptsSave.editUserString ? scriptsSave.editUserString.placeholder8 : ''}
                    type='date'
                    value={formValues.birthdayDate}
                    tabIndex='6'
                  />
                  <span className={incorrectInputFormat.birthdayDateFormat}>{incorrectInputFormat.birthdayDateMessage}</span>
                </div>
                <div className='editUserInputBox'>
                  <span className='editNewUserInputTitle'>{`${scriptsSave && scriptsSave.editUserString ? scriptsSave.editUserString.idIssuance : ''}`}</span>
                  <input
                    autoComplete='off'
                    className={`${incorrectInputFormat.documentExpeditionCityFormat === 'employeeRightFormat' ? 'editUserInput' : 'editUserInputIncorrectFormat'}`}
                    name='editUserInput'
                    onBlur={() => onIsValidExpeditionCityBlur(
                      formValues,
                      setFormValues,
                      incorrectInputFormat,
                      setIncorrectInputFormat,
                      citiesFetched
                    )}
                    onChange={(e) => onExpeditionCityChange(e, formValues, setFormValues)}
                    placeholder={scriptsSave && scriptsSave.editUserString ? scriptsSave.editUserString.placeholder9 : ''}
                    type='text'
                    value={formValues.documentExpeditionCity}
                    tabIndex='7'
                  />
                  <span className={incorrectInputFormat.documentExpeditionCityFormat}>{incorrectInputFormat.documentExpeditionCityMessage}</span>
                </div>
              </div>
              <div className='formSubContainerRGeneralContainerC'>
                <div className='editUserInputBox GenderInput'>
                  <span className='editNewUserInputTitle'>{`${scriptsSave && scriptsSave.editUserString ? scriptsSave.editUserString.gender : ''}`}</span>
                  <select
                    autoComplete='off'
                    className={`${incorrectInputFormat.documentTypeIdFormat === 'employeeRightFormat' ? 'editUserSelect' : 'editUserSelectIncorrectFormat'}`}
                    onChange={(e) => onGenderChange(
                      e,
                      formValues,
                      setFormValues,
                      incorrectInputFormat,
                      setIncorrectInputFormat
                    )}
                    defaultValue={formValues.gender || ''}
                    tabIndex='10'
                  >
                    <option disabled key='0gender' id='gender' value=''>
                      {scriptsSave && scriptsSave.editUserString ? scriptsSave.editUserString.defaultGender : ''}
                    </option>
                    {
                      gendersFetched.map(({ genderId, genderType }) => (
                        <option
                          selected={genderId === Number(formValues.gender)}
                          key={genderId}
                          value={genderId}
                        >
                          {genderType}
                        </option>
                      ))
                    }
                  </select>
                  <span className={incorrectInputFormat.genderFormat}>{incorrectInputFormat.genderMessage}</span>
                </div>
                <div className='editUserInputBox'>
                  <span className='editNewUserInputTitle'>{`${scriptsSave && scriptsSave.editUserString ? scriptsSave.editUserString.phone : ''}`}</span>
                  <input
                    autoComplete='off'
                    className={`${incorrectInputFormat.phoneFormat === 'employeeRightFormat' ? 'editUserInput' : 'editUserInputIncorrectFormat'}`}
                    onBlur={() => onIsValidPhoneBlur(
                      dataContext,
                      formValues,
                      setFormValues,
                      incorrectInputFormat,
                      setIncorrectInputFormat,
                      idToken,
                      initialFormValues
                    )}
                    onChange={(e) => onPhoneChange(e, formValues, setFormValues)}
                    name='editUserInput'
                    placeholder={scriptsSave && scriptsSave.editUserString ? scriptsSave.editUserString.placeholder10 : ''}
                    type='text'
                    value={formValues.phone}
                    tabIndex='11'
                  />
                  <span className={incorrectInputFormat.phoneFormat}>{incorrectInputFormat.phoneMessage}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='editUserFooter'>
          <NavLink to='../' className='editUserFooterBackLink'>
            <img
              src={backIcon}
              alt={scriptsSave && scriptsSave.editUserString ? scriptsSave.editUserString.altImageTxt2 : ''}
            />
            <span>{`${scriptsSave && scriptsSave.editUserString ? scriptsSave.editUserString.goBack : ''}`}</span>
          </NavLink>
          <NavLink
            className={`${isRequesting ? 'editUserFooterButtonDisabled' : 'editUserFooterButton'}`}
            onClick={() => editUserSendForm(
              handleShow,
              formValues,
              setFormValues,
              initialFormValues,
              incorrectInputFormat,
              setIncorrectInputFormat,
              countriesFetched,
              citiesFetched,
              maxBirthdayDate,
              minBirthdayDate,
              personId,
              employeeidToBeEdited,
              employeeCredentialId,
              idToken,
              dataContext,
              dataDispatch,
              isRequesting,
              setIsRequesting,
              handleShow3,
              setModal3Message
            )}
            tabIndex='13'
          >
            {`${scriptsSave && scriptsSave.editUserString ? scriptsSave.editUserString.save : ''}`}
          </NavLink>
        </div>
      </div >
      <Modal isOpen={showModal}>
        <Modal.Content>
          <p>{scriptsSave && scriptsSave.editUserString ? scriptsSave.editUserString.successfulyUserCreation : ''}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button
            className='Modal-orangeBtn'
            onClick={() => closeModal(navigate, handleClose)}
          >
            {scriptsSave && scriptsSave.editUserString ? scriptsSave.editUserString.conitnue : ''}
          </button>
        </Modal.Buttons>
      </Modal>
      <Modal isOpen={showModal3}>
        <Modal.Content>
          <p>{modal3Message}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button
            className='Modal-greenBtn'
            onClick={handleClose3}>
            {scriptsSave && scriptsSave.editUserString ? scriptsSave.editUserString.ok : ''}
          </button>
        </Modal.Buttons>
      </Modal>
    </>
  )
}
