import React, { useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { NavFooter } from '../../components/NavFooter/NavFooter'
import { DataContext } from '../../context/DataContext'
import { Modal } from '../../components/Modal/Modal'
import { useModal } from '../../hooks/useModal'
import { useFetchPQRSDetail } from './hooks/useFetchPQRSDetail'
import { handleResponsePQRS, validateTextarea } from './UserRequestFunctions'
import userCompanyImg from '../../assets/images/userCompany.png'
import userPersonImg from '../../assets/images/userPerson.png'

import './UserRequest.css'
import { CharCounter } from '../../components/CharCounter/CharCounter'
import { ScriptContext } from '../../context/ScriptsContext'

export const UserRequest = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const dataContext = useContext(DataContext)
  const { dataState } = dataContext
  const { idToken, pqrs, employeeId } = dataState.operative
  const { pqrsDetail } = pqrs

  const message = pqrsDetail?.content?.match(/Message:\s*(.*)/)
    ? pqrsDetail?.content?.match(/Message:\s*(.*)/)[1]
    : pqrsDetail?.content

  const navigate = useNavigate()
  const { state } = useLocation()
  const { requestId, isUser } = state
  const [showModal, handleShow] = useModal()

  const [responseFields, setResponseFields] = useState({
    status: 0,
    response: '',
    employeeId
  })

  const [isRequesting, setIsRequesting] = useState(false)

  useFetchPQRSDetail(dataContext, requestId, isUser)

  return (
    <>
      <div className="unregisteredUserMainContainer">
        <div className="dataBox">
          <div className="userRequestButtonsBox">
            <button
              className="userRequestButtonsBoxBtn"
              onClick={() =>
                navigate('EditarPQRS', {
                  state: {
                    isUser,
                    id: pqrsDetail.userId.userId || pqrsDetail.userId.buyerId,
                    pqrsId: pqrsDetail.pqrsId.pqrsId
                  }
                })
              }
            >
            {
              pqrsDetail?.pqrsId?.pqrsId === 6
                ? (scriptsSave && scriptsSave.userRequestsString ? scriptsSave.userRequestsString.button1Text2 : '')
                : (scriptsSave && scriptsSave.userRequestsString ? scriptsSave.userRequestsString.button1Text1 : '')
            }
            </button>
            <button
              className={`userRequestButtonsBoxBtn ${
                !pqrsDetail?.userId?.userId || !pqrsDetail.documentPath ? 'isHidden' : ''
              }`}
              onClick={() =>
                navigate('AdjuntosDeCambios', { state: { pqrsDetail, data: state } })
              }
            >
              {scriptsSave && scriptsSave.userRequestsString ? scriptsSave.userRequestsString.button2Text1 : ''}
            </button>
          </div>
          <div className="userRequestDataContainer">
            <h4 className="userRequestH4">{scriptsSave && scriptsSave.userString ? scriptsSave.userString.data : ''}</h4>
            <div className="userRequestInfoContainer">
              <div className="userRequestDataSubContainer1">
                <div className="userRequestSeparatedDataContainer">
                  <p className="userRequestSeparatedDataContainerP">
                    {scriptsSave && scriptsSave.userString ? scriptsSave.userString.userName : ''}
                  </p>
                  <p className="userRequestParagraph">{pqrsDetail?.name}</p>
                </div>
                <div className="userRequestSeparatedDataContainer">
                  <p className="userRequestSeparatedDataContainerP">
                    {scriptsSave && scriptsSave.userString ? scriptsSave.userString.mobile : ''}
                  </p>
                  <p className="userRequestParagraph">{pqrsDetail?.phone}</p>
                </div>
                <div className="userRequestSeparatedDataContainer">
                  <p className="userRequestSeparatedDataContainerP">
                    {scriptsSave && scriptsSave.userString ? scriptsSave.userString.mail : ''}
                  </p>
                  <p className="userRequestParagraph">{pqrsDetail?.email}</p>
                </div>
              </div>
              <div className="userRequestDataSubContainer2">
                <div className="userRequestSeparatedDataContainer">
                  <p className="userRequestSeparatedDataContainerP">
                    {scriptsSave && scriptsSave.userString ? scriptsSave.userString.idType : ''}
                  </p>
                  <p className="userRequestParagraph">{pqrsDetail?.idType}</p>
                </div>
                <div className="userRequestSeparatedDataContainer">
                  <p className="userRequestSeparatedDataContainerP">
                    {scriptsSave && scriptsSave.userString ? scriptsSave.userString.id : ''}
                  </p>
                  <p className="userRequestParagraph">{pqrsDetail?.identNum}</p>
                </div>
                <div className="userRequestSeparatedDataContainer">
                  ´
                  {pqrsDetail?.userId?.userId
                    ? (
                    <img
                      src={userPersonImg}
                      alt={scriptsSave && scriptsSave.userRequestsString ? scriptsSave.userRequestsString.altImageTxt1 : ''}
                    />
                      )
                    : (
                    <img
                      src={userCompanyImg}
                      alt={scriptsSave && scriptsSave.userRequestsString ? scriptsSave.userRequestsString.altImageTxt2 : ''}
                    />
                      )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p className="petitionRequestComplaintTitle">
            {scriptsSave && scriptsSave.compString ? scriptsSave.compString.petitionRequestComplaint : ''}
          </p>
          <div className="petitionRequestComplaintBox">
            <p className="petitionRequestComplaintBoxPID">{`# ${pqrsDetail?.radicatedNum}`}</p>
            <p className="petitionRequestComplaintBoxP">
              {pqrsDetail?.pqrsName}
            </p>
            <br />
            {message &&
              message.split('\n').map((row, index) => (
                <p className="petitionRequestComplaintBoxP" key={index}>
                  {row}
                </p>
              ))}
            <br />
            <p className="petitionRequestComplaintBoxP">
              {`${pqrsDetail?.name} - ${pqrsDetail?.email}`}
            </p>
          </div>
        </div>
        <div className="userRequestResponse">
          <p>{scriptsSave && scriptsSave.userRequestsString ? scriptsSave.userRequestsString.textareaLabel : ''}</p>
          <textarea
            value={responseFields.response}
            onChange={(e) =>
              validateTextarea(e, responseFields, setResponseFields)
            }
          ></textarea>
          <CharCounter description={responseFields.response} />
        </div>
        <NavFooter returnPath="../">
          <label className="userRequestRadio">
            <input
              type="radio"
              name="pqrs"
              value={2}
              onChange={(e) =>
                setResponseFields({ ...responseFields, status: e.target.value })
              }
            />
            {scriptsSave && scriptsSave.userRequestsString ? scriptsSave.userRequestsString.radioInput1Text : ''}
          </label>
          <label className="userRequestRadio">
            <input
              type="radio"
              name="pqrs"
              value={3}
              onChange={(e) =>
                setResponseFields({ ...responseFields, status: e.target.value })
              }
            />
            {scriptsSave && scriptsSave.userRequestsString ? scriptsSave.userRequestsString.radioInput2Text : ''}
          </label>
          <button
            className="userRequestButton"
            onClick={() =>
              handleResponsePQRS(
                pqrsDetail,
                responseFields,
                dataContext,
                idToken,
                handleShow,
                setIsRequesting
              )
            }
            disabled={
              responseFields.response.length < 10 || responseFields.status === 0 || isRequesting
            }
          >
            {scriptsSave && scriptsSave.compString ? scriptsSave.compString.returnAnswer : ''}
          </button>
        </NavFooter>
      </div>
      <Modal isOpen={showModal}>
        <Modal.Content>
          <p>{scriptsSave && scriptsSave.userRequestsString ? scriptsSave.userRequestsString.modalContent : ''}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button className="Modal-orangeBtn" onClick={() => navigate('..')}>
            {scriptsSave && scriptsSave.userRequestsString ? scriptsSave.userRequestsString.modalButton1Text1 : ''}
          </button>
        </Modal.Buttons>
      </Modal>
    </>
  )
}
