import React, { useContext, useEffect, useState } from 'react'

import { NavFooter } from '../../components/NavFooter/NavFooter'
import { useModal } from '../../hooks/useModal'
import { Modal } from '../../components/Modal/Modal'
import { useFetchBuyerNotActive } from './hooks/useFetchBuyerNotActive'
import {
  onContinueModalBtnClick,
  onDeleteEntityClick,
  onSaveEntityClick
} from './EntityValidationFunctions'

import { DataContext } from '../../context/DataContext'
import { useNavigate } from 'react-router-dom'
import { setDocumentUrl } from '../../utils/functions'
import { ScriptContext } from '../../context/ScriptsContext'
import './EntityValidation.css'

export const EntityValidation = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const dataContext = useContext(DataContext)
  const { dataState } = dataContext
  const { idToken } = dataState.operative

  const {
    buyerCredentialId,
    buyerId,
    companyComertialName,
    companyBussinesName,
    companyEmail,
    companyNit,
    buyerLegalRepresentative,
    buyerContactName,
    companyPhone,
    companyLogoPath,
    buyerRUTPath
  } = useFetchBuyerNotActive()

  const [showModal, handleShow, handleClose] = useModal()
  const [modalMessage, setModalMessage] = useState('')
  const navigate = useNavigate()
  const [url, setUrl] = useState()
  const [, setUserDocumentPathObject] = useState([])
  const fileUrlSplit = buyerRUTPath.split('/')
  const fileUrl = fileUrlSplit[fileUrlSplit.length - 1].split('?')
  const [, setIsImage] = useState(true)

  const [isRequesting, setIsRequesting] = useState(false)

  useEffect(() => {
    setDocumentUrl(buyerRUTPath, setUserDocumentPathObject, setUrl, setIsImage)
  }, [buyerRUTPath])

  return (
    <>
      <div className="entityValidationMainContainer">
        <div className="entityValidationSubContainer1">
          <div className="entityValidationInfoContainerL">
            <div className="entityValidationInfoSubContainer">
              <p className="entityValidationInfoContainerTitle">
                {scriptsSave && scriptsSave.compString
                  ? scriptsSave.compString.entityComertialName
                  : ''}
              </p>
              <p className="entityValidationInfoContainerP">
                {companyComertialName}
              </p>
            </div>
            <div className="entityValidationInfoSubContainer">
              <p className="entityValidationInfoContainerTitle">
                {scriptsSave && scriptsSave.compString
                  ? scriptsSave.compString.entityBussinesName
                  : ''}
              </p>
              <p className="entityValidationInfoContainerP">
                {companyBussinesName}
              </p>
            </div>
            <div className="entityValidationInfoSubContainer">
              <p className="entityValidationInfoContainerTitle">
                {scriptsSave && scriptsSave.compString
                  ? scriptsSave.compString.mail
                  : ''}
              </p>
              <p className="entityValidationInfoContainerP">{companyEmail}</p>
            </div>
            <div className="entityValidationInfoSubContainer">
              <p className="entityValidationInfoContainerTitle">
                {scriptsSave && scriptsSave.compString
                  ? scriptsSave.compString.NIT
                  : ''}
              </p>
              <p className="entityValidationInfoContainerP">{companyNit}</p>
            </div>
            <div className="entityValidationInfoSubContainer">
              <p className="entityValidationInfoContainerTitle">
                {scriptsSave && scriptsSave.compString
                  ? scriptsSave.compString.legalRepresentation
                  : ''}
              </p>
              <p className="entityValidationInfoContainerP">
                {buyerLegalRepresentative}
              </p>
            </div>
          </div>
          <div className="entityValidationInfoContainerR">
            <div className="entityValidationInfoSubContainer">
              <p className="entityValidationInfoContainerTitle">
                {scriptsSave && scriptsSave.compString
                  ? scriptsSave.compString.contact
                  : ''}
              </p>
              <p className="entityValidationInfoContainerP">
                {buyerContactName}
              </p>
            </div>
            <div className="entityValidationInfoSubContainer">
              <p className="entityValidationInfoContainerTitle">
                {scriptsSave && scriptsSave.compString
                  ? scriptsSave.compString.phone
                  : ''}
              </p>
              <p className="entityValidationInfoContainerP">{companyPhone}</p>
              <div className="lblButtonContainer">
                <a
                  rel="noreferrer"
                  className="entityValidationButton"
                  onClick={() => {
                    if (url) {
                      const newWindow = window.open(url, '_blank')
                      if (newWindow) {
                        newWindow.opener = null
                      }
                    }
                  }}
                >
                  {scriptsSave && scriptsSave.compString
                    ? scriptsSave.compString.rutConsultation
                    : ''}
                </a>
                <label>{fileUrl[0]}</label>
              </div>
            </div>
          </div>
          <div className="entityValidationImageSubContainer">
            <img
              className="entityValidationImg"
              src={companyLogoPath}
              alt={
                scriptsSave && scriptsSave.entityValidationString
                  ? scriptsSave.entityValidationString.altImageTxt1
                  : ''
              }
            />
          </div>
        </div>
        <NavFooter returnPath="../NuevasEntidades">
          <button
            className={`${
              isRequesting
                ? 'navFooterButton1AlternativeDisabled'
                : 'navFooterButton navFooterButton1Alternative'
            }`}
            onClick={() =>
              onDeleteEntityClick(
                buyerCredentialId,
                companyEmail,
                companyComertialName,
                setModalMessage,
                idToken,
                handleShow,
                dataContext,
                isRequesting,
                setIsRequesting
              )
            }
          >
            {scriptsSave && scriptsSave.compString
              ? scriptsSave.compString.deleteEntity
              : ''}
          </button>
          <button
            className={`${
              isRequesting
                ? 'navFooterButton2Disabled'
                : 'navFooterButton navFooterButton2'
            }`}
            onClick={() =>
              onSaveEntityClick(
                buyerId,
                companyComertialName,
                setModalMessage,
                idToken,
                handleShow,
                dataContext,
                isRequesting,
                setIsRequesting
              )
            }
          >
            {scriptsSave && scriptsSave.compString
              ? scriptsSave.compString.save
              : ''}
          </button>
        </NavFooter>
      </div>
      <Modal isOpen={showModal}>
        <Modal.Content>
          <p>{modalMessage}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button
            className="Modal-orangeBtn"
            onClick={() => onContinueModalBtnClick(navigate, handleClose)}
          >
            {scriptsSave && scriptsSave.entityValidationString
              ? scriptsSave.entityValidationString.modalButton2Text1
              : ''}
          </button>
        </Modal.Buttons>
      </Modal>
    </>
  )
}
