import React, { useContext } from 'react'
import { ScriptContext } from '../../../../context/ScriptsContext'
import { DataContext } from '../../../../context/DataContext'
import iconEnProceso from '../../../../assets/icons/icon_enproceso.svg'
import iconAutorizado from '../../../../assets/icons/icon_autorizado.svg'
import iconRechazado from '../../../../assets/icons/icon_bloqueado.svg'
import iconFinalizado from '../../../../assets/icons/icon_bien.svg'
import iconRevision from '../../../../assets/icons/icon_continuar.svg'

export const CompaniesInProcessList = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const dataContext = useContext(DataContext)
  const { adminChangePlanToBeReviewed } = dataContext.dataState.operative.users
  const { planName } = adminChangePlanToBeReviewed.specificInfo

  return (
    <div className='usersAdminDetailsContainer2'>
        <div className='usersAdminDetailsSubcontainer'>
        <h2 className='usersAdminDetailsTitle'>
            {scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.title4 : ''}
        </h2>
        <div className='usersAdminDetailsHistorialRows'>
            {adminChangePlanToBeReviewed.blockedCompanies.length > 0 && (
            <>
                <h2 className='usersAdminDetailsSubtitle usersAdminDetailsSubtitleHistorialLSide'>
                {scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.subtitle8 : ''}
                </h2>
                <h2 className='usersAdminDetailsSubtitle usersAdminDetailsSubtitleHistorialRSide'>
                {scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.subtitle9 : ''}
                </h2>
            </>
            )}
            {
            adminChangePlanToBeReviewed.blockedCompanies.length > 0 && adminChangePlanToBeReviewed.blockedCompanies.map(company => (
                <>
                <p className='usersAdminDetailsCompanyName'>{company.companyComertialName}</p>
                <div className='usersAdminDetailsCompanyStatus'>
                    <img
                    className='usersAdminDetailsCompanyStatusImg'
                    src={
                        company.statusName === 'Pendiente'
                          ? iconEnProceso
                          : company.statusName === 'Rechazado'
                            ? iconRechazado
                            : company.statusName === 'Finalizado'
                              ? iconFinalizado
                              : iconRevision
                    }
                    alt={scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.altImageTxt2 : ''}
                    />
                </div>
                </>
            ))
            }
        </div>
        </div>
        <div className='usersAdminDetailsSubcontainer'>
        <h2 className={`${planName === 'Control' ? 'usersAdminDetailsTitleControl' : 'usersAdminDetailsTitle'}`}>
            {scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.title5 : ''}
        </h2>

        {planName !== 'Control'
          ? (
            <div className='usersAdminDetailsHistorialRows'>
                {adminChangePlanToBeReviewed.authorizedCompanies.length > 0 && (
                <>
                    <h2 className='usersAdminDetailsSubtitle usersAdminDetailsSubtitleHistorialLSide'>
                    {scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.subtitle8 : ''}
                    </h2>
                    <h2 className='usersAdminDetailsSubtitle usersAdminDetailsSubtitleHistorialRSide'>
                    {scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.subtitle9 : ''}
                    </h2>
                </>
                )}
                {
                adminChangePlanToBeReviewed.authorizedCompanies.length > 0 && adminChangePlanToBeReviewed.authorizedCompanies.map(company => (
                    <>
                    <p className='usersAdminDetailsCompanyName'>{company.companyComertialName}</p>
                    <div className='usersAdminDetailsCompanyStatus'>
                        <img
                        className='usersAdminDetailsCompanyStatusImg'
                        src={iconAutorizado}
                        alt={scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.altImageTxt3 : ''}
                        />
                    </div>
                    </>
                ))
                }
            </div>
            )
          : <p className='usersAdminDetailsSubtitle usersAdminDetailsNA'>
            {scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.warningTxt1 : ''}
            </p>
        }
        </div>
    </div>
  )
}
