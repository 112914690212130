import React, { useContext, useEffect, useRef, useState } from 'react'
import { DataContext } from '../../context/DataContext'
import { usePaginateQuery } from '../../hooks/usePaginateQuery'
import { Pagination } from '../../components/Pagination/Pagination'
import { useNavigate } from 'react-router-dom'
import { Dropdown } from '../../components/Dropdown/Dropdown'
import { addElipsisAtEnd } from '../../utils/functions'
import { formatUsersManagementList, onDetailClick } from './OperativeUsersAdminFunctions'
import { useFilterBySearch } from '../../hooks/useFilterBySearch'
import { getActiveUsersData, searchFilterUsersAdmin } from '../../utils/userQueries'
import { dataTypes } from '../../context/reducers/dataReducer/dataTypes'
import editIcon from '../../assets/icons/icon_editar.svg'
import { ScriptContext } from '../../context/ScriptsContext'
import { EmptyData } from '../../components/EmptyData/EmptyData'

export const OperativeUsersAdmin = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const dataContext = useContext(DataContext)
  const { dataState, dataDispatch } = dataContext
  const { idToken, users } = dataState.operative
  const { usersManagement } = users
  const usersManagementList = users.usersManagement.list

  const originalUsersManagementList = useRef(users.usersManagement.list)
  const [originalIsChanged, setOriginalIsChanged] = useState(0)
  useEffect(() => {
    if (originalIsChanged <= 1) {
      originalUsersManagementList.current = usersManagementList
      setOriginalIsChanged(originalIsChanged + 1)
    }
  }, [usersManagementList])

  const [queryPersonNameBody, setQueryPersonNameBody] = useState(null)
  const [queryPersonIDBody, setQueryPersonIDBody] = useState(null)
  const [newPersonName, setNewPersonName] = useState('')
  const [newPersonID, setNewPersonID] = useState('')
  const newPersonNameBody = {
    personId: {
      personName: newPersonName
    }
  }
  const newPersonIDBody = {
    personId: {
      personIdentNum: newPersonID
    }
  }

  const [paginationValues, setPaginationValues, isNextActive, setIsNextActive, isPrevActive, setIsPrevActive] = usePaginateQuery(
    usersManagement,
    'usersManagement',
    queryPersonNameBody || queryPersonIDBody ? searchFilterUsersAdmin : getActiveUsersData,
    dataTypes.updateUsersManagement,
    idToken,
    dataContext,
    queryPersonNameBody || queryPersonIDBody || null
  )

  const { page, limit, totalPages } = paginationValues
  const { originalList, listFormatted, setListFormatted } = useFilterBySearch(
    usersManagementList,
    formatUsersManagementList,
    page,
    limit
  )

  const userTypeOption1 = scriptsSave && scriptsSave.operativeUsersAdminStrings ? scriptsSave.operativeUsersAdminStrings.userTypeOption1 : ''
  const userTypeOption2 = scriptsSave && scriptsSave.operativeUsersAdminStrings ? scriptsSave.operativeUsersAdminStrings.userTypeOption2 : ''
  const userTypeFilterOptions = [userTypeOption1, userTypeOption2]

  const navigate = useNavigate()
  return (
    <section id='DeleteRequests'>
      <table>
        <thead>
          <tr>
            <th>{scriptsSave && scriptsSave.operativeUsersAdminStrings ? scriptsSave.operativeUsersAdminStrings.thead1 : ''}</th>
            <th>
              <Dropdown
                title={scriptsSave && scriptsSave.operativeUsersAdminStrings ? scriptsSave.operativeUsersAdminStrings.thead2 : ''}
                type={'search'}
                newQueryBody={newPersonNameBody}
                setQueryBody={setQueryPersonNameBody}
                setNewValue={setNewPersonName}
              />
            </th>
            <th>
              <Dropdown
                title={scriptsSave && scriptsSave.operativeUsersAdminStrings ? scriptsSave.operativeUsersAdminStrings.thead3 : ''}
                type={'search'}
                newQueryBody={newPersonIDBody}
                setQueryBody={setQueryPersonIDBody}
                setNewValue={setNewPersonID}
              />
            </th>
            <th>
              <Dropdown
                filterBy={'userType'}
                list={originalList}
                options={userTypeFilterOptions}
                title={scriptsSave && scriptsSave.operativeUsersAdminStrings ? scriptsSave.operativeUsersAdminStrings.thead4 : ''}
                updateList={setListFormatted}
              />
            </th>
            <th>{scriptsSave && scriptsSave.operativeUsersAdminStrings ? scriptsSave.operativeUsersAdminStrings.thead5 : ''}</th>
          </tr>
        </thead>
        <tbody>
          {
            listFormatted.map((user) => (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>{addElipsisAtEnd(user.nameInList, 20)}</td>
                <td>{user.identNumInList}</td>
                <td>{user.userType}</td>
                <td>
                  <button className='UsersPanelDetailBtn'
                    onClick={() => onDetailClick(
                      navigate,
                      user,
                      idToken,
                      dataDispatch,
                      dataContext
                    )}
                  >
                    <img
                      src={editIcon}
                      alt={scriptsSave && scriptsSave.operativeUsersAdminStrings ? scriptsSave.operativeUsersAdminStrings.altImageTxt1 : ''}
                    />
                  </button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
      {(originalUsersManagementList.current.length !== 0 && listFormatted.length === 0) && <EmptyData/>}
      <div className='UsersPanelPagination'>
        <Pagination
          currentPage={usersManagement.currentPage}
          paginationValues={paginationValues}
          scrollMeasurement={usersManagement.scrollMeasurement}
          setPaginationValues={setPaginationValues}
          totalPages={totalPages}
          isNextActive={isNextActive}
          setIsNextActive={setIsNextActive}
          isPrevActive={isPrevActive}
          setIsPrevActive={setIsPrevActive}
        />
      </div>
    </section>
  )
}
